import router from '@/router/index'
import axios from 'axios'
import i18n from '@/lang'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import { Message, MessageBox } from 'element-ui'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
let isFirstError = false
const blobToJson = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = res => {
      const { result } = res.target
      const parseResult = JSON.parse(result)
      resolve(parseResult)
    }
    reader.onerror = err => {
      reject(err)
    }
    reader.readAsText(new Blob([blob]), 'utf-8')
  })
}

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // baseURL: 'https://sport-api-slave-develop.ingress-devops.data-sports.org/' // client
  // baseURL: 'https://sport-data-api-dev-client.ingress-devops.data-sports.org/' // platform
  // baseURL: 'https://sport-data-api-datasets.ingress-devops.data-sports.org/'
})

const handleErrorMessage = (data) => {
  console.log('handleErrorMessage', data)
  let res_msg = ''
  if (data.error.details) {
    res_msg = handleRegularError(data.error)
  } else {
    res_msg = i18n.t(`errorMessage.${data.error.code}`)
  }

  Message({
    message: res_msg,
    type: data.error.code < 3000 ? 'warning' : 'error',
    duration: 6000
  })
}

const showLogoutNotifyDialog = () => {
  MessageBox.confirm(i18n.t('Notify.logoutNotifications'), i18n.t('general.Notify'), {
    confirmButtonText: i18n.t('general.ReLogin'),
    cancelButtonText: i18n.t('general.Continue'),
    type: 'warning',
    showClose: false,
    showCancelButton: false,
    closeOnClickModal: false,
    closeOnPressEscape: false
  }).then(() => {
    console.log('request remove Token')
    removeToken()
    router.app.$router.replace('/login?redirect=/')
  })
}

const handleRegularError = (error) => {
  const details = {}
  for (const key in error.details) {
    details[key] = i18n.te(`errorMessage.${error.details[key]}`) ? i18n.t(`errorMessage.${error.details[key]}`) : error.details[key]
  }
  return i18n.t(`errorMessage.${error.code}`, details)
}

service.interceptors.request.use(
  
  config => {
    if (store.getters.token) {
      config.headers['Authorization'] = `Bearer ${getToken()}`
    } 
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status >= 300) {
      return Promise.reject(new Error())
    }
    return res
  },
  
  async error => {
    if (axios.isCancel(error)) {
      return Promise.resolve({})
    }
    const { data, config } = error.response
    let res_msg

    if (!isFirstError && (data.error.code === 3101 || data.error.code === 3102)) {
      console.log('3101 & 3102')
      isFirstError = true
      showLogoutNotifyDialog()
    } else if (data.error && i18n.te(`errorMessage.${data.error.code}`)) {
      const showOnDialog = [0, 2011, 4001, 4002, 4003, 4004, 4005, 4006, 4007, 4101]
      console.log(showOnDialog.indexOf(data.error.code))
      if (showOnDialog.indexOf(data.error.code) > 0) {
        console.log('show on dialog')
      } else {
        handleErrorMessage(data)
      }
    } else if (config.responseType === 'blob') {
      console.log('blob')
      isFirstError = false
      const errorJson = await blobToJson(data)
      return Promise.reject(errorJson)
    } else {
      console.log('neither')
      res_msg = data.message
      isFirstError = false
      Message({
        message: res_msg,
        type: data.error.code < 3000 ? 'warning' : 'error',
        duration: 6000
      })
    }

    return Promise.reject(error)
  }
)

export default service
