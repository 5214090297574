import { mapGetters } from 'vuex'
import FooterMenu from '@/assets/siteMap/FooterMenu.json'
import adminMenu from '@/assets/siteMap/adminMenu.json'
import providerMenu from '@/assets/siteMap/providerMenu.json'

export default {
  data() {
    return {
      adminMenu: [],
      providerMenu: [],
      footerMenu: []
    }
  },
  computed: {
    ...mapGetters(['token']),
    isLogin () {
      return !!this.token
    }
  },
  mounted() {
    this.getFooterMenu()
    this.setHeaderMenu()
  },
  methods: {
    setHeaderMenu() {
      this.adminMenu = adminMenu
      this.providerMenu = providerMenu
    },
    getFooterMenu() {
      const data = FooterMenu.map(item => {
        const newObj = {
          ...item,
          children: item.children.map(child => {
            const newChild = {
              ...child
            }
            if (this.isLogin && child.routerNameLogin) {
              newChild.routerName = child.routerNameLogin
            }
            return newChild
          })
        }
        if (this.isLogin && item.routerNameLogin) {
          newObj.routerName = item.routerNameLogin
        }
        return newObj
      })
      this.footerMenu = data
    }
  }
}
