export default {
  route: {
    Dashboard: 'ダッシュボード',
    SportsDataCharityService: 'スポーツデータ公益プラットフォーム',
    Specification: '仕様',
    JoinPlatform: '参加する',
    SiteMap: 'サイトマップ',
    FAQ: 'よくある質問',
    ContactUs: 'お問い合わせ',
    Privacy: 'プライバシーポリシー',
    Terms: 'サービス条項',
    AboutProject: '政策的な背景',
    DataUsage: '使用説明',
    UseCase: '使用事例',
    CheckUpload: 'アップロードチェック',
    Login: 'ログイン',
    SearchData: 'データ',
    QA: '質問と解答',
    SyntheticData: '処理されたデータ',
    UploadFile: 'アップロード',
    Basic: '基本情報',
    Password: 'パスワードを変更する',
    Token: 'Access Token',
    Account: 'アカウント管理',
    Organization: '会社管理',
    Consumer: 'ユーザーのレビュー',
    Device: '分散型デバイス',
    DeviceList: 'デバイス一覧',
    Activities: '運用記録',
    UserRecord: 'ユーザー紀錄',
    DataLog: 'ダッシ処理紀錄',
    DataSpec: 'データ規格',
    Dataset: 'データ集',
    Achievements: 'データ事例 ',
    Sdcamp: '2024実証企画募集申し込み',
    PlatformUpdates: 'プラットフォームのアップデート',
    // device
    deviceLogin: 'ログイン',
    deviceApply: '申請する'
  },
  Dashboard: {
    SportsDataCharityService: 'スポーツデータ公益プラットフォーム',
    usefulData: '可運用資料累積',
    Records: '筆',
    HealthData: '生理資料',
    SportsData: '運動資料',
    PhysicalFitnessData: '體適能資料',
    OmnibearingSportsData: '全方位運動資料',
    MDataIntegration: '多層級資料整合',
    Overview: '總覽',
    DataTypeRecords: '資料分類資料筆數',
    DataTypeRatio: '資料分類占比',
    UploadDataRatio: '上傳資料占比',
    UploadCityRatio: '上傳縣市占比',
    UploadDataRecords: '上傳資料筆數',
    MainDataTypeRecords: '主類型資料筆數',
    PlaceTypeDataRatio: '場域類型占比',
    PlaceTypeDataRecords: '場域資料筆數',
    SportTypeDataRecords: '運動類型資料筆數',
    TypeDataRecords: '主類型資料占比',
    organization_name: '公司/組織',
    LessThanOne: 'データは 1% 未満です。皆様からのより多くの貢献をお待ちしています。',
    org_city: '縣市',
    table: '表格',
    chart: '圖表',
    total: '總數',
    Physiology: '生理',
    Sport: 'スポーツ',
    PhysicalFitness: '体力',
    no: '項次',
    Place: '場域',
    Type: '類型',
    // provider
    Thanks: 'ありがとう',
    DataProvide: 'データ提供',
    WelcomeProviderGreeting: 'ようこそご参加ください。データの貢献を楽しみにしています',

    MainTypeRatio: 'メインタイプ比率',
    UploadMainType: 'メインタイプをアップロードする',
    ServiceMainType: 'メインタイプ',
    PlaceTypeRatio: '場所の種類の比率',
    UploadPlaceType: '場所の種類をアップロードする',
    ServicePlaceType: '場所の種類',
    Amount: '量',
    ContributeCount: '投稿数',
    ContributePercentage: '累積貢献率',
    ContributeDataCount: 'データの提供',
    ServiceDataCount: 'サービスデータ',
    UploadingProgressTitle: 'リアルタイムのデータ統合ステータス',
    UploadingProgress: '転送するアイテムの数',
    CommissionedBy: '執行機関：財団法人資訊工業策進会',
    // Consumer
    UploadDataTimes: 'データのアップロード時間',
    CumulativeNumberOfDataProviders: 'データプロバイダーの累計数',
    NumberOfDataProviders: 'データプロバイダーの数'
  },
  Chart: {
    DataTypeRatio: 'データ型の割合',
    Chart_Physiology_Place: '生理学的データの場所の種類',
    Chart_PhysicalFitness_Place: '場所の体力の種類',
    Chart_PhysicalFitnessType: '体力のデータ種類',
    Chart_Sport_Place: 'スポーツデータフィールド', // dashboard
    Chart_Place: `スポーツデータタイプ `, // landing
    Chart_Sport: `スポーツ種類`,
    Chart_Gender: `提供者の性別分布`,
    Chart_City: `提供者の県市分布`,
    Chart_Place_desc: `不同活動場所的數據筆數，包括學校、社區、醫院、職場、衛生單位、運動中心、健身房、其他機構、戶外場所，以及居家環境等`,
    Chart_Sport_desc: `運動數據規格中針對不同運動所收集的數據。例如羽球、步行、桌球、健行、棒球、游泳、虛擬跑步、虛擬騎車、跑步、騎車等數據`,
    Chart_Gender_desc: `貢獻數據之數據當事人的性別比例`,
    Chart_City_desc: `貢獻數據之數據當事人來自哪個縣市`
  },
  LandingStatistic: {
    org_count: '連携業者数',
    place_type: 'フィールドタイプ数',
    device_type: '設備タイプ数',
    type: 'メインタイプ数',
    org_count_desc: 'プラットフォームのパートナー数',
    place_type_desc: 'さまざまなイベント会場。学校、地域社会、病院、職場、保健室、スポーツ センター、ジムなどが含まれます。',
    device_type_desc: 'データを記録する機器アイテムの種類',
    type_desc: '様で定義されているモーションタイプの数'
  },
  LandingChart: {
    female: '女',
    male: '男',
    unknow: '未提供'
  },
  general: {
    PhysiologyRawData: '生理データ',
    SportsRawData: 'スポーツデータ',
    PhysicalFitnessRawData: '体力データ',
    Datatype: 'データ・タイプ',
    LastUpdateDate: '最終更新日',
    Update: '更新する',
    Sports: 'スポーツ',
    Sport: 'スポーツ',
    PhysicalFitness: '体力',
    Physiology: '生理',
    BasicInfo: '基本情報',
    PleaseInput: '入力してください',
    Title: 'タイトル',
    Add: '追加する',
    Edit: '編集',
    Action: '操作する',
    Index: 'アイテム',
    Delete: '消去',
    Remove: '消去',
    Clean: 'クリア',
    Batch: 'バッチ',
    Account: 'アカウント',
    Password: 'パスワード',
    Login: 'ログイン',
    Email: 'メールアドレス',
    ContactName: '連絡担当者',
    Phone: '連絡先番号',
    ReLogin: '再度ログインします',
    Continue: '続く',
    SeeMore: 'もっと見る',
    Visitors: '累計来場者数',
    Close: '消す',
    Success: '成功',
    Warning: '警告',
    Error: 'エラー',
    Create: '作成する',
    Active: 'アクティブ',
    Confirm: '確認する',
    Save: '保存',
    Be: 'なる',
    Notify: '通知する',
    Cancel: 'キャンセル',
    Filter: 'フィルター',
    Select: '選択する',
    Download: 'ダウンロード',
    Sample: 'サンプル',
    DownloadSample: 'サンプルをダウンロード',
    Copy: 'コピーされた',
    Apply: '申請する',
    ApplyNow: '今すぐお申し込みください',
    NoData: 'データなし',
    oldPwd: '以前のパスワード',
    newPwd: '新しいパスワード',
    confirmPwd: '新しいパスワード',
    ChangeDatatype: 'タイプの変更',
    SearchPlaceholder: 'ユーザーIDを入力してください',
    Code: '検証コード',
    VerificationCode: '検証コード',
    ChangeVerificationCode: '別の検証コード',
    VerificationCodeEmpty: '検証コード空にすることはできません',
    ChangePasswordTitle: 'ードを変更してください',
    ForgetPassword: 'パスワードを忘れた方',
    Register: '申請する',
    RegisterNow: '申請する',
    ContactUs: 'お問い合わせ',
    DownloadManual: 'ダウンロード',
    PageNotFound: 'ページが存在しません',
    Welcome: '参加しませんか'
  },
  Aria: {
    TargetBlankGotoOpenAPI: '另開新視窗前往OpenAPI',
    TargetBlank: '另開新視窗',
    Mailto: '開啟平台聯絡信箱',
    Goto: '前往',
    SamplePic: '範例圖片',
    Page: '頁面',
    Landing: '首頁',
    UnCheckTerms: '未閱讀使用條款',
    TermsChecked: '已閱讀使用條款',
    ReadInformation: '查看說明'
  },
  imgAlt: {
    HowToUseSportData: '運動資料使用說明'
  },
  RuleMsg: {
    PleaseInput: '請輸入',
    PleaseSelect: '請選擇',
    Invalid: '無效的',
    Member: '@:Member.Member',
    Email: 'メールアドレス',
    memberName: '使用者名稱',
    Password: 'パスワード',
    Description: '描述',
    InputNewPwd: '請輸入新密碼',
    InputRepeatPwd: '請再次輸入密碼',
    PasswordLimit: '密碼不可少於 8 個字元',
    WarnVerificate: '驗證碼錯誤',
    PasswordNotSame: '密碼不相同',
    DifferentNewPassword: '新密碼不可與舊密碼相同'
  },
  navbar: {
    admin: '管理',
    provider: '提供',
    consumer: '運用',
    logOut: '登出',
    syslog: '系統紀錄',
    home: '首頁',
    sysActivities: '使用者操作紀錄',
    dataProcessinglog: '資料處理紀錄',
    AccountManagement: '設定',
    Activities: '個人的な運用記録',
    Dashboard: 'ダッシュボード',
    Data: 'データ使用',
    Spec: 'データ規格',
    DataSpec: 'データ規格',
    OpenAPILink: 'OpenAPI',
    Privacy: 'プライバシーポリシー',
    FAQ: '質問と解答',
    QA: '質問と解答',
    Introduce: 'データ規格の紹介',
    DataConsumer: 'ユーザーのレビュー',
    DataProvider: '提供されるデータ',
    DataData: 'データリソース',
    UseCase: 'データ事例',
    Dataset: 'データ集',
    DataExplore: 'データ探索',
    News: '最新情報',
    Terms: '利用規約',
    ContactUs: 'お問い合わせ',
    BasicInfo: '資料基本類型',
    CheckFile: 'アップロードチェック',
    UploadFile: 'アップロード',
    DataList: 'データ',
    SyntheticData: '処理されたデータ',
    ConsumerData: 'データ使用',
    SiteMap: 'サイトマップ',
    Guidelines: '開発ガイド',
    Others: 'その他の情報',
    Manual: 'ユーザーマニュアル',
    Compliance_handbook: '法令順守マニュアル',
    About: '私たちについて',
    AboutProject: 'プラットフォームの背景 ',
    DataUsage: 'データ使用の説明',
    Provider: 'プロバイダー',
    DeviceList: 'デバイス一覧',
    Important: '重要',
    Sdcamp: '2024実証企画募集申し込み'
  },
  File: {
    AddFile: 'ファイルの追加',
    Upload: 'アップロード',
    Id: 'Id',
    Download: 'ダウンロード',
    DragFilesHere: 'またはここにファイルをドラッグアンドドロップします',
    ChooseFile: 'ファイルを選ぶ',
    InputName: '名前を入力するか、アップロード ファイル名を使用します',
    File: 'ファイル',
    FileSizeLimit: '20 MB、または 7000 レコードを超えない',
    UploadAndSave: 'ファイルをアップロードして保存',
    MaxFileSize: '最大ファイルサイズ',
    AllowedFileTypes: '許可されるファイルの種類',
    ConsentForm: '串接運動數據公益平台告知事項暨個人運動資料提供同意書',
    Self_auditChecklist: '運動場域端（數據提供者）蒐集民眾運動資料之個人資料保護自我檢核表',
    Compliance_handbook: '運動數據公益平台個資評估法遵使用手冊'
  },
  Notify: {
    Added: '已新增',
    Updated: '已更新',
    Deleted: '已刪除',
    Canceled: '已取消',
    Uploaded: '已上傳',
    Created: '已建立',
    Copied: '已複製',
    TemporarySaved: '已暫存',
    Submit: '已送出',
    Saved: '已儲存',
    NoEmpty: '內容不得為空',
    Same: '內容相同，未變更。',
    pleaseInput: '請輸入',
    UpdatedReLogin: '密碼更換成功，重新登入',
    SwitchLanguage: '更換語言成功',
    SingleFileLimit: '一次只能選擇一個檔案，請先移除已選擇的檔案。',
    UnsupportedFileFormat: '無法上傳，不支援此種檔案格式。',
    FileSizeLimit: '超過可上傳的檔案大小 ({size})。',
    FileNameLimit: '檔名不可包含特殊字元。',
    LoadFail: '讀取失敗，請洽您的系統管理員。',
    UnSavedChanges: '您編輯的內容尚未儲存，確定離開嗎？',
    logoutNotifications: '您的登入已失效，請重新登入。',

    VerificationCodeError: '驗證碼錯誤',
    CheckLoginDuplicate: '請檢查帳號是否重複',
    Name: '請輸入名稱',
    Date: '請選擇日期',
    permission: '請選擇至少一個權限',
    // login
    lockIPAfter5Error: '15 分以内に 5 回以上ログインに失敗すると、IP がロックされます。',
    // register
    Authorities: '請輸入主管機關',
    ChoiceFetchType: '管轄当局を入力してください',
    UncheckPersoInfoAgree: '個人情報収集に関する同意書にチェックが入っていません。',

    DownloadSource: 'データをご利用の際は著作権法に従って出典を明示してください',
    DownloadLimit: ',こちらから最大2000件までダウンロード可能です',

    confirmDelete: '本気ですか@:(general.Delete)？',
    confirmClose: '本気ですか@:(general.Close)？',
    confirmDeleteSth: '本気ですか@:(general.Delete)「{name}」？',
    forgetPasswordSuccess: '情報は送信されました。プラットフォームはできるだけ早くパスワードを変更できるように支援します',
    patientWhenDeleteData: 'データは削除中です。30 秒以内に完了する予定です。しばらくお待ちください'
  },
  excelUpload: {
    missing_required_fields_nums: '必須フィールドが欠落しています',
    CheckFile: 'ファイルをチェックする',
    duplicate_rows_num: '重複',
    total: '合計',
    success_num: '成功',
    index: 'データ行',
    userId: 'ユーザID',
    missing_fields: '欠落しているフィールド',
    MustChoiceOne: '必ず 1 つ持っておく必要があります',
    missing_MustChoiceOne: '「必須」フィールドがありません',
    checkErrorMessage: 'エラーメッセージを確認する',
    testErrorMessage: 'エラーメッセージを確認する',
    checkReportMessage: 'メッセージを確認する',
    Important: '重要',
    checkBeforeUpload_1: '時間を節約するには、次のサイトにアクセスしてください：',
    checkBeforeUpload_2: 'ファイルをアップロードする前にデータ型を確認してください',
    checkOnly: 'このページは形式チェックのみを行います。アップロードしたい場合は、こちらにアクセスしてください',
    gotoUpload: 'ファイルをアップロードする',
    uploadTitle: 'スポーツデータのアップロード',
    success_upload: '成功',
    total_upload: '合計',
    missing_upload: '欠落データ',
    duplicate_upload: '重複データ',
    correct_upload: '正しい',
    toUploadPage: 'アップロードページに移動',
    count: '(記録)'
  },
  PlaceType: {
    school: '学校',
    community: 'コミュニティ',
    hospital: '病院',
    workplace: '職場',
    health_unit: '衛生機関',
    sports_center: 'スポーツセンター',
    gym: 'ジム',
    facility: '機構',
    outdoor: '屋外',
    home: '自宅',
    openWater: '開放水域',
    unknow: '未知'
  },
  field: {
    // basic
    version: 'バージョン',
    main_type: 'メインタイプ',
    type: 'タイプ',
    subtype: 'サブタイプ',
    main_type_zh: 'メインタイプ 中文',
    type_zh: 'タイプ中文',
    subtype_zh: 'サブタイプ中文',
    user_id: 'ユーザID',
    birth_year: '生年',
    gender: '性別',
    city: '市',
    start_date: '記録タイム',
    created_at: 'アップロードタイム',
    timezone: 'タイムゾーン',
    name: 'イベント名',
    device_type: 'デバイスタイプ',
    place_type: 'フィールドタイプ',
    organization_name: '企業',
    // place
    school: '学校',
    community: 'コミュニティ',
    hospital: '病院',
    workplace: '職場',
    health_unit: '健康ユニット',
    sports_center: 'スポーツセンター',
    gym: '健身房ジム',
    facility: '施設',
    outdoor: 'アウトドア',
    home: '家',
    // measure
    height: 'Height',
    weight: 'weight',
    waist_circumference: 'Waist Circumference',
    body_fat_percentage: 'Body Fat Percentage',
    body_mass_index: 'Body Mass Index',
    skeleton_muscle_mass: 'Skeleton Muscle Mass',
    deep_sleep_count: 'Deep Sleep Count',
    light_sleep_count: 'Light Sleep Count',
    deep_sleep_time: 'Deep Sleep Time',
    light_sleep_time: 'Light Sleep Time',
    achievement_length: 'Achievement Length',
    systolic_blood_pressure: 'Systolic Blood Pressure',
    diastolic_blood_pressure: 'Diastolic Blood Pressure',
    peripheral_oxyhemoglobin_saturation: 'Peripheral Oxyhemoglobin Saturation',
    blood_sugar: 'Blood Sugar',
    basal_metabolic_rate: 'Basal Metabolic Rate',
    heart_rate: 'Heart Rate',
    resting_heart_rate: 'Resting Heart Rate',
    average_heart_rate: 'Average Heart Rate',
    max_heart_rate: 'Max Heart Rate',
    heart_rate_variability: 'Heart Rate Variability',
    temperature: 'Temperature',
    respiratory_rate: 'Respiratory Rate',
    vo2_max: 'VO2 Max',
    cardiorespiratory_fitness_index: 'Cardiorespiratory Fitness Index',
    calories: 'Calories',
    distance__m: 'Distance(m)',
    distance__km: 'Distance(km)',
    moving_time: 'Moving Time',
    elapsed_time: 'Elapsed Time',
    start_latitude: 'Start Latitude',
    start_longitude: 'Start Longitude',
    end_latitude: 'End Latitude',
    end_longitude: 'End Longitude',
    total_elevation_gain: 'Total Elevation Gain',
    elevation_high: 'Elevation High',
    elevation_low: 'Elevation Low',
    speed_ms: 'Speed(m/s)',
    speed__km_hr: 'Speed(km/hr)',
    average_spee__m_s: 'Average Speed(m/s)',
    average_speed__km_hr: 'Average Speed(km/hr)',
    max_speed__m_s: 'Max Speed(m/s)',
    max_speed__km_hr: 'Max Speed(km/hr)',
    ride_cadence: 'Ride Cadence',
    average_ride_cadence: 'Average Ride Cadence',
    run_cadence: 'Run Cadence',
    average_run_cadence: 'Average Run Cadence',
    kilojoules: 'Kilojoules',
    average_watts: 'Average Watts',
    max_watts: 'Max Watts',
    weighted_average_watts: 'Weighted Average Watts',
    steps: 'Steps',
    achievement_count: 'Achievement Count',
    training_weight: 'Training Weight',
    jump_height: 'Jump Height',
    upper_extremity_muscle_strength: 'Upper Extremity Muscle Strength',
    lower_extremity_muscle_strength: 'Lower Extremity Muscle Strength',
    total_elevation_loss: 'Total Elevation Loss',
    max_ride_cadence: 'Max Ride Cadence',
    max_run_cadence: 'Max Run Cadence',
    average_pace__sec_km: 'Average Pace(sec/km)',
    total_length_count__25m: 'Total Length Count(25m)',
    total_length_count__50m: 'Total Length Count(50m)',
    total_stroke_count: 'Total Stroke Count',
    average_distance_per_stroke: 'Average Distance Per Stroke',
    average_stroke_rate: 'Average Stroke Rate',
    average_swim_pace_sec_25m: 'Average Swim Pace(sec/25m)',
    average_swim_pace_sec_50m: 'Average Swim Pace(sec/50m)',
    swim_swolf__25m: 'Swim SWOLF(25m)',
    swim_swolf__50m: 'Swim SWOLF(50m)',
    sleep_efficiency: 'Sleep Efficiency',
    route_name: 'Route Name',
    bicycle_model: 'Bicycle(Brand)',
    shoe_model: 'Shoe(Type)',
    gpx: 'Activity(gpx)',
    fit: 'Activity(fit)',
    tcx: 'Activity(tcx)',
    competition_event: 'Competition Event',
    competition_name: 'Competition Name',
    competition_result: 'Competition Result',
    competition_score: 'Competition Score',
    competition_score_opposing: 'Competition Score Opposing',
    competition_rank: 'Competition Rank',
    body_fat_mass: 'Body Fat Mass',
    fat_free_mass: 'Fat Free Mass',
    fat_free_mass_index: 'Fat Free Mass Index',
    visceral_fat_index: 'Visceral Fat Index',
    protein: 'Protein',
    mineral: 'Mineral',
    total_body_water: 'Total Body Water',
    body_age: 'Body Age',
    left_side_sbp: 'Left-Side SBP',
    right_side_sbp: 'Right-Side SBP',
    left_side_dbp: 'Left-Side DBP',
    right_side_dbp: 'Right-Side DBP',
    left_side_pulse_pressure: 'Left-Side PP(Pulse Pressure)',
    right_side_pulse_pressure: 'Right-Side PP(Pulse Pressure)',
    left_side_mean_arterial_pressure: 'Left-Side MAP(Mean Arterial Pressure)',
    right_side_mean_arterial_pressure: 'Right-Side MAP(Mean Arterial Pressure)',
    bat_speed: 'Bat speed',
    peak_hand_speed: 'Peak hand speed',
    time_to_contact: 'Time to contact',
    attack_angle: 'Attack angle',
    vertical_bat_angle: 'Vertical bat angle',
    on_plane_efficiency: 'On plane efficiency',
    rotation_acceleration: 'Rotation acceleration',
    early_connection: 'Early connection',
    connection_at_impact: 'Connection at impact',
    power: 'Power',
    hitting_type: 'Hitting type',
    vdot: 'Vdot',
    heart_rate_reserve__70: '70% Heart Rate Reserve',
    heart_rate_reserve: 'Heart Rate Reserve',
    condition_index: 'Condition Index',
    physical_fitness_index: 'Physical Fitness Index',
    fatigue_index: 'Fatigue Index',
    step_length: 'Step Length',
    training_volume: 'Training Volume',
    running_target: 'Running Target',
    run_type: 'Run Type',
    run_training_status_source: 'Run Training Status Source',
    // badminton
    maximum_swing_strength: 'Maximum Swing Strength',
    average_swing_strength: 'Average Swing Strength',
    fastest_swing_speed: 'Fastest Swing Speed',
    average_swing_speed: 'Average Swing Speed',
    fastest_smashing_speed: 'Fastest Smashing Speed',
    average_smashing_speed: 'Average Smashing Speed',
    // table tennis
    swing_count: 'Swing Count',
    ax_mean: 'ax_mean',
    ay_mean: 'ay_mean',	
    az_mean: 'az_mean',
    gx_mean: 'gx_mean',	
    gy_mean: 'gy_mean',	
    gz_mean: 'gz_mean',
    // 4MeterGait 5TimesStandAndSit BalanceTest
    gait_speed: 'Gait Speed',	
    shoulder_angle_difference: 'Shoulder Angle Difference',	
    hip_angle_difference: 'Hip Angle Difference',	
    knee_angle_difference: 'Knee Angle Difference',	
    chair_stand: 'Chair Stand',
    side_by_side_stand: 'Side By Side Stand',
    semi_tandem_stand: 'SemiTandem Stand',
    tandem_stand: 'Tandem Stand',
    // PitchingAnalysis
    ball_velocity: 'Ball Velocity',
    total_spin: 'Total Spin',
    true_spin: 'True Spin',
    spin_direction: 'Spin Direction',
    spin_efficiency: 'Spin Efficiency',
    release_angle: 'Release Angle',
    horizontal_angle: 'Horizontal Angle',
    release_height: 'Release Height',
    release_side: 'Release Side',
    age: 'Age',
    level: 'Level',
    // strength-training
    shoulder_press: 'Shoulder Press',
    chest_press: 'Chest Press', 
    rowing: 'Rowing',
    back_extension: 'Back Extension',
    back_flexion: 'Back Flexion',
    knee_extension: 'Knee Extension',
    knee_flexion: 'Knee Flexion',
    hip_abduction: 'Hip Abduction',
    hip_adduction: 'Hip Adduction',
    leg_press: 'Leg Press',
    // 擊球練習
    accuracy: 'Accuracy',
    // 灼見
    year: 'Year',
    date: 'Date',
    pitcher_id: 'Pitcher Id',
    pitch_throw: 'Pitch Throw',
    pitcher_team: 'Pitcher Team',
    batter_id: 'Batter Id',
    batter_side: 'Batter Side',
    batter_team: 'Batter Team',
    inning: 'Inning',
    top_bottom: 'Top Bottom',
    balls: 'Balls',
    strikes: 'Strikes',
    outs: 'Outs',
    on_base: 'On Base',
    pitch_action: 'Pitch Action',
    pitch_type: 'Pitch Type',
    hit_type: 'Hit Type',
    hit_strength: 'Hit Strength',
    pa_result: 'Pa Result',
    kzone_y: 'Kzone Y',
    kzone_z: 'Kzone X',
    pitch_velo: 'Pitch Velo',
    exit_distance: 'Exit Distance',
    park: 'Park',
    game_level: 'Game Level',
    game_id: 'Game Id',
    game_type: 'Game Type',
    PA: 'PA',
    AB: 'AB',
    R: 'R',
    H: 'H',
    RBI: 'RBI',
    '2B': '2B',
    '3B': '3B',
    HR: 'HR',
    DP: 'DP',
    TP: 'TP',
    BB: 'BB',
    IBB: 'IBB',
    HBP: 'HBP',
    K: 'K',
    SH: 'SH',
    SF: 'SF',
    FOTE: 'FOTE',
    SB: 'SB',
    CS: 'CS',
    pitches: 'pitches',
    BF: 'BF',
    WP: 'WP',
    BK: 'BK',
    runs_allowed: 'runs_allowed',
    ER: 'ER',
    // ..
    ball_speed: 'Ball Speed',
    club_head_speed: 'Club Head Speed',
    smash_factor: 'Smash Factor',
    total_dist_ft: 'Total Dist Ft',
    carry_dist_ft: 'Carry Dist Ft',
    dist_to_pin_ft: 'Dist To Pin Ft',
    club_angle_path: 'Club Angle Path',
    club_angle_face: 'Club Angle Face',
    launch_angle: 'Launch Angle',
    launch_direction: 'Launch Direction',
    golf_back_spin: 'Golf Back Spin',
    golf_side_spin: 'Golf Side Spin',
    // baseball
    baseball_release_time: 'Baseball Release Time',
    baseball_travel_time: 'Baseball Travel Time',
    baseball_side_spin: 'Baseball Side Spin',
    baseball_top_spin: 'Baseball Top Spin',
    gyro_degree: 'Gyro Degree',
    horizontal_break: 'Horizontal Break',
    vertical_break: 'Vertical Break',
    baseball_total_spin: 'Baseball Total Spin',
    exchange_time: 'Exchange Time',
    pop_time: 'Pop Time',
    bounces: 'Bounces',
    sweet_spot: 'Sweet Spot',
    forefoot_presure_ratio: 'Forefoot Pressure Ratio',
    heel_presure_ratio: 'Heel Pressure Ratio',
    linited_internal_rotation: 'Limited Internal Rotation',
    over_internal_rotation: 'Over Internal Rotation',
    activity_index: 'Activity Index',
    TDEE: 'TDEE',
    district: 'District',
    OS_version: 'OS Version',
    vertical_jump: 'Vertical Jump',
    broad_jump: 'Broad Jump',
    hop: 'Hop',
    squat: 'Squat',
    isometric: 'Isometric',
    sprint: 'Sprint',
    reaction_speed: 'Reaction Speed',
    // 棒式支撐
    achievement_time: 'Achievement Time',
    // 虹映-睡眠
    rem_sleep_time: 'REM Sleep Time',
    awake_time: 'Awake Time',
    // 明根
    usage_times: 'Usage times',
    total_work: 'Total work',
    average_work_per_second: 'Average work per second',
    resistance_value: 'Resistance Value',
    setting_speed: 'Speed',
    vertical_amplitude: 'Vertical Amplitude',
    vertical_frequency: 'Vertical Frequency',
    horizontal_amplitude: 'Horizontal Amplitude',
    horizontal_frequency: 'Horizontal Frequency',
    frequency: 'Frequency',
    max_strength_push_up: 'Max strength for Push-up',
    max_strength_pull_down: 'Max strength for Pull-down',
    max_output_push_forward: 'Max output of push forward',
    max_output_pull_back: 'Max output of pull back',
    max_output_press_inward: 'Max output of press-inward',
    max_output_spread_outward: 'Max output of spread-outward',
    max_output_legs_extended: 'Max output of legs extended',
    max_strength_front_kick: 'Max strength for Front kick',
    max_strength_hook_kick: 'Max strength for Hook kick',
    max_output_left_rotate: 'Max output of left-rotate',
    max_output_right_rotate: 'Max output of right-rotate',
    max_output_bend_forward: 'Max output of bend-forward',
    max_output_straighten_upright: 'Max output of straighten-upright',
    back_forth_max_force_ratio: 'Back and forth max force ratio',
    max_force_body_weight_ratio_push_up: 'Max force to body weight ratio for Push-up',
    max_force_body_weight_ratio_Pull_down: 'Max force to body weight ratio for Pull-down',
    max_force_body_weight_ratio_push_forward: 'Max force to body weight ratio of push forward',
    max_force_body_weight_ratio_pull_back: 'Max force to body weight ratio of pull back',
    max_force_body_weight_ratio_press_inward: 'Max force to body weight ratio of press-inward',
    max_force_body_weight_ratio_spread_outward: 'Max force to body weight ratio of spread-outward',
    max_force_body_weight_ratio_legs_extended: 'Max force to body weight ratio of legs extended',
    max_force_body_weight_ratio_front_kick: 'Max force to body weight ratio for Front kick',
    max_force_body_weight_ratio_hook_kick: 'Max force to body weight ratio for Hook kick',
    max_force_body_weight_ratio_left_rotate: 'Max force to body weight ratio of left-rotate',
    max_force_body_weight_ratio_right_rotate: 'Max force to body weight ratio of right-rotate',
    max_force_body_weight_ratio_bend_forward: 'Max force to body weight ratio of bend-forward',
    max_force_body_weight_ratio_straighten_upright: 'Max force to body weight ratio of straighten-upright',
    // 虛擬划船
    standard_level: 'Standard(%)',
    ebk_ass:	'ebk Ass',
    ebk_speed__km_hr:	'Ebk Speed(km/hr)',
    ebk_tor:	'Ebk Tor',
    hr_sta:	'Heart Rate Status',
    // 跳床
    average_hang_time:	'Average hang time',
    duration_trampoline:	'Duration',
    // 睡眠
    average_sp_o2:	'Average SpO2',
    oxygen_desaturation_3:	'Oxygen Desaturation Index 3%',
    oxygen_desaturation_4:	'Oxygen Desaturation Index 4%',
    average_pulse_rate:	'Average Pulse Rate',
    average_respiration_rate:	'Average Respiration Rate',
    total_record_time:	'Total Record Time',
    time_in_bed:	'Time in Bed',
    sleep_onset_latency:	'Sleep Onset Latency',
    wake_after_sleep_onset:	'Wake After Sleep Onset',
    wake_after_sleep_offset:	'Wake After Sleep Offset',
    total_sleep_time:	'Total Sleep Time',
    T90:	'T90',
    T89:	'T89',
    T88:	'T88',
    T50:	'T50',
    T120:	'T120',
    T10:	'T10',
    T20:	'T20'
  },
  Unit: {
    cm: 'cm',
    kg: 'kg',
    kg_m2: 'kg/m2', 
    counts: 'counts',
    minutes: 'min',
    mmHg: 'mmHg',
    mg_dl: 'mg/dl',
    kilocalories: 'kcal',
    beats_per_minute: 'BPM',
    milliseconds: 'ms',
    celsius: '℃',
    ml_kg_minute: 'ml/kg/minute',
    sec_pulses: 'Sec*100/[2×(total number of 3 pulses)]',
    meters: 'm',
    kilometers: 'km',
    latitude: 'lat',
    longitude: 'lng',
    meters_per_second: 'm/sec',
    kilometers_per_hour: 'km/h',
    revolutions_per_minute: 'RPM',
    steps_per_minute: 'SPM',
    kilojoules: 'kJ',
    watts: 'W',
    steps: 'steps',
    seconds_per_kilometers: 'sec/km',
    number_of_length: 'number of length',
    meters_per_stroke: 'meters/cycle',
    strokes_per_minute: 's/m',
    seconds_per_25m: 'sec/25m',
    seconds_per_50m: 'sec/50m',
    SWOLF: 'SWOLF',
    years_old: 'YO',
    seconds: 'seconds',
    degrees: 'degrees',
    percentage: 'percentage',
    G__meter_per_second_squared: 'G(meter per second squared)',
    kilo_watt: 'kilo watt',
    vdot: 'vdot',
    index: 'index',
    // badminton
    newton: 'N',
    // table tennis
    times: 'times',	
    g: 'g',
    degrees_per_second: '°/sec',
    // new
    pt: 'point',
    cm_s: 'cm/s',
    // PitchingAnalysis
    rpm: 'RPM',
    hh_mm: 'hh:mm',
    ft: 'ft',
    // 明根 等速肌力 律動運動
    hertz: 'Hz',
    kgf: 'kgf',
    millimeters: 'mm',
    millimeters_seconds: 'mm/s',
    joule: 'J',
    gear: 'gear',
    Nm: 'Nm',
    bool: 'Y/N',
    // 睡眠
    times_hour: 'times/hour'
  },
  dataList: {
    massDeleteLimit: '大量削除の上限は 20,000 件です',
    connectUs: 'フィルタ条件を再設定するか、削除のサポートを受けるために管理者に連絡してください',
    isDelete: '本当に削除しますか？',
    deleteCount: '削除筆數',
    deleteCountTotal: '削除筆數共',
    filterCount: '篩選筆數共',
    pleaseEnter: '入力してください',
    handleDeleteAction: '削除を実行するために',
    canNotReverse: '削除されたデータは復元できません。',

    wrongNumber: '数字を入力してください',
    batchDeleteTitle: 'バッチ削除',
    batchDeleteDescription: 'まず「フィルター」機能を使用すると、バッチ削除が可能になります',
    selectData: '請選擇資料類型',
    selectOrg: '請輸入組織',
    totalCount: '總筆數',
    inputType: '請輸入主類型(本項全部)',
    inputSubtype: '請輸入子類型',
    inputUserId: '請輸入使用者Id',
    uploadRange: '上傳區間',
    recordRange: '紀錄區間',
    startDate: '開始日期',
    endDate: '結束日期'
  },
  mainType: {
    // Physiological_type
    BodyComposition: '身体計測値',
    Sleep: '睡眠',
    // PhysicalFitness_type
    AdultPhysicalFitness: '成人の体力',
    ElderPhysicalFitness: '高齢者の体力',
    SportSpecificAbility: '専門トレーニング',
    Workout: 'ワークアウト',
    WeightTraining: 'ウェイトトレーニング',
    StrengthTraining: '筋力トレーニング',
    BodyWeightTraining: '自重トレーニング',
    ResistanceTraining: 'レジスタンストレーニング',
    Pilates: 'ピラティス',
    AerobicExercise: 'エアロビック',
    StretchingExercise: 'ストレッチ',
    Crossfit: 'クロスフィット',
    AirWalker: 'スライディングマシン',
    StairStepper: 'ステップマシン',
    Flywheel: 'スピンバイク',
    Treadmill: 'ランニングマシン',
    Yoga: 'ヨガ',
    IsokineticMuscleStrengthTesting: '等速性筋力測定g',
    IsokineticMuscleStrengthTraining: '等速性筋力トレーニング',
    HarmonicVibrationExercise: 'リズム運動',
    // Sport_type
    Walk: 'ウォーキング',
    Hike: 'ハイキング',
    Run: 'ランニング',
    VirtualRun: 'バーチャルランニング',
    Ride: 'サイクリング',
    VirtualRide: 'バーチャルサイクリング',
    Golf: 'ゴルフ',
    Swim: '水泳',
    Elliptical: 'エリプティカルトレーナー',
    Badminton: 'バドミントン',
    Baseball: '野球',
    TableTennis: '卓球',
    // 夏姿
    VirtualCanoeing: 'バーチャルローイング',
    // 昇揚
    eBikeRide: '電動アシスト自転車',
    // 宇康
    Trampoline: 'トランポリン',
    // 卡洛動
    Boating: '划船'
  },
  subType: {
    LeftHandGripStrengthTest: 'Left Hand Grip Strength Test',
    RightHandGripStrengthTest: 'Right Hand Grip Strength Test',
    StepTest: 'Step Test',
    Cooper12MinuteRunTest: 'Cooper 12 Minute Run Test',
    PushUp: 'Push Up',
    '1MinuteSitUp': '1 Minute Sit Up',
    SitAndReachTest: 'Sit And Reach Test',
    StandAndReachTest: 'Stand And Reach Test',
    CounterMovementJump: 'Counter Movement Jump',
    '505AgilityTest': '505 Agility Test',
    IsometricMidThighPull: 'Isometric Mid Thigh Pull',
    '8FootUpAndGoTest': '8 Foot Up And Go Test',
    SingleLegBalanceLeft: 'Single Leg Balance Left',
    SingleLegBalanceRight: 'Single Leg Balance Right',
    ChairStandTest: 'Chair Stand Test',
    '2MinuteStepTest': '2 Minute Step Test',
    '30SecondArmCurlTest': '30 Second Arm Curl Test',
    '4MeterGaitSpeedTest': '4 Meter Gait Speed Test',
    '4MeterGaitSpeedTest(with Assistive Device)': '4 Meter Gait Speed Test(with Assistive Device)',
    Badminton8PointAgilityTest: 'Badminton 8 Point Agility Test',
    '100m': '100m',
    '200m': '200m',
    '400m': '400m',
    '800m': '800m',
    '1500m': '1500m',
    '3KRun': '3K Run',
    '5KRun': '5K Run',
    '10KRun': '10K Run',
    HalfMarathon: 'Half Marathon',
    Marathon: 'Marathon',
    FitnessBoxing: 'Fitness Boxing',
    HighIntensityIntervalTraining: 'High Intensity Interval Training',
    FatBurning: 'Fat Burning',
    LeftLegEyeClosedSingleLegStanceTest: 'Left Leg Eye Closed Single Leg Stance Test',
    RightLegEyeClosedSingleLegStanceTest: 'Right Leg Eye Closed Single Leg Stance Test',
    BatSwingTest: 'Bat Swing Test',
    PitchingAnalysis: 'Pitching Analysis',
    // 4MeterGait 5TimesStandAndSit BalanceTest
    '4MeterGait': '4 Meter Gait',
    '5TimesStandAndSit': '5 Times Stand And Sit',
    BalanceTest: 'Balance Test',
    Swing: 'Swing',
    GamePitchByPitch: 'Game Pitch By Pitch',
    BatterStats: 'Batter Stats',
    PitcherStats: 'Pitcher Stats',
    CatchingAnalysis: 'Catching Analysis',
    HittingAnalysis: 'Hitting Analysis',
    CoordinationTest: 'Coordination Test',
    // 虹映-徒手訓練
    Lunge: 'Lunge',
    JumpingJacks: 'Jumping Jacks',
    PlankHold: 'Plank Hold',
    StandCrunch: 'Stand Crunch',
    SplitSquat: 'Split Squat',
    ReverseCrunch: 'Reverse Crunch',
    PlankLegLift: 'Plank Leg Lift',
    BirdDog: 'Bird Dog',
    KneeinCrunch: 'Knee-in Crunch',
    CrossKick: 'Cross Kick',
    CookBridge: 'Cook Bridge',
    KneeupLunge: 'Knee-up Lunge',
    SingleLegDeadlift: 'Single Leg Deadlift',
    AlternateToeTouch: 'Alternate Toe Touch',
    PikePlank: 'Pike Plank',
    SidePlankLegLift: 'Side Plank Leg Lift',
    Burpee: 'Burpee',
    Squat: 'Squat',
    // 等速肌力檢測, 等速肌力訓練
    UpperLimbPush_Pull: 'Upper Limb Push-Pull',
    UpperLimbFrontPush_BackPull: 'Upper Limb Front Push-Back Pull',
    UpperLimbAdduction_Abduction: 'Upper Limb Adduction-Abduction',
    LowerLimbAdduction_Abduction: 'Lower Limb Adduction-Abduction',
    LowerLimbUpwardPush_DownwardGlide: 'Lower Limb Upward Push-Downward Glide',
    LowerLimbFrontKick_BackHook: 'Lower Limb Front Kick-Back Hook',
    CoreRightRotation_LeftRotation: 'Core Right Rotation-Left Rotation',
    CoreAbdominalMuscles_BackMuscles: 'Core Abdominal Muscles-Back Muscles',
    // 律動運動
    VerticalHarmonicVibration: 'Vertical Harmonic Vibration',
    PeriodicalAcceleration: 'Periodical Acceleration',
    HandHarmonicVibration: 'Hand Harmonic Vibration',
    // 
    ElectricBicycle: 'Electric Bicycle',
    // 虹映-徒手訓練
    ShoulderCircles: 'Shoulder Circles',
    SeatedKneeRaise: 'Seated Knee Raise',
    SeatedLegRaise: 'Seated Leg Raise',
    SeatedHold: 'Seated Hold',
    KneeRaiseClap: 'Knee Raise Clap',
    BicepCurl: 'Bicep Curl',
    TricepsExtension: 'Triceps Extension',
    ShoulderPress: 'Shoulder Press',
    WeightedSquat: 'Weighted Squat',
    ThoracicStretch: 'Thoracic Stretch',
    OverHeadPulldown: 'Over Head Pulldown',
    SeatedSquat: 'Seated Squat',
    SideLunge: 'Side Lunge',
    KneeLiftTwist: 'Knee Lift Twist',
    ShuttlecockKick: 'Shuttlecock Kick',
    VShapedSquat: 'V-shaped squat',
    // 卡洛動-划船
    SUP: '立槳',
    Canoeing: '輕艇',
    Rowing: '西式划船'
  },
  accessToken: {
    permissionGet: 'Get 取得已上傳資料',
    permissionPost: 'Post 上傳資料',
    permissionDelete: 'Delete 刪除已上傳資料',
    fieldName: '名稱',
    fieldActive: '啟用時間',
    fieldExpired: '有效期(日)',
    fieldScope: '範圍',
    description: '備註',
    created: '已產生完畢',
    saveWarning: '請馬上複製留存，因為你將不會再看見它了',
    getToken: '產生'
  },
  accountManagement: {
    add: '新增',
    org: '会社',
    sidebarBasic: '個人情報',
    sidebarPassword: 'パスワードを変更する',
    sidebarToken: 'Access Token',
    sidebarAccount: 'アカウント',
    sidebarOrganization: '会社管理',
    sidebarConsumer: 'ユーザー管理',
    sidebarDistribute: 'デバイス管理',
    DistributeAppManagement: 'クライアントアプリケーション',
    OrgOwner: '会社のオーナー',
    OrgState: '会社状態',
    description: '述べる',
    name: '名前',
    FirstContact: '主な連絡先',
    memberName: '名前',
    Contact: '主な連絡先',
    ContactName: '主な連絡先',
    ContactTel: '代表連絡先番号',
    email: 'email',
    loginAccount: 'アカウント',
    login: 'アカウント',
    account: 'アカウント',
    PlaceName: '場所',
    DataPlaceName: '場所',
    org_city: '市',
    organization: '会社',
    Authorities: '所轄官庁',
    viewOrganization: '会社一覧',
    Id: '背番号',
    orgId: '背番号',
    OrgIdorNumber: '背番号',
    orgCity: '市',
    phone: '連絡先番号',
    department: '部門',
    title: '役職',
    role: '役割',
    state: '状態',
    active: '有効にする',
    inactive: '無効にする',
    pass: '合格',
    reject: '拒否する',
    edit: '編集',
    password: 'パスワード',
    resetPassword: 'パスワードを再設定する',
    oldPassword: '以前のパスワード',
    newPassword: '新しいパスワード',
    confirmNewPassword: 'パスワードを再入力してください',
    accountInfo: '基本情報',
    changePassword: 'パスワードを変更する',
    AccountRule: '2 ～ 60 文字が必要で、中央の ._- 特殊文字のみを受け入れます',
    PasswordRule: '8 ～ 20 単語、少なくとも 1 つの大文字、1 つの小文字、1 つの数字が必要です',
    validOrgName: '会社名',
    validOrgOwnerName: '会社のオーナー',
    validOrgID: '背番号',
    validLogin: 'アカウント',
    validPwd: 'パスワード',
    validemail: 'email',
    isDeleteOrg: 'この組織の削除を確認します',
    orgMemberlist: '会社メンバー',
    orgMemberCount: '人',
    LoginCheck: 'チェック',
    isDeleteOrgDESC: '組織を削除するとメンバーも削除されます',
    isDeleteMember: 'アカウント削除の確認？',
    accIsOwnerDESC: 'このアカウントは組織の所有者に属しています。削除する場合は、組織の管理に移動して、所有者の権限を他のメンバーに譲渡してください。',
    toOrg: '転送に行く',
    member: {
      add: 'アカウントを追加する',
      edit: 'アカウントの編集'
    },
    organ: {
      name: '組織名稱',
      add: '会社を追加する',
      addOwner: '新增擁有者',
      editOwner: '編輯擁有者',
      edit: '会社の編集'
    },
    consumer: {
      ConsumerReviewDialogTitle: 'ユーザーのレビュー'
    },
    // consumer
    isDuplicate: '帳號重複',
    notDuplicate: '帳號可使用',
    reviewReject: '未通過',
    reviewPending: '未審核',
    version: '版本',
    agreeVersion: '個資同意書',
    dateApply: '申請日期',
    dateReview: '審核日期',
    LastUpdateDate: '更新日期',
    Auditing: '審核者',
    Reason: '審核說明',
    review_status: 'レビュー',
    suggestion: '申請說明',
    // device
    inputReasonForReject: '請填寫審核不通過原因',
    editDeviceForm: '客戶端應用程式編輯',
    reviewed: '已審核',
    reviewing: '審核中',
    reviewRecord: '審核紀錄',
    reviewDescription: '審核說明',
    disabledDate: '停用日期',
    all: '全部'
  },
  Register: {
    Provider: 'プロバイダー',
    Consumer: 'ユーザー',
    DataProvider: 'プロバイダー',
    DataConsumer: 'ユーザー',
    PlatformUser: 'ユーザー',
    ContactUs: 'お問い合わせ',
    PersonalInfoAgree: '個人情報収集同意書',
    TremsOfUse: '利用規約',
    FetchType: 'フェッチタイプ',
    FetchAPi: 'API',
    FetchService: 'Webページからアップロード(Excel)',
    Suggestion: '提案',
    NeedHelp: 'ご質問がございましたらお気軽に',
    ApplyFormTitle: 'プラットフォーム接続アプリケーション',
    placeholderOrgName: '同意書に署名された情報は組織に帰属します',
    placeholderOrgId: '会社登録番号または納税者登録番号',
    placeholderEmail: 'メールアドレス',
    placeholderphone: '電話番号',
    placeholderName: 'ユーザー名',
    placeholderSuggestion: '提案',
    placeholderPlace: '場所を提供する情報を入力してください',
    placeholderDescripUsage: 'データのニーズと公共の福祉の目的について説明してください',
    placeholderPlaceOrgCity: '会場がある郡および市',
    placeholderContactName: '主な連絡先',
    placeholderContactNo: '代表連絡先番号',
    placeholderBelong: '例如：OO署、OO部',
    pleaseCheckAgree: '利用規約に同意するにはボックスにチェックを入れてください',
    pleaseReadAndCheck: '「利用規約」をよくお読みになり、同意する場合はチェックボックスをオンにしてください。',
    RegisterNotify1: '情報が承認されると、7 営業日以内にログイン アカウントとパスワードが届きます。',
    RegisterNotify2: '通知が届かない場合は、お問い合わせください',
    RegisterConsumerNotify1: '居てくれてありがとう',
    RegisterConsumerNotify2: 'できるだけ早く日付をお知らせします。',
    RegisterConsumerNotify3: '若在等待的過程中有任何疑問，隨時',
    ApplySuccess: '成功',
    IReadAndAgree: '私は、プラットフォームに記載されている収集目的に必要な範囲内で私の個人情報が収集、処理、使用されることを読み、同意しました。',
    JoinUs: 'スポーツ データ チャリティーの仲間入りをしましょう',
    WithUs: '私たちと一緒にスポーツテクノロジー業界の発展を推進し、すべての人々のスポーツと健康を促進しましょう',
    DonateData: 'データを提供する',
    UseData: 'データの取得',
    NoAccount: 'アカウントがありません?'
  },
  Organization: {
    Organization_Employer: '会社のオーナー',
    Data_Consumer: 'ユーザー',
    Member: 'メンバー',
    SubAdmin: 'マネージャー',
    Administrator: 'システム管理者',
    consumer: 'ユーザー', // for css
    provider: 'プロバイダー' // for css
  },
  activities: {
    organization_name: '会社',
    user_name: 'ユーザ',
    action_type: 'アクション',
    details: '詳細',
    act_at: '時間',
    DELETE_DATAS: 'データの削除',
    UPLOAD_DATAS_BY_FILE: 'アップロード(ファイル)',
    UPLOAD_DATAS_BY_API: 'アップロード(API)',
    UPLOAD_DATAS_BY_DISTRIBUTION: 'アップロード(デバイス)',
    DOWNLOAD_SDV_DATA: 'データのダウンロード',
    CREATE_ORGANIZATION: 'アカウントを作成する',
    UPDATE_ORGANIZATION: '組織を更新する',
    DELETE_ORGANIZATION: '組織の削除',
    CREATE_MEMBER: 'アカウントを作成する',
    DELETE_MEMBER: '刪除帳',
    UPDATE_MEMBER: 'アカウントを更新する',
    MEMBER_LOGIN: 'ログイン',
    created_at: '作成した',
    ProcessingTimes: '処理時間',
    process_times: '処理頻度',
    log: '處理紀錄',
    proces_user: 'ユーザ'
  },
  Spec: {
    selectDataType: 'タイプを選択してください',
    item: 'プロジェクト',
    columName: 'フィールド名',
    unit: 'ユニット',
    required: '必須',
    nonRequired: 'オプション',
    reasonalbe_data: 'データ範囲',
    Example: '例',
    desciption: '說明',
    noData: 'このプロジェクトにはサブタイプがありません',
    all: '1つ選択してください',
    true: 'true',
    false: 'false'
  },
  Device: {
    DeviceList: 'デバイス一覧',
    SyncMembers: '同步使用者',
    online: '在線',
    offline: '離線',
    error: '錯誤',
    integration: '傳輸中'
  },
  DeviceListField: {
    Status: '狀態',
    OrgName: '組織名稱',
    LoadingStatus: '資料傳輸',
    UpdatedTime: '最後回報時間'
  },
  Form: {
    placeholderSelect: '選んでください',
    placeholderInput: '入ってください',
    placeholderKeyword: 'キーワード'
  },
  errorMessage: {
    // Organization
    1003: '找不到ID為 {organization_id} 的組織。',
    // Member
    2001: '找不到 ID 為 {user_id} 的使用者。',
    2002: '使用者名稱 {name} 違反命名規則。只能使用大寫字母、小寫字母、數字、減號、底線、句點，且頭尾必須是字母或數字，且必須為 2 到 60 個字元。',
    2003: '密碼格式不符合規範。密碼需要為 8 到 20 個字元長，且必須包含至少一個大寫字母，一個小寫字母，以及一個數字(密碼格式可以使用a-z, A-Z, 0-9, !@#$%^&*()_+|{}[]``~-=\'";:/?.>,<)。',
    2004: '密碼或使用者帳號錯誤。',
    2005: '此帳號已有人使用。',
    2009: '該組織成員，權限不足以修改角色權限。',
    2010: '組織的擁有者不可被移除。',
    2011: '請先更換密碼。',
    2100: '此 {member_account} 已經在 15 分鐘內，登入失敗超過 5 次。', 
    2101: '此IP ( {ip} )已經被封鎖了',
    2012: '帳號或信箱錯誤',
    // Access Token
    3005: '您的權限不足已更改用戶存取權限。',
    3101: 'Access_token {access_token} 不存在。',
    3102: '已經過期了 {access_token_name}。',
    3103: '根據你的 Access token, 並沒有權限可以進行此操作。',
    3201: '過期的 Access token。',
    3202: '無效的 Access token',
    // Excel Upload
    4001: '檔案需包含以下欄位',
    4002: '檔案出現未定義的欄位',
    4003: '檔案不應該出現以下欄位',
    4004: '"紀錄日期時間" 應符合 2022-01-01 00:00:00 的格式, 且型態必須為string。',
    4005: '上傳的檔案類型必須為 CSV 或 Excel',
    4006: '檔案裡有整筆空的row',
    4007: '選擇的檔案必須為 CSV, EXCEL 或 ODS',
    4101: '資料庫不存在 {data_type} - {type} - {subtype} - {version} 的資料。',
    4102: '無法產生合成數據。',
    4201: '刪除的數據不可以超過3000筆。',
    // Common Upload Error
    5001: '該版本 {version} 被禁止或不存在。',
    5002: '該數據類型 {data_type} - {type} - {subtype} 不存在或未被定義。',
    5100: '每次上傳，資料總列數不得高於7000筆。',
    // Device
    1004: '啟用金鑰錯誤',
    7021: '裝置權限被拒絕，請聯繫管理員。',
    // Unkown Error
    9001: '非預期錯誤，請聯繫管理者。',
    9002: '無效的 Code path',
    9999: '內部發生未知錯誤，請聯繫管理者。'
  },
  DataExplore: {
    sdv_count: 'データ',
    download_times: 'ダウンロード回数',
    dataEmpty: '本データセット目前無資料',
    downloadDialogTitle: 'ダウンロードAPI',
    interface: 'インターフェース',
    selectedDatatype: '現在選択されている',
    apiKey: 'API Key'
  },
  Privacy: {
    Title_Subject: 'プライバシーポリシー',
    Desc_Subject: 'This Privacy Policy has been formulated on the "Sports Data Altruism Service Platform" (hereinafter referred to as "the Platform") operated by the Administration for Digital Industries, Ministry of Digital Affairs (MODA) to ensure the privacy rights of users when browsing the Platform. To help you understand the information on how the Platform protects the personal data you provide, as well as how it collects, uses, and safeguards various types of information you provide, we kindly suggest you read the following details to ensure a secure and pleasant experience when using the services of the Platform. Furthermore, the personal data collected by the Platform shall be handled in accordance with the specifications set forth in the "Personal Data Protection Act," unless otherwise provided by other laws and specifications.',
    Title_Section1: 'I. Scope of Application',
    Desc_Section1: 'This Privacy Policy applies to the collection, use, and protection of personal data related to your activities on the Platform. However, it does not apply to government agency websites or payment websites linked to the Platform. Each website linked through the Platform has its own privacy policy, and the Platform does not bear any joint responsibility. When you access these websites, the protection of personal data is subject to the privacy policy of each respective website.',
    Title_Section2: 'II. Collection, Processing, and Use of Personal Data on the Platform',
    Desc1_Section2: 'Purpose of collection: The Platform collects your personal data for the purposes of user identity authentication and management, in accordance with the specific purpose and the classification of personal data of the Personal Data Protection Act, including but not limited to "135 Information (Communication) Services," "136 Information (Communication) and database Management," "137 Information and Communication Security and Management," "157 Investigation, Statistics and Research Analysis," etc.',
    Desc2_Section2: 'Category of the personal data collected: This includes personally identifiable data such as name, email address, telephone or mobile number, etc.',
    Desc3_Section2: 'Period, region, and subjects of personal data used:',
    Desc3_1_Section2: '(1) Duration: The duration required to fulfill the specific purposes, the years for retention as stipulated by relevant laws and specifications or contractual agreements, and the necessary retention period for the performance of official duties or business operations by the Platform.',
    Desc3_2_Section2: '(2) Region: In the administrative territory of the Republic of China.',
    Desc3_3_Section2: '(3) Subjects: The operating and management agency of the Platform, as well as necessary third-party entities involved in the operation.',
    Desc3_4_Section2: '(4) Browsing and downloading files on the Platform do not involve the collection of any personally identifiable data.',
    Desc3_5_Section2: `(5) The Platform's system may retain records of your browsing or search activities, such as usage time, browser information, and click data. These records are used for internal analysis of website traffic and online behavior by the Platform's management unit, with the aim of improving the overall service quality of the Platform. There will not be any analysis conducted on any individual users.`,
    Title_Section3: 'III. Sharing Personal Data with Third Parties',
    Desc1_Section3: 'The Platform will never provide, exchange, rent, or sell any of your personal data to other organizations, individuals, or private companies. The use of your personal data is strictly limited to the specific purposes for which it was collected. However, the information may be used outside the scope when one of the following conditions occurs:',
    Desc1_1_Section3: '(1) where it is authorized with your consent;',
    Desc1_2_Section3: '(2) where it is expressly required by law;',
    Desc1_3_Section3: '(3) where it is necessary for ensuring national security or furthering public interest;',
    Desc1_4_Section3: '(4) where it is to prevent harm on your life, body, freedom, or property;',
    Desc1_5_Section3: '(5) where it is necessary for statistics gathering or academic research by a government agency or an academic institution for public interests; provided that such data, as provided by the data provider or as disclosed by the data collector, may not lead to the identification of a specific data subject;',
    Desc1_6_Section3: `(6) where your actions on the Platform violate the Platform's terms of service or may harm or obstruct the Platform's rights and interests, or cause damage to any person, and it is necessary for the Platform to analyze and disclose your personal data for identification, contact, or legal action.`,
    Desc1_7_Section3: `(7) where it is for the data subject's rights and interests.`,
    Desc2_Section3: 'When the Platform entrusts vendors to assist in the collection, processing, or use of your personal data, the Platform will fulfill its responsibility of supervision and management over the outsourced vendors or individuals.',
    Title_Section4: 'IV. Rights and Ways of Exercising Rights by Data Subjects',
    Desc1_Section4: 'After registering as a user on the Platform, you can log in to the user area at any time using your account and password to review, make copies, remark, correct, delete, and request the cessation of collection, processing, or use of the personal data you provided. Please note that once you choose to delete your registered user account, you will no longer be able to log in to the user services on the Platform with that account. You may request to exercise rights of other personal data by contacting the Platform through phone call or online application.',
    Desc2_Section4: `The Platform may retain the right of the data subjects’ request for the deletion or cessation of collection, processing, or use of personal data in the following circumstances:`,
    Desc2_1_Section4: '(1) When it is necessary for government agencies to perform their official or business duties;',
    Desc2_2_Section4: '(2) When there are legitimate reasons that prevent the deletion or cessation of collection, processing, or use.',
    Title_Section5: 'V. Use of Cookies',
    Desc1_Section5: `Cookies are small pieces of information written by information systems to users' hard drives through browsers, serving the purpose of managing needs and providing optimal personalized services. The Platform will write and read cookies in your browser. You can refer to the Platform's instructions on how to configure your browser to accept or reject cookies. Choosing to reject cookies will not affect your general website browsing experience but may cause certain functions of the website not to work properly.`,
    Desc2_Section5: `The aforementioned cookies only identify individual connections and record the web pages browsed by the browser within the Platform. This information is used for statistics on website traffic and browsing patterns analysis, serving as a reference for improving the Platform's services, and it does not identify specific data subjects. If necessary for statistics gathering or academic research by a government agency or an academic institution for the public interest, the data will also be processed in a way that may not lead to the identification of specific data subjects.`,
    Title_Section6: 'VI. Protection Measures to Ensure Personal Data Security',
    Desc_Section6: 'Please keep your personal data properly and do not provide it to any third parties to protect your rights and interests. If you are using a shared computer or a public computer, be sure to log out of your account and close the Platform after use.',
    Title_Section7: 'VII. Revision of Privacy Policy',
    Desc_Section7: 'If there are any revisions to this privacy policy, they will be announced on the Platform. Please check out our Platform for the revision announcements.',
    Title_Section8: 'VIII. Privacy Policy Inquiries (Contact Information)',
    Desc_Section8: 'If you have any questions regarding this policy, please feel free to'
  },
  TermsOfService: {
    Title_Subject: `Sports Data Altruism Service Platform Terms of Service`,
    Desc_Subject: `Welcome to the "Sports Data Altruism Service Platform" (hereinafter referred to as "the Platform")! The Platform is built, operated, and managed by the Institute for Information Industry (hereinafter referred to as "managing entity") entrusted by the Administration for Digital Industries, Ministry of Digital Affairs (MODA) to promote data altruism for the public in Taiwan.`,
    Title_Section1: `I. Sports Data Altruism Service Platform Privacy Policy`,
    Desc_before_link_Section1: `The Platform values the privacy rights of every user. By using the services of the Platform, you understand and agree that your user registration and other specific data will be collected, processed, and used under the protection and specification of the`,
    Desc_after_link_Section1: `Please read the Terms of Service and Privacy Policy carefully before using the Platform's services.`,
    Title_Section2: `II. Consent to the Terms and Changes in the Terms`,
    Desc1_Section2: `The Platform provides various services based on the Terms of Service. When you complete the registration or start using the Platform's services, it means that you have read, understood, and agreed to accept all the contents of the Terms of Service, including the existing and future services and contents derived from the Platform.`,
    Desc2_Section2: `If you cannot comply with the contents of the Terms or do not agree with all or part of the contents of the Terms, or if the Terms are not applicable to your country or region, you should immediately cease using the Platform's services.`,
    Desc3_Section2: `The Platform reserves the right to revise or change the contents of the Terms of Service at any time and will announce such revisions or changes on the Platform. Please make sure to follow the revisions or changes on the Platform at any time. If you continue to use the Platform's services after any revisions or changes, you will be deemed to have read, understood, and agreed to accept such revisions or changes.`,
    Title_Section3: `III. Definitions of Terms`,
    Desc1_Section3: `Service: Refers to various support services provided by the Platform's website and applications.`,
    Desc2_Section3: `Platform User: Refers to users who browse or use the Platform's services.`,
    Desc3_Section3: `Data Provider: Refers to those who connect the Platform and upload sports data.`,
    Desc4_Section3: `Data User: Refers to those who download the sports data, collect, process, and utilize them based on the principles of data altruism and for promoting social development and the related use of development of the sports technology industry.`,
    Title_Section4: `IV.Application Connecting the Platform for Platform User`,
    Desc_Section4: `If you wish to apply as a Data Provider or Data User on the Platform and for connection testing, official launch, modification, or termination of connecting, you should follow the following specifications:`,
    Desc1_Section4: `You should participate in the connection test in the testing environment and may proceed with modifying or terminating of connection upon receiving the official notice regarding the connection.`,
    Desc2_Section4: `Those who have terminated the connection may apply for resuming the connection according to the previous provision.`,
    Desc3_Section4: `Applicants should cooperate with the managing entity for testing and proceed with connection in accordance with the relevant specifications and formats specified by the Platform.`,
    Title_Section5: `V. Matters to Be Complied with by Platform User`,
    Desc1_Section5: `After becoming a data provider or data user, you should comply with the following matters:`,
    Desc1_1_Section5: `(1) If you are a data provider, you should obtain the consent of the data subject when collecting personal data and keep a physical or electronic copy of the consent form of personal data.`,
    Desc1_2_Section5: `(2) If you are a data user, you may only use the data within the scope and purpose of the approved the connecting and must not re-identify the data in any way.`,
    Desc1_3_Section5: `(3) Both data providers and data users should retain records of data transmission for at least six months. The records should at least include the data field names, transmission time, transmission recipients, and the success or failure of the data transmission. The managing entity may conduct audits as necessary.`,
    Desc2_Section5: `Platform users who connect the Platform and utilize data are responsible for ensuring the quality and accuracy of their developed products or services. In case of any legal violations resulting in harm to data subjects, the users will be held accountable for related legal liabilities.`,
    Desc3_Section5: `You understand and agree that all your actions on the Platform must comply with these Terms of Service and relevant laws and specifications. You are solely responsible for all risks and legal liabilities. If you are a user from a country or region other than the Republic of China, you agree to comply with the relevant specifications of your own country or region.`,
    Desc4_Section5: `In the event of data leakage, theft, alteration, loss, or suspected infringement of others' privacy rights or other rights, both data providers and data users must immediately stop connecting and report it to the managing entity.`,
    Desc5_Section5: `The managing entity may request rectification within a specified period for violations of other provisions of these Terms of Service. If the violations are not rectified within the given schedule or if the violations are significant, the managing entity may suspend the connecting services.`,
    Title_Section6: `VI.Service Changes and Notifications`,
    Desc1_Section6: `The Platform strives to provide users with the latest compiled information. However, the form and content of the services provided on the Platform may be added, modified, or terminated at any time without prior notice to users, based on operation development and user demands.`,
    Desc2_Section6: `You agree that the Platform reserves the right to modify, temporarily or permanently terminate the provision of service of the Platform (or any part thereof) at any time. When required by law or other relevant specifications, the Platform may notify you through the Platform's service webpage, email, or other reasonable means. However, if you violate these Terms of Service by unauthorized access to data on the Platform or by providing incorrect or outdated information during registration, you will not receive the aforementioned notifications.`,
    Title_Section7: `VII.Disclaimer`,
    Desc1_Section7: `The Platform may experience interruptions or malfunctions, which may cause inconvenience or errors when you use the Platform. You should take appropriate protective measures when using the Platform. The Platform is not liable for any damages caused by your use (or inability to use) of the Platform's services.`,
    Desc2_Section7: `The various functions, information, and content provided by the Platform are provided as it is at the time of their availability. The original providing agencies or entities are responsible for the completeness, reliability, security, and accuracy of such functions, information, and content. The Platform makes no warranty of any kind, expressed or implied for them.`,
    Desc3_Section7: `When you use the Platform, the data records recorded by the Platform's system during the usage process shall prevail. In the event of any disputes or litigation, the data recorded by the Platform's system shall be the determining standard. If you can provide other data and prove its authenticity, it is not subject to this limitation.`,
    Title_Section8: `VIII. Consultation Service (Contact Information)`,
    Desc1_Section8: `If you have any questions regarding these Terms of Service, please feel free to contact us.`,
    Desc2_Section8: `Email: service@data-sports.tw`,
    Title_Section9: `IX.Others`,
    Desc_Section9: `Due to considerations of laws, technology, market developments, or government policies, the managing entity may modify the service conditions, temporarily suspend, or terminate the services of the Platform.`
  },
  InfoaAree: {
    Title_Subject: `蒐集個人資料告知事項暨個人資料提供同意書`,
    Desc_Subject: `財團法人資訊工業策進會因執行數位發展部數位產業署「運動數據公益平台推動計畫」，運作運動數據公益平台(下稱本平台)，為遵守個人資料保護法令及平台隱私權政策，於向您蒐集個人資料前，依法向您告知下列事項，敬請詳閱。`,
    Title_Section1: `一、蒐集目的及類別`,
    Desc1_Section1: `本平台因執行計畫而需獲取您下列個人資料類別：公司名稱、電子郵遞地址、聯絡人姓名、電話或手機等。`,
    Desc2_Section1: `※您日後如不願再收到本平台所寄送之行銷訊息，可於收到前述訊息時，直接點選訊息內拒絕接受之連結。`,
    Title_Section2: `二、個人資料利用之期間、地區、對象及方式`,
    Desc_Section2: `除涉及國際業務或活動外，您的個人資料僅供本平台於中華民國領域、在前述蒐集目的之必要範圍內，以合理方式利用至蒐集目的消失為止。`,
    Title_Section3: `三、當事人權利`,
    Desc_Section3: `您可依前述業務、活動所定規則或以e-mail方式向本平台服務信箱(service@data-sports.tw)提出申請行使下列權利：`,
    Desc1_Section3: `查詢或請求閱覽。`,
    Desc2_Section3: `請求製給複製本。`,
    Desc3_Section3: `請求補充或更正。`,
    Desc4_Section3: `請求停止蒐集、處理及利用。`,
    Desc5_Section3: `請求刪除您的個人資料。`,
    Title_Section4: `四、不提供個人資料之權益影響`,
    Desc_Section4: `若您未提供正確或不提供個人資料，本平台將無法為您提供蒐集目的之相關服務。`,
    Title_Section5: `五、您瞭解此一同意書符合個人資料保護法及相關法規之要求，且同意本平台留存此同意書，供日後取出查驗。`,
    Desc_Section5: `個人資料之同意提供`,
    Desc1_Section5: `本人已充分獲知且已瞭解上述告知事項。`,
    Desc2_Section5: `本人同意平台所列蒐集目的之必要範圍內，蒐集、處理及利用本人之個人資料。`
  },
  Banner: {
    Accumulated_Data: `利用可能なデータの累積 `,
    Accumulated_Data_Content: `「データ公益」(Data Altruism, データ利他主義)は欧州連合(EU)が近年検討してきた《データ統治法》(The Data Governance Act, DGA)から生じたもので、人々が政府を信頼してデータを提供し、公共利他の研究に役立て、国内のスポーツ科学産業にエネルギーを付与することにより、ソーシャルイノベーションで産業の革新を推し進めます。`,
    Sport_Data: `スポーツデータ`,
    Physical_Fitness_Data: `体力データ`,
    Physiology_Data: `生理学的データ`
  },
  Introduce: {
    Data_Specification: `データ規範の紹介`,
    Data_Specification_Content: `私たちはスポーツデータ専門家と共同でスポーツデータの標準を定義し、一致したデータ規格でデータを共有することにより、異なるデータソースを相互に連携させる機会があります。私たちが現在定義したデータ規格には、野球、バドミントン、ジョギング、ハイキング、フィットネス、体力、生理学的データなどを含む少なくとも65種類の異なるデータタイプを受け入れています。プラットフォームのデータ規格と共有プロセスをもっと理解するには、リンクをクリックして、私たちのデータ規格のページにアクセスすると歓迎です。`
  },
  Dataset: {
    Download: '下載データ集說明',
    DownloadFile: '檔案下載',
    DownloadCount: '下載次數',
    ViewCount: '查看次數',
    Column: '欄位',
    DatasetTitle: 'データ集',
    Dataset: 'データ集',
    DatasetInfo: '情報',
    LoginWithConsumer: '請先以ユーザー身份登入',
    Running_Heart_Rate: `競走心拍数データ集`,
    Running_Heart_Rate_Description: `電子業社員が承知して合意した上に行われた、個人情報の無い匿名化された競走データを収集します。`,
    Running_Heart_Rate_Measure: `心拍数、時速、性別、年齢`,
    Swimming_Rate: `水泳25/50メートルデータ集`,
    Swimming_Rate_Description: `水泳愛好者が承知して合意した上に行われた、匿名化されたスタンダードなプールのスポーツデータを収集します。`,
    Swimming_Rate_Measure: `水上スキーの回数、ストローク距離、ストローク頻度、ペース、水泳効率、往復回数`,
    Outdoor_Biking_Rate: `室外サイクリングデータ集`,
    Outdoor_Biking_Rate_Description: `ユーザーが承知して公益利用に同意した室外サイクリングの各項目のデータを収集します。`,
    Outdoor_Biking_Rate_Measure: `経緯度、速度、距離、総上昇高度、総降下高度、最大速度、回転数`,
    Indoor_Exercise_Rate: `室内スポーツデータ集`,
    Indoor_Exercise_Rate_Description: `ユーザーが承知して公益利用に同意した在宅のスポーツデータを収集します。`,
    Indoor_Exercise_Rate_Measure: `時間帯、運動時間、カリキュラムの名称、性別、年齢`
  },
  Platform: {
    Platform_Policy: `政策的な背景`,
    Policy_Basis: `政策の準拠`,
    Policy_Basis_Section1: `スポーツ×科学技術SRB会議の結論により、5年間（1＋4）部署を跨いだ「スポーツ科学技術の応用と産業発展計画」及び四大戦略を整合する計画の実施が決定されています。各部署の協力を基にスポーツ科学技術産業の全体的な発展の活性化、全国民の運動意欲の促進、引いては健康状況の改善を図ります。`,
    Policy_Basis_Section2: `計画的な戦略においては、デジタル部署に通じて共通的なスポーツデータを発展させ、部署を跨いだ協力体制により新しいインフラ建設を整備し、更に衛福部、教育部、国科会、経済部など各部署の上流・下流フィールドリソースを結合させます。`,
    Vision_Platform: `スポーツデータ公益プラットフォームが願う未来`,
    Vision_Platform_Section1: `分散したスポーツデータ・コンプライアンス・プラットフォームを構築し、使いやすい、取り出しやすい、チャージしやすい公益スポーツサービスを実現`,
    Vision_Platform_Section2: `政府のデータガバナンス・システムに通じて個人データの自主性を保護し、情報セキュリティとプライバシーに対する不安を解消していきます。民衆の政府データに対する信頼性を強化する事で、スポーツ試合、国民スポーツセンター、各階級の体育館、コミュニティが日常的に運動する場所におけるデータの共有と積み重ねを可能にする事で、スポーツデータをオープンで共有される健康的な生態系を構築していきます。`,
    Sports_Everywhere: `リンク : 台湾スポーツ×科学技術行動計画—Sports Everywhere`,
    Personal_info: `針對個人資料保護法之作法`,
    Personal_info_Section1_1: `運動數據公益平台之數據提供者需提供個資當事人簽署「`,
    Personal_info_Section1_2: `」，明確告知當事人運動資料上傳至運動數據公益平台，並供公部門或私部門推動公益事務之參考及運用，以符合個資法第5條及第19條規定。運動數據在取得個資當事人同意，並數據經隱私強化程序後始串接至平台。 `,
    Personal_info_Section2_1: `另平台為確保數據提供者之個資保護管理機制，依據個資法施行細則第12條提供「`,
    Personal_info_Section2_2: `」，由數據提供者自我評估個資保護落實情況，並針對數據提供者個資保護管理流程進行查核，提供符合度建議，以協助數據提供者完善個資保護作業。`,
    Personal_info_Section3_1: `詳情請參閱「`,
    Personal_info_Section3_2: `」。`
  },
  DataUsage: {
    Data_Usage: `データの使用説明`,
    Foreword: `前書き`,
    Foreword_Description: `データ公益プラットフォーム（合法かつルールに遵守）に通じて、研究に利するようなデータ運用法を創造し、国内の運動場、体育館、スポーツ科学技術業者をリードしてデータを貢献、結合させる事で、利他的な新しい応用を誕生させます。`,
    How_and_Who: `どうやって回収するのか、誰が使うべきか `,
    How_and_Who_Section1: `全体的には三つの役割に分割します。其々データを貢献する側、データを収集する側、そしてデータを利用する側となります。貢献側は個人でも企業でもかまいません。収集側は公営・民営の運動場や機関、スポーツイベントの主催者、スポーツAPP業者、スポーツ設備の業者などなど。利用する側は政府機関、民間機関、学界など。`,
    How_and_Who_Section2: `データの流れは収集側から始まり、貢献側（個人/企業）に《個人情報収集告知同意書》を提出し、個人情報を収集する権利を取得します。収集側は授権されたデータを取得した後、リンクを削除して匿名化させ、収集側が保有するこれらのデータをスポーツデータ公益プラットフォームに結合させます。プラットフォームはデータを開示して、利用側はプラットフォームの中でこれらを閲覧します。利用側は業者にデータの使用権限を取得して、提出されたデータを公益に運用する事でフィードバックされ、結果的に公共利益の向上や民衆スポーツの手助けとなるサービスの誕生に繋がります。`,
    Data_Usage_Include: `データ公益の用途は以下が含まれますが、この限りではありません：`,
    Exercise_Advocacy: `スポーツ・アドボカシーの項目研究と分析`,
    Studies_and_Analyses: {
      Road_Section: `スポーツグループが利用するセクションの分析`,
      Time_Slot: `スポーツグループの時間帯の分析`,
      Urban_rural: `都市、地域のデジタル落差`,
      Exercise_Energy: `国内スポーツ活気の分析`,
      Tourism_Festival: `スポーツ・観光シーズンの研究や計画`,
      Fatigue_Recuperation: `試合後疲労回復の分析`,
      Sports_Science: `スポーツ科学の分析や訓練`
    },
    Integrating_Exterior_Data: `外部オープンデータとの結合`,
    Integrating_Exterior: {
      Routes: `スポーツフレンドリーのルートと結合`,
      Shops: `スポーツフレンドリーの店舗と結合`
    }
  },
  Navigator: {
    Site_Navigator: 'サイトマップ',
    Site_Navigator_Include: '当サイトは「ウェブアクセシビリティ設計原則」に従って構築し、これには以下の要点が含まれています：',
    Site_Navigator_Include_Section: {
      Section1: `全てのページがキーボード操作に対応し、マウスによる操作は必須ではありません。`,
      Section2: `ウェブサイトにはショートカットキーの誘導ブロック（:::）が設置されており、ユーザーは目的とするメインブロックに素早く到達する事ができます。`,
      Section3: `サイトの画像は全て、説明として適切な代替文字が標示されています。`,
      Section4: `インターフェースの変更、アドレスの変更、新しいウィンドウを開くリンクには全て、ヒントとなる文字が付け加えられており、ユーザーはリンクを一々クリックせずとも、リンクの用途を知る事ができます。`
    },
    Site_Navigator_Description: '当ウェブサイトの主な内容は三つのブロックに分けられています',
    Site_Navigator_Block: {
      Upper: `上の機能ブロック`,
      Center: `中央の内容ブロック`,
      Lower: `下の機能ブロック`,
      Left: `左の機能ブロック`
    },
    Site_Navigator_Shortcut: {
      AltU: `上の機能ブロックには、トップページに戻る、サイトマップ、開発の手引きなどが含まれています。`,
      AltC: `中央の内容ブロックには、今のページの主な内容があります。`,
      AltL: `左の機能ブロックには、当ウェブサイトサブページの〔サブメニュー〕があります。このブロックは一部のサブページでしか現れません。`,
      AltZ: `下の機能ブロック。`
    },
    Browser: `お使いのブラウザがFirefoxの場合、ショートカットキーの使い方はShift+Alt+（ショートカットキーに対応するアルファベット）となります。例えばShift+Alt+Cを押した場合、ウェブサイトの中央ブロックへ飛び、これによって類推します。`
  },
  FAQ: {
    Q9: '運動數據公益平台法遵相關資訊可在哪裡檢視呢?',
    Q9_Answer: `請參考運動數據公益平台個資評估法遵使用手冊。`
  },
  FAQ_Provider: {
    Eligibility: '資格について',
    About_Eligibility: {
      Q1: `データを提供する人に資格は必要ですか？`,
      Q1_Answer: `プラットフォームに協力してデータを提供する意志のある方、会社、コミュニティは須らく歓迎されます。`,
      Q2: `スポーツデータ公益プラットフォームの利用料金は？`,
      Q2_Answer: `今現在、データ提供者はプラットフォームを無料で利用する事ができます。`
    },
    Compliance: 'コンプライアンスについて',
    About_Compliance: {
      Q1: `提供者としてアップロードしたデータは、本人の同意を必要としますか？`,
      Q1_Answer: `データを提供する場合は、事前に本人の個人情報提供同意書を作成し、照合に備えて保存しておく必要があります。`,
      Q2: `個人情報提供同意書の内容に何か必須項目がありますか？`,
      Q2_Answer1: `個人情報提供同意書の見本を参考してください。`,
      Q2_Answer2: `ここをクリックしてダウンロード。`,
      Q3: `個人情報提供同意書の取得と保存について、何か注意すべき事項がありますか？`,
      Q3_Answer: `本人の同意が得られた事を確定する為、実務的には個人情報提供同意書に署名してもらう方式を採用し、更に書面/電子データを保存してその証明とします。`,
      Q4: `プラットフォームに協力する場所の業者である場合、個人情報提供同意書に親筆の署名が必要ですか？ それとも個人の載具（電子レシート）で署名すれば大丈夫？`,
      Q4_Answer: `個人情報提供同意書に固定のフォーマットはありませんが、証拠となるものを保存する必要があります。関連アプリで記録を行う事も可能であり、親筆の署名は必須ではありません。`
    },
    Field_Specification_and_Data_Quality: 'フィールド（欄）規範とデータの品質',
    About_Field_Specification_and_Data_Quality: {
      Q1: `デジタルフィールド規範とは？ アップロードする際にデジタルフィールド規範を必要とする理由は何ですか？`,
      Q1_Answer: `デジタルフィールド規範を設けたのは、皆様が提供したデータフォーマットを統一する為です。後続した方々が利用しやすいように、提供された全てのデータはプラットフォームが定めたデジタルフィールド規範に適合する必要があります。`,
      Q2: `最新バージョンのデータ規範はどこで見れますか？`,
      Q2_Answer1: `フィールド規範`,
      Q2_Answer2: `を参照してください。`,
      Q3: `測定した項目が規範に存在しない場合、新しいフォーマットでアップロードする事は出来ますか？`,
      Q3_Answer1: `先ずはプラットフォームの`,
      Q3_Answer2: `に提出してください。プラットフォームの内部会議で専門家と討論を行い、調整を行うべきかどうかを検討します。また、推薦者の討論への参加も歓迎です。更新された場合はスポーツデータ・フィールド規範の次のバージョンで実装されます。`,
      Q3_File_name: `欄位規格新增申請文件`,
      Q3_mailto: `メールアドレス`,
      Q4: `測定した項目のフォーマットを、データ規範が定義したフォーマットに変換する必要はありますか？`,
      Q4_Answer: `アップロードしたデータは、プラットフォームが提供したフォーマット規範に適合する必要があります。プラットフォームは二段階の合格検査が採用されており、一段階目でアップロード成功しても、二段階目で弾かれる可能性があります。よって、最初からデータ規範に従って作成した方が、アップロードへの近道となります。`,
      Q5: `ユーザーの生年月日は西暦ですか？ それとも台湾の民国暦ですか？`,
      Q5_Answer1: `当プラットフォームは西暦を、日付の標準フォーマットとして採用しております。`,
      Q5_Answer2: `データが民国暦であった場合はお手数ですが、アップロードする前に西暦へと変換してください。`,
      Q6: `イベント（アクティビティ）名は何を記入すれば良いのでしょうか？`,
      Q6_Answer: `イベント（アクティビティ）名の記入は必須ではありません。場所や計画に応じて自由に記入してください。`,
      Q7: `記録日付のフォーマットは？`,
      Q7_Answer1: `記録日付は該当データを記録した時の日付となります。特定の時間に渡って記録を行った場合は、記録を開始した時間とします。`,
      Q7_Answer2: `日付フォーマットは2022-01-01 00:00:00となります。`,
      Q8: `一つのデータの定義は？`,
      Q8_Answer: `当プラットフォームでは、データのカテゴリ、データの基本資料、測定した項目、以上の内容が含まれたものを一つのデータと定義しております。`,
      Q9: `フィールド（欄）に該当するデータが存在しない場合は、空白にする？ 空のパラメータを記入する？ それとも記入しない？`,
      Q9_Answer: `記入が必須でないフィールドの場合、空のパラメータでも、記入なしでもかまいません。ただし、データの完全性を維持する為、記入するデータをできるだけ増やす事が推奨されます。`
    },
    API_Concatenation: 'API結合',
    About_API_Concatenation: {
      Q1: `APIでデータを結合する方法は何ですか？`,
      Q1_Answer1: `プラットフォームは其々のユーザーに一組の専属API tokenを提供しております。ユーザーは専属のAPI tokenに通じて、データフィールド規範及びOpen APIを使ってデータを結合させる事ができます。`,
      Q1_Answer2: `API結合に関するテクニカルサポートが必要である場合は、プラットフォームにご連絡ください。`,
      Q2: `API結合を使うメリットは？`,
      Q2_Answer: `API結合を利用すれば、ユーザーデータを有効的に整合する事ができ、データを共有する前の作業量を大幅に抑えて、時間の節約に繋がる事ができます。 API結合を利用すれば、ユーザーデータを有効的に整合する事ができ、データを共有する前の作業量を大幅に抑えて、時間の節約に繋がる事ができます。`
    },
    Data_Security: '情報セキュリティについて',
    About_Data_Security: {
      Q1: `プラットフォームはユーザーの個人情報を収集しているのですか？`,
      Q1_Answer1: `後日で行われるデータの統計や分析の為に、できるだけ完備なデータを提供する事が推奨されます。基本となる個人情報で記入が必須であるのは年齢（生年月日）のみ、これ以外の個人情報を提供する必要はありません。`
    },
    Management: '管理について',
    About_Management: {
      Q1: `データの提供者としてどんな管理権限が得られるのですか？`,
      Q1_Answer1: `現在、データの提供者は自分のアカウントに帰属する全てのデータに対して、アップロード、削除、ダウンロードを行う事ができます。`
    }
  },
  JoinUs: {
    Welcome: `こちらから`,
    Slogan1: `データで公益を探索する可能性、手を携えてさらなる価値を創造`,
    Slogan2: `さらに多くの情報を知りたいなら`,
    Slogan3: `データを公共福祉の価値に変換し、より良い未来を一緒に創造します`,
    Slogan4: `私たちと一緒にスポーツデータを公共の福祉に貢献しましょう！`
  },
  City: {
    基隆市: `基隆市`,
    新北市: `新北市`,
    臺北市: `台北市`,
    台北市: `台北市`,
    桃園市: `桃園市`,
    新竹縣: `新竹県`,
    新竹市: `新竹市`,
    苗栗縣: `苗栗県`,
    臺中市: `臺中市`,
    台中市: `台中市`,
    彰化縣: `彰化県`,
    南投縣: `南投県`,
    雲林縣: `雲林県`,
    嘉義縣: `嘉義県`,
    嘉義市: `嘉義市`,
    臺南市: `臺南市`,
    台南市: `台南市`,
    高雄市: `高雄市`,
    屏東縣: `屏東県`,
    宜蘭縣: `宜蘭県`,
    花蓮縣: `花蓮県`,
    臺東縣: `台東県`,
    台東縣: `台東県`,
    澎湖縣: `澎湖県`,
    金門縣: `金門県`,
    連江縣: `連江県`,
    綠島: `綠島`,
    蘭嶼: `蘭嶼`,
    馬祖: `馬祖`,
    其他: `其他`,
    未填: `未填`
  },
  ContactUs: {
    Tel: '連絡電話',
    Email: 'メールアドレス'
  }
}
