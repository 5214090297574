export default {
  // mounted() {
  //   this.keydownHandler = (event) => {
  //     if (event.keyCode === 13) {
  //       console.log(this.$refs)
  //       const buttons = [
  //         this.$refs.buttonLangEnLight,
  //         this.$refs.buttonLangZhLight,
  //         this.$refs.buttonLangEnDark,
  //         this.$refs.buttonLangZhDark
  //       ]
  //       if (buttons.includes(event.target)) {
  //         console.log('handleSetLanguage')
  //         const lang = this.language === 'en' ? 'zh-TW' : 'en'
  //         this.handleSetLanguage(lang)
  //       }
  //     }
  //   }
  //   document.addEventListener('keydown', this.keydownHandler)
  // },
  // beforeDestroy() {
  //   document.removeEventListener('keydown', this.keydownHandler)
  // },
  methods: { 
    handleKeyDownRouter(name, hash) {
      this.$router.push({ name: name, hash: hash })
    }
  }
}
