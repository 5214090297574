import Cookies from 'js-cookie'
import { getLanguage } from '@/lang'

const state = {
  language: getLanguage(),
  activeMenu: '1'
}

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language
    Cookies.set('language', language)
  },
  SET_ACTIVE_ROUTE: (state, route) => {
    state.activeMenu = route
    Cookies.set('activeMenu', route)
  }
}

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language)
  },
  setActiveMenu({ commit }, route) {
    commit('SET_ACTIVE_ROUTE', route)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
