<template>
  <router-link 
    class="navbar-item"
    :to="landingRouter"
    :title="$t('Aria.Goto')+$t('Dashboard.SportsDataCharityService')+$t('Aria.Landing')"
    @keyup.enter.native="handleKeyDownRouter('Landing')"
  >
    <h1
      class="website-title"
    >
      <img :src="logos" class="image" :alt="$t('Dashboard.SportsDataCharityService')">
    </h1>
  </router-link>
</template>

<script>
import { KeyEvents, MenuItem } from '@/Mixins'

export default {
  name: 'LogoWithLinked',
  mixins: [KeyEvents, MenuItem],
  data () {
    return {
      landingRouter: this.isLogin ? 'LandingLogin' : 'Landing'
    }
  },
  computed: {
    logos() {
      return this.isLogin ? require('@/assets/images/logos_text_white.png') : require('@/assets/images/logos_text_color.png')
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.navbar-item{
  text-align: right;
  font-size: 1rem;
  line-height: 60px;
  letter-spacing: 3px;
  .website-title{
    position: relative;
    cursor: pointer;
    margin: initial;
    .image {
      height: 95px;
      display: block;
      position: absolute;
      top: -20px;
    }
  }
}
@media (max-width: 1200px){
  .navbar-item{
    font-size: 1rem;
    color: #606266;
    line-height: 56px;
    height: 56px;
    padding-left: 1rem;
    width: 100%;
    .website-title{
      .image{
        height: 95px;
        top: -70px;
      }
    }
  }
}
</style>
