import request from '@/utils/request'

export const getHomeData = () => request.get(`/dashboard/overview`)
export const getOverview = () => request.get(`/dashboard/summary`)
export const getUploadRatio = () => request.get(`/dashboard/main_type_org_ratio`)
export const getPlaceRatio = () => request.get(`/dashboard/place_type_org_ratio`)
export const getTypeRecord = () => request.get(`/dashboard/main_type_type_record`)
export const getSportTypeRatio = () => request.get(`/dashboard/subsport_type_sport_ratio`)
export const getMainTypeRecord = () => request.get(`/dashboard/maintype_type_record`)
export const getStatistic = () => request.get(`/dashboard/statistic`)
