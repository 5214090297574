import request from '@/utils/request'

export const getExcelData = (params) => request.get(`/data/excel`, { params })
export const getInfoData = () => request.get('/data/info')
export const getDatatype = (params) => request.get('/data/datatype', { params })
export const getDataCount = () => request.get('/data/summary')
export const getProviderDataCount = () => request.get('/data/summary/info')
export const deleteData = (data) => request.delete('/data/excel', { data: data })
export const getSDVdataInfo = (params) => request.get('/sdv/explore', { params }) // 名字等api推上去要改
export const downloadExcelData = (params) => request.get('/data/download', { params, responseType: 'blob' })
export const downloadSDVData = (params) => request.get('/sdv/data', { params, responseType: 'blob' })
export const getDatasetFiles = (params) => request.get('/data/specification/template/example', { params, responseType: 'blob' }) // 第一年的首頁 dataset 下載

export const getSdvData = (params) => request.get(`/sdv`, { params })
