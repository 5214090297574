<template>
  <el-container class="app-wrapper">
    <el-header>
      <ConsumerHeader v-if="isConsumer" />
      <ProviderHeader v-else-if="isOrgTeam" />
      <AdminHeader v-else/>
    </el-header>
    <el-main>
      <router-view />
    </el-main>
    <Footer
      v-if="isConsumer"
      footer-style="consumer-footer"
    />
  </el-container>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css'
import { MemberRole } from '@/Mixins'
import Footer from '@/components/Footer'
import ConsumerHeader from '@/components/Header/ConsumerHeader'
import ProviderHeader from '@/components/Header/ProviderHeader'
import AdminHeader from '@/components/Header/AdminHeader'

export default {
  name: 'Layout',
  components: {
    ConsumerHeader,
    ProviderHeader,
    AdminHeader,
    Footer
  },
  mixins: [MemberRole]
}

</script>

<style scoped lang="scss">
@import "src/styles/index.scss";

.app-wrapper{
  background-color: $bg_site;
  color: $text_default;
  line-height: 1.5;
  font-size: 20px;
  letter-spacing: 1px;
  @media (max-width: 900px) {
    width: fit-content;
  }
  @media (max-width: 576px) {
    width: auto;
  }

  .el-header{
    padding: 0;;
  }

  .el-main{
    // min-height: calc(100vh - 288px); // 如果 provider 需要 footer 改用這段
    min-height: calc(100vh - 60px);
  }

}
</style>
