import { getMemberInfo, login } from '@/api/member'
import { getToken, removeToken, setToken } from '@/utils/auth'
import VueJwtDecode from 'vue-jwt-decode'

const getDefaultState = () => {
  return {
    token: getToken(),
    memberId: 0,
    userRole: 0,
    memberName: '',
    organName: '',
    userEnv: '',
    belowOrg: []
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_MEMBER_ID: (state, memberId) => {
    state.memberId = memberId
  },
  SET_USER_ROLE: (state, userRole) => {
    state.userRole = userRole
  },
  SET_USER_NAME: (state, memberName) => {
    state.memberName = memberName
  },
  SET_ORG_NAME: (state, organName) => {
    state.organName = organName
  },
  SET_BELOW_ORG: (state, belowOrg) => {
    state.belowOrg = belowOrg
  },
  SET_USER_ENV: (state, userEnv) => {
    state.userEnv = userEnv
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { membername, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ membername: membername.trim(), password: password })
        .then((response) => {
          const { data } = response
          const { token } = data
          const jwtContent = VueJwtDecode.decode(token)
          if (!('sub' in jwtContent)) {
            Promise.reject('userId not exist')
          }

          commit('SET_MEMBER_ID', jwtContent['sub'].member_id)
          commit('SET_TOKEN', token)
          setToken(token)

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get e info
  async getInfo({ commit, state, dispatch, rootState }) {
    const token = getToken()
    const jwtContent = VueJwtDecode.decode(token)
    if (!('sub' in jwtContent)) {
      Promise.reject('memberId not exist')
    }

    commit('SET_MEMBER_ID', jwtContent['sub'].member_id)
    commit('SET_TOKEN', token)
    
    const user = await getMemberInfo(state.memberId) 
    if (!user.default_role_id) {
      throw new Error('role is not exist in user info')
    }
    commit('SET_USER_NAME', user.name)
    commit('SET_ORG_NAME', user.organization_name)
    commit('SET_USER_ROLE', user.default_role_id)
    commit('SET_BELOW_ORG', user.below_organizations)
    commit('SET_USER_ENV', user.env)

    // fetching Basic info (versions, required_columns, data_type)
    dispatch('info/getInfo', null, { root: true })
  },

  // user logout
  logout({ commit }) {
    localStorage.clear()
    sessionStorage.clear()
    console.log('logout-removw-token')
    removeToken()
    commit('RESET_STATE')
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      console.log('resetToken-removw-token')
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
