import { getInfoData, getDatatype } from '@/api/data'

const state = {
  roleList: [],
  versions: [],
  dataType: [],
  dataTypes: {}
}

const mutations = {

  SET_ROLE: (state, roleList) => {
    state.roleList = roleList
  },
  SET_VERSION: (state, versions) => {
    state.versions = versions
  },
  SET_DATATYPES: (state, dataTypes) => {
    state.dataTypes = dataTypes
  },
  SET_DATATYPE: (state, dataType) => {
    state.dataType = dataType
  }
}

const actions = {
  async getInfo({ commit, state, dispatch, rootState }) {
    const res = await getInfoData()
    const data = res.data
    const roleList = data.role
    const versions = data.version
    commit('SET_ROLE', roleList)
    commit('SET_VERSION', versions)
  },
  async getDatatype({ commit, state, dispatch, rootState }) {
    const res = await getDatatype()
    const data = res.data
    const datatype = Object.keys(data)
    commit('SET_DATATYPES', data)
    commit('SET_DATATYPE', datatype)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
