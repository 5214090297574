<template>
  <div class="navbar-user">
    <el-dropdown
      trigger="click"
    >
      <div class="user-name">
        <template v-if="!noUserName">
          <el-avatar icon="el-icon-user" size="small" />
        </template>
        <template v-else>
          <div :class="['role-tag', roleColor]">
            {{ $t(`navbar.${roleName}`) }}
          </div>
          <span :class="['display-name', nameClass]" :style="nameStyle">
            {{ userDisplayNam }}
          </span>
        </template>
        <i class="el-icon-caret-bottom" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link
            style="width:100%; display:block;" 
            :to="'/Account/BasicInfo'"
          >
            <span>{{ $t('navbar.AccountManagement') }}</span>
          </router-link>
        </el-dropdown-item>
        <el-dropdown-item v-if="!isConsumer">
          <router-link
            style="width:100%; display:block;" 
            :to="'/Activities'"
          >
            <span>{{ $t('navbar.Activities') }}</span>
          </router-link>
        </el-dropdown-item>
        <el-dropdown-item
          id="systemLogoutBtn"
          divided
          @click.native="logout"
        >
          <span style="display:block;">{{ $t('navbar.logOut') }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
  
</template>

<script>
import { MemberRole } from '@/Mixins'
export default {
  name: 'UserAccount',
  mixins: [MemberRole],
  data () {
    return {}
  },
  computed: {
    noUserName() {
      return !!this.userName
    },
    userDisplayNam() {
      return this.userName.slice(0, 11) // 保留 12 字
    },
    LengthUserName() {
      return this.userDisplayNam.length
    },
    roleColor() {
      if (this.isAdminTeam) {
        return 'admin-team'
      } else if (this.isOrgTeam) {
        return 'org-team'
      } else {
        return 'consumer'
      }
    },
    roleName() {
      if (this.isAdminTeam) {
        return 'admin'
      } else if (this.isOrgTeam) {
        return 'provider'
      } else {
        return 'consumer'
      }
    },
    nameClass() {
      const length = this.LengthUserName
      if (length <= 5) {
        return 'short-name'
      } else if (length <= 11) {
        return 'medium-name'
      } else {
        return 'long-name'
      }
    },
    nameStyle() {
      const length = this.LengthUserName
      if (length <= 5) {
        return { fontSize: '16px' }
      } else {
        return { fontSize: '12px' }
      }
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push('/SportData?redirect=/')
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/index.scss";
.navbar-user{
  .user-name{
    display: flex;
    align-items: center;
    .role-tag{
      height: 20px;
      font-size: 10px;
      color: white;
      line-height: 20px;
      padding: 0 4px;
      margin-right: 4px ;
      border-radius:4px;
    }
    .admin-team{
      background-color: #6C6C6C;
    }
    .org-team{
      background-color: $physicalFitness_turquoise;
    }
    .consumer{
      background-color: $sport_yellow;
    }
    .display-name{
      text-align: center;
      width: 80px;
      @media (max-width: 830px){
        text-align: initial;
        width: initial;
      }
    }

    .short-name {
      white-space: nowrap;
    }
    .medium-name {
      white-space: normal;
      word-break: break-word;
      line-height: initial;
    }
    .long-name {
      white-space: normal;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      line-height: initial;
    }
  }

  .el-dropdown{
    color:$text_light;
    @media (max-width: 1080px){
      color:$text;
    }
  }

  .el-dropdown span{
    &.el-avatar {
      .el-icon-user{
        margin-right: 0;
      } 
    }
  }

  .el-dropdown {
    .el-button{
      background-color: transparent;
      padding: 0;
      border: none;
    }
    .el-button:hover{
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
}
</style>
