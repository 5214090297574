import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import metaImg from './meta'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'
import 'normalize.css/normalize.css'
import i18n, { getLanguage } from './lang'
import 'remixicon/fonts/remixicon.css'

import '@/permission'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/zh-tw'
import './utils/downloadCsvOrExcel'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(duration)

dayjs.locale(getLanguage().toLowerCase())
Vue.prototype.$dayjs = dayjs

Vue.config.productionTip = false
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) })

new Vue({
  router,
  store,
  i18n,
  // metaImg,
  render: h => h(App)
}).$mount('#app')
