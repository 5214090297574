import request from '@/utils/request'
import User from '@/data/user'

export const registerProvider = (data) => request.post('/member/apply/provider', data)
export const registerConsumer = (data) => request.post('/member/apply/consumer', data)
export const login = (data) => request.post('/member/login', data)
export const forgetPassword = (data) => request.post('/member/forget', data)
export const postMember = (data) => request.post('/member', data)
export const updateUser = (memberId, data) => request.put(`/member/${memberId}`, data)
export const deleteUser = (memberId) => request.delete(`/member/${memberId}`)
export const getVistorcount = () => request.get('/member/count/vistor')
export const getMemberList = (params) => request.get('/member/list', { params })
export const getIsLoginDuplicate = (login) => request.get(`/member/login/duplicate/${login}`)
export const getConsumerApplierList = (params) => request.get('/apply/user', { params })
export const updateConsumerApply = (memberId, data) => request.put(`/apply/user_profile/${memberId}`, data)
export const getMemberInfo = async (memberId) => {
  const res = await request.get(`/member/${memberId}`)
  return new User(res.data)
}
