<template>
  <footer :class="['footer-container', footerStyle]">
    <div class="footer-desk-view">
      <a 
        id="AZ"
        href="#AZ"
        accesskey="Z"
        title="尾端功能區塊[快捷鍵Alt+Z]"
        tabindex="0"
        class="sr-only sr-only-focusable"
      >
        :::
      </a>
      <el-row class="footer-body inner-container">
        <el-col :span="8" class="footer-left">
          <router-link 
            class="site-title"
            style="width:100%; display:block;"
            :to="landingRouter"
          >
            {{ $t('Dashboard.SportsDataCharityService') }}
          </router-link>
          <ADIlogo />
          <div class="directed-container">
            <div>
              {{ $t('Dashboard.CommissionedBy') }}
            </div>
          </div>
          <!-- <div class="visit-count">
            {{ $t('general.Visitors') }}
            <span>{{ visitCount }}</span>
          </div> -->
          <AAImage />
        </el-col>
        <el-col :span="16" class="footer-nav">
          <el-row class="menu-container">
            <nav aria-label="footer-nav">
              <template
                v-for="(item, index) in footerMenu"
              >
                <el-col
                  :key="index"
                  :span="7"
                >
                  <div class="menu-title">
                    {{ $t(`navbar.${item.displayName}`) }}
                  </div>
                  <template
                    v-for="(childItem, childIndex) in item.children"
                  >
                    <el-tooltip
                      v-if="childItem.type === 'downloadFile' "
                      :key="childIndex"
                      class="menu-item"
                      effect="dark"
                      :content="$t(`general.${childItem.tooltipContent}`)"
                      placement="right"
                    >
                      <DownloadFileButton
                        :button-type="'button'"
                        :class-name="'footer-desk'"
                        :aria-label="$t(`general.Download`)+$t(`navbar.${childItem.fileName}`)"
                        :file-para="childItem.id"
                        :file-name="`${$t(`navbar.${childItem.fileName}`)}.pdf`"
                        :button-content="$t(`navbar.${childItem.buttonContent}`)"
                      />
                    </el-tooltip>
                    <div
                      v-else-if="childItem.type === 'outerLink' "
                      :key="childIndex"
                      class="menu-item"
                    >
                      <a 
                        :href="childItem.link"
                        :aria-label="$t(`Aria.${childItem.ariaLabel}`)"
                        target="_blank"
                      >
                        {{ $t(`navbar.${childItem.displayName}`) }}
                      </a>
                    </div>
                    <template v-else>
                      <router-link
                        v-if="language === 'zh-TW' || (language !== 'zh-TW' && (!childItem.condition || childItem.condition !== 'zh-TW_only'))"
                        :key="childIndex"
                        style="width:100%;display:block;"
                        :to="{ name: childItem.routerName }"
                      >
                        {{ $t(`navbar.${childItem.displayName}`) }}
                      </router-link>
                    </template>
                  </template>
                </el-col>
              </template>
            </nav>
          </el-row>
        </el-col>
      </el-row> 
    </div>
    <div class="footer-pad-phone-view">
      <el-row>
        <el-col :span="24" class="footer-title">
          <router-link style="
            display:block;" 
            :to="landingRouter"
          >
            <h1>{{ $t('Dashboard.SportsDataCharityService') }}</h1>
            <ADIlogo />
            <div class="org-list">
              {{ $t('Dashboard.CommissionedBy') }}
            </div>
          </router-link>
          <!-- <div class="visit-count">
            {{ $t('general.Visitors') }}
            <span>{{ visitCount }}</span>
          </div> -->
        </el-col>
        <AAImage />
      </el-row>
      <el-row>
        <el-collapse class="menu-collapse">
          <el-collapse-item
            v-for="(item, index) in footerMenu"
            :key="index"
            :name="$t(`navbar.${item.displayName}`)"
            :title="$t(`navbar.${item.displayName}`)"
          >
            <div 
              v-for="(childItem, childIndex) in item.children"
              :key="childIndex"
            >
              <DownloadFileButton
                v-if="childItem.type === 'downloadFile' "
                :button-type="'text'"
                :class-name="'footer-phone'"
                :aria-label="$t(`general.Download`)+$t(`navbar.${childItem.fileName}`)"
                :file-para="childItem.id"
                :file-name="`${$t(`navbar.${childItem.fileName}`)}.pdf`"
                :button-content="$t(`navbar.${childItem.buttonContent}`)"
              />
              <div
                v-else-if="childItem.type === 'outerLink' "
                class="menu-item"
              >
                <a 
                  :href="childItem.link"
                  :aria-label="$t(`Aria.${childItem.ariaLabel}`)"
                  target="_blank"
                >
                  {{ $t(`navbar.${childItem.displayName}`) }}
                </a>
              </div>
              <template v-else>
                <router-link
                  v-if="language === 'zh-TW' || (language !== 'zh-TW' && (!childItem.condition || childItem.condition !== 'zh-TW_only'))"
                  style="width:100%;display:block;"
                  :to="{ name: childItem.routerName }"
                >
                  {{ $t(`navbar.${childItem.displayName}`) }}
                </router-link>
              </template>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-row>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { MemberRole, DownloadFile, MenuItem } from '@/Mixins'
import { getVistorcount } from '@/api/member'
import AAImage from './AAImage.vue'
import ADIlogo from './ADIlogo.vue'
import DownloadFileButton from '@/components/Button/DownloadFileButton.vue'

export default {
  name: 'FooterComponent',
  components: {
    ADIlogo,
    AAImage,
    DownloadFileButton
  },
  mixins: [MemberRole, DownloadFile, MenuItem],
  props: {
    footerStyle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      landingRouter: this.isLogin ? 'LandingLogin' : 'Landing',
      visitCount: null
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  mounted() {
    this.fetchVisitCount()
  },
  methods: {
    async fetchVisitCount() {
      try {
        const res = await getVistorcount()
        this.visitCount = res.data.count ? res.data.count : 0
      } catch (error) {
        console.error('getVistorcount-error: ', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.footer-container {
  color: $text_light;
  background-color: $bg_header_footer;
  font-size: 1rem;
  .footer-desk-view{
    position: relative;
    @include hidden-by-pad;
    @include hidden-by-phone;
    .footer-body{
      padding: 2rem;
      position: relative;
    }

    .inner-container{
      max-width: 1200px;
      margin: 0 auto;
    }

    .footer-left{
      .site-title{
        font-size: 1rem;
        padding: 0 0 20px 0;
        cursor: pointer;
      }

      .directed-container{
        font-size: 0.8rem;
        div{
          padding-bottom: 10px;
        }
      }
      .visit-count{
        padding-top: 10px;
        font-size: 0.8rem;
      }
    }

    .footer-nav{
      .menu-container{
        .menu-title{
          padding-bottom: 10px;
          font-weight: 800;
        }

        a{
          font-size: 0.875rem;
          padding: 10px 0;
        }

        .menu-item{
          height: 36px;
          font-size: 0.875rem;
          line-height: 36px;
          cursor: pointer;
        }
      }
    }
    .el-button{ // [download] desk view only for keyboard event
      color: $text_light;
      background-color: transparent;
      padding: 0;
      border: none;
      letter-spacing: 1px;
    }
  }
  .footer-pad-phone-view{
    @include hidden-by-desk;
    .footer-title{
      padding-left: 5%;
      padding-bottom: 20px;

      h1{
        text-align: initial;
        margin: 0;
        line-height: 60px;  
        font-size: 20px;
        letter-spacing: 1.5px;
      }

      .org-list{
        font-size: 13px;
        text-align: left;
      }
    }
    .visit-count{
      padding-top: 10px;
      text-align: left;
    }
    .menu-collapse{
      text-align: left;
      a{
        font-size: 14px;
        padding: 10px 0;
      }
      .menu-item{
        a{
          display: block;
        }
      }
      ::v-deep .el-collapse-item__header{
        background-color: $bg_header_footer;
        color: #fff;
        padding-left: 5%;
      }
      ::v-deep .el-collapse-item__content{
        padding-bottom: 0;
        background-color: $bg_header_footer;
        color: #fff;
        padding-left: 8%;

        .link-only{
          a{
            line-height: 30px;
            width:100%;
            display:block
          }
        }
      }
    }
  }
}
.consumer-footer {
  background-color: $bg_header_footer_consumer;
  .footer-pad-phone-view{
    .menu-collapse{
      ::v-deep .el-collapse-item__header{
        background-color: $bg_header_footer_consumer;
      }
      ::v-deep .el-collapse-item__content{
        background-color: $bg_header_footer_consumer;
      }
    }
  }
  
}
</style>
