<template>
  <el-dropdown
    trigger="click"
    class="international"
    @command="handleSetLanguage"
  >
    <div
      aria-label="切換語言"
      role="navigation"
      aria-haspopup="true"
    >
      <em class="ri-translate-2" aria-hidden="true" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        :disabled="language === 'zh-TW'"
        command="zh-TW"
      >
        中文
      </el-dropdown-item>
      <el-dropdown-item
        :disabled="language === 'en'"
        command="en"
      >
        English
      </el-dropdown-item>
      <el-dropdown-item
        :disabled="language === 'jp'"
        command="jp"
      >
        日文
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.getters.language
    }
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$dayjs.locale(lang.toLowerCase())
      this.$store.dispatch('app/setLanguage', lang)
      document.title = this.$t(`route.${this.$route.meta.title}`)
      this.$message({
        title: this.$t('general.Success'),
        message: this.$t('Notify.SwitchLanguage'),
        type: 'success'
      })
    }
  }
}
</script>

<style lang="scss">
.el-dropdown{
  font-size: 16px;
}
</style>
