function User(user) {
  if (!user) {
    return
  } 
  this.create_at = user.create_at
  this.department = user.department
  this.status = user.disabled
  this.email = user.email
  this.id = user.id
  this.login = user.login
  this.name = user.name
  this.phone = user.phone
  this.title = user.title
  this.below_organizations = user.below_organizations
  this.update_at = user.update_at
  this.env = user.env
  if (user.default_role) {
    this.default_role_id = user.default_role.id
    this.default_role_name = user.default_role.name
  }
  if (user.belong_organization) {
    this.organization_id = user.belong_organization.id
    this.organization_name = user.belong_organization.name
  }
}

export default User
