<template>
  <div class="access-container">
    <a 
      target="_blank"
      href="https://accessibility.moda.gov.tw/Applications/Detail?category=20221231114842"
      :aria-label="$t('Aria.TargetBlank')+','+$t('Aria.Goto')+'無障礙網路空間服務網'"
    >
      <img :src="srcAA" class="image" alt="通過AA無障礙網頁2.0檢測標章">
    </a>
  </div> 
</template>
<script>
export default {
  name: 'AAImage',
  data() {
    return {
      srcAA: require('@/assets/images/accessibilityAA.png')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/index.scss";
.access-container{
  padding: 1rem 0;
  .image {
    width: 45%;
    display: block;
  }
  @media (max-width: 768px){
    padding: 1rem ;
    padding-left: 5%;
    .image {
      width: 20%;
    }
  }
}

</style>
