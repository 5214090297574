<template>
  <h1 class="website-title">
    <template v-if="isStaging">
      測試環境
    </template>
    <template v-else>
      <img 
        :src="logos"
        class="desk image"
        :alt="$t('Dashboard.SportsDataCharityService')"
      >
      <img 
        :src="logos_rwd"
        class="pad image"
        :alt="$t('Dashboard.SportsDataCharityService')"
      > 
    </template>
  </h1>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LogoWithLinked',
  data () {
    return {
      logos: require('@/assets/images/logos_text_white.png'),
      logos_rwd: require('@/assets/images/logos_text_color.png'),
      loginEnv: ''
    }
  },
  computed: {
    ...mapGetters(['userEnv']),
    isStaging () {
      return this.loginEnv === 'staging'
    }
  },
  mounted() {
    this.loginEnv = this.userEnv
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.website-title{
  position: relative;
  margin: initial;
  width: 210px;
  color: #ffffff;
  @media (max-width: 576px) {
    width: 80px;
  }
  .image {
    height: 90px;
    display: block;
    position: absolute;
    top: -15px;
  }
  .pad{
    display: none;
  }
}
@media (max-width: 1080px){
  .website-title{
    margin: initial;
    padding-left: 20px;
    color: $text_default;
    .image {
      height: 60px;
      display: block;
      top: initial;
    }
    .desk{
      display: none;
    }
  }
}
</style>
