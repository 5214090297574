const getters = {
  language: state => state.app.language,
  activeMenu: state => state.app.activeMenu,
  token: state => state.user.token,
  memberId: state => state.user.memberId,
  userRole: state => state.user.userRole,
  memberName: state => state.user.memberName,
  organName: state => state.user.organName,
  belowOrg: state => state.user.belowOrg,
  userEnv: state => state.user.userEnv,
  roleList: state => state.info.roleList,
  versions: state => state.info.versions,
  dataType: state => state.info.datatype,
  dataTypes: state => state.info.datatypes
}
export default getters
