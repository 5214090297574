import { getStatistic } from '@/api/dashboard'

export default {
  data() {
    return {
      statistic: {}
    }
  },
  mounted() {
    this.getStatistic()
  },
  methods: {
    async getStatistic() {
      try {
        const res = await getStatistic()
        this.statistic = res.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
