import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      role: 0,
      userName: '',
      orgName: '',
      belowOrgList: []
    }
  },
  computed: {
    ...mapGetters(['userRole', 'memberName', 'organName', 'belowOrg']),
    isMember() {
      return this.role === 1
    },
    isConsumer() {
      return this.role === 2
    },
    isOwner() {
      return this.role === 3
    },
    isSubadmin() {
      return this.role === 4
    },
    isAdmin() {
      return this.role === 5
    },
    isAdminTeam() {
      return this.role === 5 || this.role === 4
    },
    isOrgTeam() {
      return this.role === 1 || this.role === 3
    }
  },
  // created() {
  //   if (sessionStorage.getItem('role')) {
  //     this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('role'))))
  //   }

  //   window.addEventListener('beforeunload', () => {
  //     sessionStorage.setItem('role', JSON.stringify(this.$store.state))
  //   })
  // },
  mounted() {
    this.role = this.userRole
    this.userName = this.memberName
    this.orgName = this.organName
    if (this.isSubadmin) {
      this.belowOrgList = this.belowOrg
    }
  }
}
