<template>
  <el-button
    :type="buttonType"
    native-type="button"
    :aria-label="ariaLabel"
    :class="['download-button',className]"
    :loading="fileLoading"
    @click="fetchGetDocumentAPI(filePara, fileName)"
  >
    {{ buttonContent }}
  </el-button>
</template>

<script>
import { DownloadFile } from '@/Mixins'

export default {
  name: 'DownloadFileButton',
  mixins: [DownloadFile],
  props: {
    buttonContent: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: ''
    },
    filePara: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileLoading: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.download-button{ // text, used on FAQ
  color: $primary;
  padding: 0;
  font-size: 1rem;
  border-color: transparent;
}
.download-button:hover{
  color: $text;
  padding: 0;
  font-weight: bold;
  border-color: transparent;
  background-color: transparent;
}

.footer-desk{
  color: $text_light;
  font-size: 0.875rem;
  padding: 0.625rem 0;
}
.footer-phone{
  color: $text_light;
  font-size: 0.875rem;
  padding: 0.625rem 0;
  letter-spacing: 1px;
}
.footer-phone:hover{ // Mobile devices do not have a hover effect
  color: $text_light;
  font-weight: bold;
  padding: 0.625rem 0;
}
.site-map{
  color: $text_dark;
  line-height: 2;
  letter-spacing: 1px;
}
.site-map:hover{
  color: $hover_dark_blue;
}
.site-map:focus{
  border: 2px solid $text_dark;
  background-color: transparent;
}

.event-file{
  padding: 0 1rem;
  background-color: $background_blue;
  color: $text_light;
  line-height: 2;
  letter-spacing: 1px;
  text-decoration: underline;
}
.event-file:hover{
  padding: 0 1rem;
}
</style>
