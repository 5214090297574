export default {
  route: {
    Dashboard: 'Dashboard',
    SportsDataCharityService: 'Sports Data Altruism Service',
    Specification: 'Specification',
    JoinPlatform: 'Join Platform',
    SiteMap: 'Site Navigator',
    FAQ: 'FAQ',
    ContactUs: 'Contact Us',
    Privacy: 'Privacy',
    Terms: 'Terms of Service',
    AboutProject: 'Platform Policy Background',
    DataUsage: 'Data Usage',
    UseCase: 'Data Altruism Cases',
    CheckUpload: 'Check Upload',
    UserRecord: 'User Record',
    Login: 'Login',
    SearchData: 'Search Data',
    QA: 'Q&A',
    SyntheticData: 'Processed Data',
    UploadFile: 'Upload File',
    Basic: 'Basic Info',
    Password: 'Change Password',
    Token: 'Access Token',
    Account: 'Account Management',
    Organization: 'Organization Management',
    Consumer: 'Consumer Management',
    Device: 'Device Management',
    DeviceList: 'Device List',
    Activities: 'Activities',
    DataLog: 'Data Processing Log',
    DataSpec: 'Specification',
    Dataset: 'Dataset',
    Achievements: 'Altruism Cases',
    Sdcamp: '2024實證徵案報名',
    PlatformUpdates: 'Platform Updates',
    // device
    deviceLogin: 'Login',
    deviceApply: 'Apply'
  },
  Dashboard: {
    SportsDataCharityService: 'Sports Data Altruism Service',
    usefulData: 'Applicable data Accumulation',
    Records: 'Records',
    PhysiologyData: 'Physiology Data',
    SportsData: 'Exercise Data',
    PhysicalFitnessData: 'Physical Fitness Data',
    OmnibearingSportsData: 'All-Around Exercise data',
    MDataIntegration: 'Multi-Level Data Integration',
    OrgOwner: 'Owner',
    OrgState: 'State',
    Overview: 'Overview',
    DataTypeRecords: 'Data Type Records',
    DataTypeRatio: 'Data Type Ratio',
    UploadDataRatio: 'Upload Data Ratio',
    UploadCityRatio: 'City Upload Ratio',
    UploadDataRecords: 'Upload Data Record',
    MainDataTypeRecords: 'Main Data Type Records',
    PlaceTypeDataRatio: 'Place Type Data Ratio',
    PlaceTypeDataRecords: 'Place Type Data Records',
    SportTypeDataRecords: 'Sport Type Data Records',
    TypeDataRecords: 'Main Data Type Ratio',
    organization_name: 'Organization',
    LessThanOne: 'Data accounts for less than 1%, looking forward to more contributions from you.',
    org_city: 'City',
    table: 'Table',
    chart: 'Chart',
    total: 'Total',
    Physiology: 'Physiology',
    Sport: 'Sport',
    PhysicalFitness: 'Physical Fitness',
    no: 'No',
    Place: 'Place',
    Type: 'Type',
    // provider
    Thanks: 'Thank you',
    DataProvide: 'Contribute Count',
    WelcomeProviderGreeting: 'Welcome join us, Looking forward to your data contribution.',

    MainTypeRatio: 'Main Type Ratio',
    UploadMainType: 'Upload Main Type',
    ServiceMainType: 'Service Main Type',
    PlaceTypeRatio: 'Place Type Ratio',
    UploadPlaceType: 'Upload Place Type',
    ServicePlaceType: 'Service Place Type',
    Amount: 'amount',
    ContributeCount: 'Contribute Count',
    ContributePercentage: 'Cumulative contribution percentage',
    ContributeDataCount: 'Contribute Data',
    ServiceDataCount: 'Service Data',
    UploadingProgressTitle: 'Real-Time Data Integration Status',
    UploadingProgress: 'Number of items to be transferred',
    CommissionedBy: 'Executive: Institute for Information Industry',
    // Consumer
    UploadDataTimes: 'Upload Data Times',
    CumulativeNumberOfDataProviders: 'Cumulative Number Of Data Providers',
    NumberOfDataProviders: 'Number Of Data Providers'
  },
  Chart: {
    DataTypeRatio: 'Proportion of Data Types',
    Chart_Physiology_Place: 'Place Types of Physiological Data',
    Chart_PhysicalFitness_Place: 'Place Types of Physical Fitness',
    Chart_PhysicalFitnessType: 'Main Types of Physical Fitness',
    Chart_Sport_Place: 'Place Types of Sport Data',
    Chart_Place: `Place`,
    Chart_Sport: `Sports Type`,
    Chart_Gender: `Gender`,
    Chart_City: `Origin of Contributors`,
    Chart_Place_desc: `Distribution of data across various activity places, including school, community, hospital, workplace, health unit, sports center, gym , facility, outdoor, and home.`,
    Chart_Sport_desc: `Data types collected for various sports as defined in the sports data specification, including badminton, walking, table tennis, hiking, baseball, swimming, virtual running, virtual cycling, running, cycling, and others`,
    Chart_Gender_desc: `Gender ratio among the data contributors.`,
    Chart_City_desc: `The originating county or city of the data contributors.`
  },
  LandingStatistic: {
    org_count: 'Provider',
    place_type: 'Place Type',
    device_type: 'Device Type',
    type: 'Main Type',
    org_count_desc: 'Number of registered partners with the platform.',
    place_type_desc: 'Categories of activity places, including school, community, hospital, workplace, health unit, sports center, gym, facility, outdoor, and home.',
    device_type_desc: 'Variety of device categories utilized for data recording.',
    type_desc: 'Total number of sport types defined in sports data specification and successfully gathered in the platform.'
  },
  LandingChart: {
    female: 'Female',
    male: 'Male',
    unknow: 'Unknown'
  },
  general: {
    PhysiologyRawData: 'Physiology', // 生理數據
    SportsRawData: 'Sport', // 運動數據
    PhysicalFitnessRawData: 'Physical Fitness', // 體適能數據
    Datatype: 'Datatype',
    LastUpdateDate: 'Last update',
    Update: 'Updated',
    Sports: 'Sports',
    Sport: 'Sport',
    PhysicalFitness: 'Physical Fitness',
    Physiology: 'Physiology',
    BasicInfo: 'Basic',
    PleaseInput: 'Please enter ',
    Title: 'Title',
    Add: 'Add',
    Edit: 'Edit',
    Action: 'Action',
    Index: 'Index',
    Delete: 'Delete',
    Remove: 'Remove',
    Clean: 'Clean',
    Batch: 'Mass',
    Account: 'Account',
    Password: 'Password',
    Login: 'Login',
    Email: 'email',
    ContactName: 'name',
    Phone: 'contact number',
    ReLogin: 'Re-Login',
    Continue: 'Continue',
    SeeMore: 'See More',
    Visitors: 'cumulative visitors',
    Close: 'Close',
    Success: 'Success',
    Warning: 'Warning',
    Error: 'Error',
    Create: 'Create',
    Active: 'Active',
    Confirm: 'Confirm',
    Save: 'Save',
    Be: 'Be the',
    Notify: 'Notify',
    Cancel: 'Cancel',
    Select: 'Select',
    Filter: 'Filter',
    Download: 'Download',
    Sample: 'Sample',
    DownloadSample: 'Download Sample',
    Copy: 'Copy',
    Apply: 'Apply',
    ApplyNow: 'Apply Now',
    NoData: 'No Data',
    oldPwd: 'Old one',
    newPwd: 'New one',
    confirmPwd: 'Confirm',
    ChangeDatatype: 'Change Datatype',
    SearchPlaceholder: 'Please Input UserID',
    Code: 'Code',
    VerificationCode: 'Verification Code',
    ChangeVerificationCode: 'Change Verification Code',
    VerificationCodeEmpty: 'Verification Code must not be empty',
    ChangePasswordTitle: 'Please Change Password',
    ForgetPassword: 'Forget Password',
    Register: 'register',
    RegisterNow: 'Register Now',
    ContactUs: 'Contact Us',
    DownloadManual: 'Download Manual',
    PageNotFound: 'Page not found',
    Welcome: 'Join us'
  },
  Aria: {
    TargetBlankGotoOpenAPI: 'Open new window to OpenAPI',
    TargetBlank: 'Open new window',
    Mailto: 'Mail to us',
    Goto: 'Go to',
    SamplePic: 'sample photo',
    Page: 'page',
    Landing: 'Landing Page',
    UnCheckTerms: 'Terms of Use not read',
    TermsChecked: 'Terms of Use read',
    ReadInformation: 'Read detail information'
  },
  imgAlt: {
    HowToUseSportData: 'How We Use Sport Data'
  },
  RuleMsg: {
    PleaseInput: 'Please enter',
    PleaseSelect: 'Please select',
    Invalid: ' Invalid',
    Member: ' @:Member.Member',
    Email: ' email',
    memberName: ' user name',
    Password: ' password',
    Description: ' description',
    InputNewPwd: 'Please enter new password',
    InputRepeatPwd: 'Please enter repeat password',
    PasswordLimit: 'Password must be at least 8 characters',
    PasswordNotSame: 'Password not same',
    DifferentNewPassword: 'Your new password must be different from your old password.'
  },
  navbar: {
    admin: 'MGT',
    provider: 'provider',
    consumer: 'consumer',
    logOut: 'Log Out',
    syslog: 'System Log',
    home: 'Home',
    sysActivities: 'User Activities',
    dataProcessinglog: 'Data Processing Log',
    AccountManagement: 'Setting',
    Activities: 'Activities',
    Dashboard: 'Dashboard',
    Data: 'Data',
    Spec: 'Specification',
    DataSpec: 'Specification',
    OpenAPILink: 'OpenAPI',
    Privacy: 'Privacy',
    FAQ: 'FAQ',
    QA: 'Q&A',
    Introduce: 'Data Specification',
    DataConsumer: 'Data Consumer',
    DataProvider: 'Data Provider',
    DataData: 'Data',
    UseCase: 'Use Cases',
    Dataset: 'Dataset',
    DataExplore: 'Data Explore',
    News: 'Latest News',
    Terms: 'Terms of Service',
    ContactUs: 'Contact Us',
    BasicInfo: 'Basic Data',
    CheckFile: 'Check File',
    UploadFile: 'Upload File',
    DataList: 'Data List',
    SyntheticData: 'Processed Data',
    ConsumerData: 'Data',
    SiteMap: 'Site Navigator',
    Guidelines: 'Guidelines',
    Others: 'Others',
    Manual: 'User Manual',
    Compliance_handbook: 'Compliance Handbook',
    About: 'About',
    AboutProject: 'Platform Policy Background',
    DataUsage: 'Data Usage',
    Provider: 'Provider',
    DeviceList: 'Device List',
    Important: 'Important',
    Sdcamp: '2024實證徵案報名'
  },
  File: {
    AddFile: 'Add File',
    Upload: 'Upload',
    Id: 'Id',
    Download: 'Download',
    DragFilesHere: 'or drag and drop files here.',
    ChooseFile: 'Select File',
    InputName: 'Enter Name or Use Upload File Name',
    File: 'File',
    FileSizeLimit: '20 MB, or not exceeding 7000 records.',
    UploadAndSave: 'Upload File and Save',
    MaxFileSize: 'Max file size',
    AllowedFileTypes: 'Allowed file types',
    ConsentForm: '串接運動數據公益平台告知事項暨個人運動資料提供同意書',
    Self_auditChecklist: '運動場域端（數據提供者）蒐集民眾運動資料之個人資料保護自我檢核表',
    Compliance_handbook: '運動數據公益平台個資評估法遵使用手冊'
  },
  Notify: {
    Added: 'add successful',
    Updated: 'update successful',
    Deleted: 'delete successful',
    Canceled: 'cancel successful',
    Uploaded: 'upload successful',
    Created: 'create successful',
    Copied: 'Copied',
    TemporarySaved: 'Temporary Saved successful',
    Submit: 'Submit',
    Saved: 'Saved successful',
    NoEmpty: 'Content must not be empty',
    Same: 'The new value is same with old value.',
    pleaseInput: 'Please enter',
    UpdatedReLogin: 'Success! Please Login with new password.',
    ReLogin: 'Please login again',
    SwitchLanguage: 'Switch Language Success',
    SingleFileLimit: 'Only one file can be added at a time, please delete the existing file first.',
    UnsupportedFileFormat: 'Unable to upload a file: This file type is not supported',
    FileSizeLimit: 'This file cannot be uploaded because it exceeds the maximum allowed file size ({size})',
    FileNameLimit: 'A filename cannot contain any of the special characters.',
    LoadFail: 'Something went wrong, please contact your system administrator.',
    UnSavedChanges: 'Do you really want to leave? you have unsaved changes!',
    logoutNotifications: 'The session has timed out. Please click re-login button to login back again.',
    
    VerificationCodeError: 'Verification Error',
    CheckLoginDuplicate: 'Please check if the account is duplicated',
    Name: 'Please enter a name',
    Date: 'Please select expire date',
    permission: 'Please select at least one permission.',
    // login
    lockIPAfter5Error: 'If there are more than 5 login failures within 15 minutes, the IP will be locked.',
    
    // register
    Authorities: 'Please enter competent authorities for businesses.',
    ChoiceFetchType: 'Choose at least one method',
    UncheckPersoInfoAgree: 'You have not check the Personal Data Collection Agreement',

    DownloadSource: 'Properly cite the source according to copyright laws to encourage information sharing.',
    DownloadLimit: 'Download up to 2000 records from here.',

    confirmDelete: 'Are you sure to @:(general.Delete)?',
    confirmClose: 'Are you sure to @:(general.Close)?',
    confirmDeleteSth: 'Are you sure to @:(general.Delete)「{name}」？',
    forgetPasswordSuccess: 'We will assist you in changing password as soon as possible.',
    patientWhenDeleteData: 'Deleting data, estimated to be completed within 30 seconds. Thank you for your patience.'
  },
  excelUpload: {
    missing_required_fields_nums: 'Missing Required Fields',
    CheckFile: 'Check File',
    duplicate_rows_num: 'Duplicate',
    total: 'Total',
    success_num: 'Success',
    index: 'Data Row',
    userId: 'User Id',
    missing_fields: 'Missing Fields',
    MustChoiceOne: 'Must Have One',
    missing_MustChoiceOne: 'Missing "Must Have One" Field',
    checkErrorMessage: 'Check Error Message',
    testErrorMessage: 'Check Error Message',
    checkReportMessage: 'Check Message',
    Important: 'Important',
    checkBeforeUpload_1: 'To save your time, please go to: ',
    checkBeforeUpload_2: 'check data type before upload file.',
    checkOnly: 'This page only does format check. If you want to upload, please go to',
    gotoUpload: 'Upload File',
    uploadTitle: 'Sport Data Upload',
    success_upload: 'Success',
    total_upload: 'Total',
    missing_upload: 'Missing Data',
    duplicate_upload: 'Duplicate Data',
    correct_upload: 'Correct！',
    toUploadPage: 'Go to upload page',
    count: '(Records)'
  },
  PlaceType: {
    school: 'School',
    community: 'Community',
    hospital: 'Hospital',
    workplace: 'Workplace',
    health_unit: 'Health Unit',
    sports_center: 'Sports Center',
    gym: 'Gym',
    facility: 'Facility',
    outdoor: 'Outdoor',
    home: 'Home',
    openWater: 'Open Water',
    unknow: 'Unknown'
  },
  field: {
    // basic
    version: 'Version',
    main_type: 'Data Type',
    type: 'Main Type',
    subtype: 'Subtype',
    main_type_zh: 'Data Type(zh)',
    type_zh: 'Main Type(zh)',
    subtype_zh: 'Subtype(zh)',
    user_id: 'User ID',
    birth_year: 'Birth Year',
    gender: 'Gender',
    city: 'City',
    start_date: 'Record date',
    created_at: 'Created date',
    timezone: 'Timezone',
    name: 'Activity Name',
    device_type: 'Device Type',
    place_type: 'Place Type',
    organization_name: 'Organization Name',
    // place
    school: 'School',
    community: 'Community',
    hospital: 'Hospital',
    workplace: 'Workplace',
    health_unit: 'Health Unit',
    sports_center: 'Sports Center',
    gym: 'Gym',
    facility: 'Facility',
    outdoor: 'Outdoor',
    home: 'Home',
    // measure
    height: 'Height',
    weight: 'weight',
    waist_circumference: 'Waist Circumference',
    body_fat_percentage: 'Body Fat Percentage',
    body_mass_index: 'Body Mass Index',
    skeleton_muscle_mass: 'Skeleton Muscle Mass',
    deep_sleep_count: 'Deep Sleep Count',
    light_sleep_count: 'Light Sleep Count',
    deep_sleep_time: 'Deep Sleep Time',
    light_sleep_time: 'Light Sleep Time',
    achievement_length: 'Achievement Length',
    systolic_blood_pressure: 'Systolic Blood Pressure',
    diastolic_blood_pressure: 'Diastolic Blood Pressure',
    peripheral_oxyhemoglobin_saturation: 'Peripheral Oxyhemoglobin Saturation',
    blood_sugar: 'Blood Sugar',
    basal_metabolic_rate: 'Basal Metabolic Rate',
    heart_rate: 'Heart Rate',
    resting_heart_rate: 'Resting Heart Rate',
    average_heart_rate: 'Average Heart Rate',
    max_heart_rate: 'Max Heart Rate',
    heart_rate_variability: 'Heart Rate Variability',
    temperature: 'Temperature',
    respiratory_rate: 'Respiratory Rate',
    vo2_max: 'VO2 Max',
    cardiorespiratory_fitness_index: 'Cardiorespiratory Fitness Index',
    calories: 'Calories',
    distance__m: 'Distance(m)',
    distance__km: 'Distance(km)',
    moving_time: 'Moving Time',
    elapsed_time: 'Elapsed Time',
    start_latitude: 'Start Latitude',
    start_longitude: 'Start Longitude',
    end_latitude: 'End Latitude',
    end_longitude: 'End Longitude',
    total_elevation_gain: 'Total Elevation Gain',
    elevation_high: 'Elevation High',
    elevation_low: 'Elevation Low',
    speed_ms: 'Speed(m/s)',
    speed__km_hr: 'Speed(km/hr)',
    average_spee__m_s: 'Average Speed(m/s)',
    average_speed__km_hr: 'Average Speed(km/hr)',
    max_speed__m_s: 'Max Speed(m/s)',
    max_speed__km_hr: 'Max Speed(km/hr)',
    ride_cadence: 'Ride Cadence',
    average_ride_cadence: 'Average Ride Cadence',
    run_cadence: 'Run Cadence',
    average_run_cadence: 'Average Run Cadence',
    kilojoules: 'Kilojoules',
    average_watts: 'Average Watts',
    max_watts: 'Max Watts',
    weighted_average_watts: 'Weighted Average Watts',
    steps: 'Steps',
    achievement_count: 'Achievement Count',
    training_weight: 'Training Weight',
    jump_height: 'Jump Height',
    upper_extremity_muscle_strength: 'Upper Extremity Muscle Strength',
    lower_extremity_muscle_strength: 'Lower Extremity Muscle Strength',
    total_elevation_loss: 'Total Elevation Loss',
    max_ride_cadence: 'Max Ride Cadence',
    max_run_cadence: 'Max Run Cadence',
    average_pace__sec_km: 'Average Pace(sec/km)',
    total_length_count__25m: 'Total Length Count(25m)',
    total_length_count__50m: 'Total Length Count(50m)',
    total_stroke_count: 'Total Stroke Count',
    average_distance_per_stroke: 'Average Distance Per Stroke',
    average_stroke_rate: 'Average Stroke Rate',
    average_swim_pace_sec_25m: 'Average Swim Pace(sec/25m)',
    average_swim_pace_sec_50m: 'Average Swim Pace(sec/50m)',
    swim_swolf__25m: 'Swim SWOLF(25m)',
    swim_swolf__50m: 'Swim SWOLF(50m)',
    sleep_efficiency: 'Sleep Efficiency',
    route_name: 'Route Name',
    bicycle_model: 'Bicycle(Brand)',
    shoe_model: 'Shoe(Type)',
    gpx: 'Activity(gpx)',
    fit: 'Activity(fit)',
    tcx: 'Activity(tcx)',
    competition_event: 'Competition Event',
    competition_name: 'Competition Name',
    competition_result: 'Competition Result',
    competition_score: 'Competition Score',
    competition_score_opposing: 'Competition Score Opposing',
    competition_rank: 'Competition Rank',
    body_fat_mass: 'Body Fat Mass',
    fat_free_mass: 'Fat Free Mass',
    fat_free_mass_index: 'Fat Free Mass Index',
    visceral_fat_index: 'Visceral Fat Index',
    protein: 'Protein',
    mineral: 'Mineral',
    total_body_water: 'Total Body Water',
    body_age: 'Body Age',
    left_side_sbp: 'Left-Side SBP',
    right_side_sbp: 'Right-Side SBP',
    left_side_dbp: 'Left-Side DBP',
    right_side_dbp: 'Right-Side DBP',
    left_side_pulse_pressure: 'Left-Side PP(Pulse Pressure)',
    right_side_pulse_pressure: 'Right-Side PP(Pulse Pressure)',
    left_side_mean_arterial_pressure: 'Left-Side MAP(Mean Arterial Pressure)',
    right_side_mean_arterial_pressure: 'Right-Side MAP(Mean Arterial Pressure)',
    bat_speed: 'Bat speed',
    peak_hand_speed: 'Peak hand speed',
    time_to_contact: 'Time to contact',
    attack_angle: 'Attack angle',
    vertical_bat_angle: 'Vertical bat angle',
    on_plane_efficiency: 'On plane efficiency',
    rotation_acceleration: 'Rotation acceleration',
    early_connection: 'Early connection',
    connection_at_impact: 'Connection at impact',
    power: 'Power',
    hitting_type: 'Hitting type',
    vdot: 'Vdot',
    heart_rate_reserve__70: '70% Heart Rate Reserve',
    heart_rate_reserve: 'Heart Rate Reserve',
    condition_index: 'Condition Index',
    physical_fitness_index: 'Physical Fitness Index',
    fatigue_index: 'Fatigue Index',
    step_length: 'Step Length',
    training_volume: 'Training Volume',
    running_target: 'Running Target',
    run_type: 'Run Type',
    run_training_status_source: 'Run Training Status Source',
    // badminton
    maximum_swing_strength: 'Maximum Swing Strength',
    average_swing_strength: 'Average Swing Strength',
    fastest_swing_speed: 'Fastest Swing Speed',
    average_swing_speed: 'Average Swing Speed',
    fastest_smashing_speed: 'Fastest Smashing Speed',
    average_smashing_speed: 'Average Smashing Speed',
    // table tennis
    swing_count: 'Swing Count',
    ax_mean: 'ax_mean',
    ay_mean: 'ay_mean',	
    az_mean: 'az_mean',
    gx_mean: 'gx_mean',	
    gy_mean: 'gy_mean',	
    gz_mean: 'gz_mean',
    // 4MeterGait 5TimesStandAndSit BalanceTest
    gait_speed: 'Gait Speed',	
    shoulder_angle_difference: 'Shoulder Angle Difference',	
    hip_angle_difference: 'Hip Angle Difference',	
    knee_angle_difference: 'Knee Angle Difference',	
    chair_stand: 'Chair Stand',
    side_by_side_stand: 'Side By Side Stand',
    semi_tandem_stand: 'SemiTandem Stand',
    tandem_stand: 'Tandem Stand',
    // PitchingAnalysis
    ball_velocity: 'Ball Velocity',
    total_spin: 'Total Spin',
    true_spin: 'True Spin',
    spin_direction: 'Spin Direction',
    spin_efficiency: 'Spin Efficiency',
    release_angle: 'Release Angle',
    horizontal_angle: 'Horizontal Angle',
    release_height: 'Release Height',
    release_side: 'Release Side',
    age: 'Age',
    level: 'Level',
    // strength-training
    shoulder_press: 'Shoulder Press',
    chest_press: 'Chest Press', 
    rowing: 'Rowing',
    back_extension: 'Back Extension',
    back_flexion: 'Back Flexion',
    knee_extension: 'Knee Extension',
    knee_flexion: 'Knee Flexion',
    hip_abduction: 'Hip Abduction',
    hip_adduction: 'Hip Adduction',
    leg_press: 'Leg Press',
    // 擊球練習
    accuracy: 'Accuracy',
    // 灼見
    year: 'Year',
    date: 'Date',
    pitcher_id: 'Pitcher Id',
    pitch_throw: 'Pitch Throw',
    pitcher_team: 'Pitcher Team',
    batter_id: 'Batter Id',
    batter_side: 'Batter Side',
    batter_team: 'Batter Team',
    inning: 'Inning',
    top_bottom: 'Top Bottom',
    balls: 'Balls',
    strikes: 'Strikes',
    outs: 'Outs',
    on_base: 'On Base',
    pitch_action: 'Pitch Action',
    pitch_type: 'Pitch Type',
    hit_type: 'Hit Type',
    hit_strength: 'Hit Strength',
    pa_result: 'Pa Result',
    kzone_y: 'Kzone Y',
    kzone_z: 'Kzone X',
    pitch_velo: 'Pitch Velo',
    exit_distance: 'Exit Distance',
    park: 'Park',
    game_level: 'Game Level',
    game_id: 'Game Id',
    game_type: 'Game Type',
    PA: 'PA',
    AB: 'AB',
    R: 'R',
    H: 'H',
    RBI: 'RBI',
    '2B': '2B',
    '3B': '3B',
    HR: 'HR',
    DP: 'DP',
    TP: 'TP',
    BB: 'BB',
    IBB: 'IBB',
    HBP: 'HBP',
    K: 'K',
    SH: 'SH',
    SF: 'SF',
    FOTE: 'FOTE',
    SB: 'SB',
    CS: 'CS',
    pitches: 'pitches',
    BF: 'BF',
    WP: 'WP',
    BK: 'BK',
    runs_allowed: 'runs_allowed',
    ER: 'ER',
    // ..
    ball_speed: 'Ball Speed',
    club_head_speed: 'Club Head Speed',
    smash_factor: 'Smash Factor',
    total_dist_ft: 'Total Dist Ft',
    carry_dist_ft: 'Carry Dist Ft',
    dist_to_pin_ft: 'Dist To Pin Ft',
    club_angle_path: 'Club Angle Path',
    club_angle_face: 'Club Angle Face',
    launch_angle: 'Launch Angle',
    launch_direction: 'Launch Direction',
    golf_back_spin: 'Golf Back Spin',
    golf_side_spin: 'Golf Side Spin',
    // baseball
    baseball_release_time: 'Baseball Release Time',
    baseball_travel_time: 'Baseball Travel Time',
    baseball_side_spin: 'Baseball Side Spin',
    baseball_top_spin: 'Baseball Top Spin',
    gyro_degree: 'Gyro Degree',
    horizontal_break: 'Horizontal Break',
    vertical_break: 'Vertical Break',
    baseball_total_spin: 'Baseball Total Spin',
    exchange_time: 'Exchange Time',
    pop_time: 'Pop Time',
    bounces: 'Bounces',
    sweet_spot: 'Sweet Spot',
    forefoot_presure_ratio: 'Forefoot Pressure Ratio',
    heel_presure_ratio: 'Heel Pressure Ratio',
    linited_internal_rotation: 'Limited Internal Rotation',
    over_internal_rotation: 'Over Internal Rotation',
    activity_index: 'Activity Index',
    TDEE: 'TDEE',
    district: 'District',
    OS_version: 'OS Version',
    vertical_jump: 'Vertical Jump',
    broad_jump: 'Broad Jump',
    hop: 'Hop',
    squat: 'Squat',
    isometric: 'Isometric',
    sprint: 'Sprint',
    reaction_speed: 'Reaction Speed',
    // 棒式支撐
    achievement_time: 'Achievement Time',
    // 虹映-睡眠
    rem_sleep_time: 'REM Sleep Time',
    awake_time: 'Awake Time',
    // 明根
    usage_times: 'Usage times',
    total_work: 'Total work',
    average_work_per_second: 'Average work per second',
    resistance_value: 'Resistance Value',
    setting_speed: 'Speed',
    vertical_amplitude: 'Vertical Amplitude',
    vertical_frequency: 'Vertical Frequency',
    horizontal_amplitude: 'Horizontal Amplitude',
    horizontal_frequency: 'Horizontal Frequency',
    frequency: 'Frequency',
    max_strength_push_up: 'Max strength for Push-up',
    max_strength_pull_down: 'Max strength for Pull-down',
    max_output_push_forward: 'Max output of push forward',
    max_output_pull_back: 'Max output of pull back',
    max_output_press_inward: 'Max output of press-inward',
    max_output_spread_outward: 'Max output of spread-outward',
    max_output_legs_extended: 'Max output of legs extended',
    max_strength_front_kick: 'Max strength for Front kick',
    max_strength_hook_kick: 'Max strength for Hook kick',
    max_output_left_rotate: 'Max output of left-rotate',
    max_output_right_rotate: 'Max output of right-rotate',
    max_output_bend_forward: 'Max output of bend-forward',
    max_output_straighten_upright: 'Max output of straighten-upright',
    back_forth_max_force_ratio: 'Back and forth max force ratio',
    max_force_body_weight_ratio_push_up: 'Max force to body weight ratio for Push-up',
    max_force_body_weight_ratio_Pull_down: 'Max force to body weight ratio for Pull-down',
    max_force_body_weight_ratio_push_forward: 'Max force to body weight ratio of push forward',
    max_force_body_weight_ratio_pull_back: 'Max force to body weight ratio of pull back',
    max_force_body_weight_ratio_press_inward: 'Max force to body weight ratio of press-inward',
    max_force_body_weight_ratio_spread_outward: 'Max force to body weight ratio of spread-outward',
    max_force_body_weight_ratio_legs_extended: 'Max force to body weight ratio of legs extended',
    max_force_body_weight_ratio_front_kick: 'Max force to body weight ratio for Front kick',
    max_force_body_weight_ratio_hook_kick: 'Max force to body weight ratio for Hook kick',
    max_force_body_weight_ratio_left_rotate: 'Max force to body weight ratio of left-rotate',
    max_force_body_weight_ratio_right_rotate: 'Max force to body weight ratio of right-rotate',
    max_force_body_weight_ratio_bend_forward: 'Max force to body weight ratio of bend-forward',
    max_force_body_weight_ratio_straighten_upright: 'Max force to body weight ratio of straighten-upright',
    // 虛擬划船
    standard_level: 'Standard(%)',
    ebk_ass:	'ebk Ass',
    ebk_speed__km_hr:	'Ebk Speed(km/hr)',
    ebk_tor:	'Ebk Tor',
    hr_sta:	'Heart Rate Status',
    // 跳床
    average_hang_time:	'Average hang time',
    duration_trampoline:	'Duration',
    // 睡眠
    average_sp_o2:	'Average SpO2',
    oxygen_desaturation_3:	'Oxygen Desaturation Index 3%',
    oxygen_desaturation_4:	'Oxygen Desaturation Index 4%',
    average_pulse_rate:	'Average Pulse Rate',
    average_respiration_rate:	'Average Respiration Rate',
    total_record_time:	'Total Record Time',
    time_in_bed:	'Time in Bed',
    sleep_onset_latency:	'Sleep Onset Latency',
    wake_after_sleep_onset:	'Wake After Sleep Onset',
    wake_after_sleep_offset:	'Wake After Sleep Offset',
    total_sleep_time:	'Total Sleep Time',
    T90:	'T90',
    T89:	'T89',
    T88:	'T88',
    T50:	'T50',
    T120:	'T120',
    T10:	'T10',
    T20:	'T20'
  },
  Unit: {
    cm: 'cm',
    kg: 'kg',
    kg_m2: 'kg/m2', 
    counts: 'counts',
    minutes: 'min',
    mmHg: 'mmHg',
    mg_dl: 'mg/dl',
    kilocalories: 'kcal',
    beats_per_minute: 'BPM',
    milliseconds: 'ms',
    celsius: '℃',
    ml_kg_minute: 'ml/kg/minute',
    sec_pulses: 'Sec*100/[2×(total number of 3 pulses)]',
    meters: 'm',
    kilometers: 'km',
    latitude: 'lat',
    longitude: 'lng',
    meters_per_second: 'm/sec',
    kilometers_per_hour: 'km/h',
    revolutions_per_minute: 'RPM',
    steps_per_minute: 'SPM',
    kilojoules: 'kJ',
    watts: 'W',
    steps: 'steps',
    seconds_per_kilometers: 'sec/km',
    number_of_length: 'number of length',
    meters_per_stroke: 'meters/cycle',
    strokes_per_minute: 's/m',
    seconds_per_25m: 'sec/25m',
    seconds_per_50m: 'sec/50m',
    SWOLF: 'SWOLF',
    years_old: 'YO',
    seconds: 'seconds',
    degrees: 'degrees',
    percentage: 'percentage',
    G__meter_per_second_squared: 'G(meter per second squared)',
    kilo_watt: 'kilo watt',
    vdot: 'vdot',
    index: 'index',
    // badminton
    newton: 'N',
    // table tennis
    times: 'times',	
    g: 'g',
    degrees_per_second: '°/sec',
    // new
    pt: 'point',
    cm_s: 'cm/s',
    // PitchingAnalysis
    rpm: 'RPM',
    hh_mm: 'hh:mm',
    ft: 'ft',
    // 明根 等速肌力 律動運動
    hertz: 'Hz',
    kgf: 'kgf',
    millimeters: 'mm',
    millimeters_seconds: 'mm/s',
    joule: 'J',
    gear: 'gear',
    Nm: 'Nm',
    bool: 'Y/N',
    // 睡眠
    times_hour: 'times/hour'
  },
  dataList: {
    massDeleteLimit: 'Mass delete can only delete 20000 data.',
    connectUs: 'Please reset filter, or connect administrator to delete data.',
    isDelete: 'Are you sure you want to delete this item?',
    deleteCount: 'Delete',
    deleteCountTotal: 'This action will delete',
    filterCount: 'You have selected',
    pleaseEnter: 'Please enter',
    handleDeleteAction: 'to proceed with deletion',
    canNotReverse: 'Deleted data cannot be restored.',
    
    enterNumber: 'Please enter the number',
    wrongNumber: 'Please enter the correct number',
    batchDeleteTitle: 'Batch delete',
    batchDeleteDescription: 'Use the "Filter" function first to enable Batch Delete.',
    selectData: 'Please select type',
    selectOrg: 'Please select Organization',
    totalCount: 'Total',
    inputType: 'Please enter main type',
    inputSubtype: 'Please enter subtype',
    inputUserId: 'Please enter UserId',
    uploadRange: 'Upload range',
    recordRange: 'Record range',
    startDate: 'Start date',
    endDate: 'End date'
  },
  mainType: {
    // Physiological_type
    BodyComposition: 'Body Composition',
    Sleep: 'Sleep',
    // PhysicalFitness_type
    AdultPhysicalFitness: 'Adult Physical Fitness',
    ElderPhysicalFitness: 'Elder Physical Fitness',
    SportSpecificAbility: 'Sport Specific Ability',
    Workout: 'Workout',
    WeightTraining: 'Weight Training',
    StrengthTraining: 'Strength Training',
    BodyWeightTraining: 'Body Weight Training',
    ResistanceTraining: 'Resistance Training',
    Pilates: 'Pilates',
    AerobicExercise: 'Aerobic Exercise',
    StretchingExercise: 'Stretching Exercise',
    Crossfit: 'Crossfit',
    AirWalker: 'AirWalker',
    StairStepper: 'Stair Stepper',
    Flywheel: 'Flywheel',
    Treadmill: 'Treadmill',
    Yoga: 'Yoga',
    IsokineticMuscleStrengthTesting: 'Isokinetic Muscle Strength Testing',
    IsokineticMuscleStrengthTraining: 'Isokinetic Muscle Strength Training',
    HarmonicVibrationExercise: 'Harmonic Vibration Exercise',
    // Sport_type
    Walk: 'Walk',
    Hike: 'Hike',
    Run: 'Run',
    VirtualRun: 'Virtual Run',
    Ride: 'Ride',
    VirtualRide: 'Virtual Ride',
    Golf: 'Golf',
    Swim: 'Swim',
    Elliptical: 'Elliptical',
    Badminton: 'Badminton',
    Baseball: 'Baseball',
    TableTennis: 'Table Tennis',
    // 夏姿
    VirtualCanoeing: 'Virtual Canoeing',
    // 昇揚
    eBikeRide: 'eBike Ride',
    // 宇康
    Trampoline: 'Trampoline',
    // 卡洛動
    Boating: 'Boating'
  },
  subType: {
    LeftHandGripStrengthTest: 'Left Hand Grip Strength Test',
    RightHandGripStrengthTest: 'Right Hand Grip Strength Test',
    StepTest: 'Step Test',
    Cooper12MinuteRunTest: 'Cooper 12 Minute Run Test',
    PushUp: 'Push Up',
    '1MinuteSitUp': '1 Minute Sit Up',
    SitAndReachTest: 'Sit And Reach Test',
    StandAndReachTest: 'Stand And Reach Test',
    CounterMovementJump: 'Counter Movement Jump',
    '505AgilityTest': '505 Agility Test',
    IsometricMidThighPull: 'Isometric Mid Thigh Pull',
    '8FootUpAndGoTest': '8 Foot Up And Go Test',
    SingleLegBalanceLeft: 'Single Leg Balance Left',
    SingleLegBalanceRight: 'Single Leg Balance Right',
    ChairStandTest: 'Chair Stand Test',
    '2MinuteStepTest': '2 Minute Step Test',
    '30SecondArmCurlTest': '30 Second Arm Curl Test',
    '4MeterGaitSpeedTest': '4 Meter Gait Speed Test',
    '4MeterGaitSpeedTest(with Assistive Device)': '4 Meter Gait Speed Test(with Assistive Device)',
    Badminton8PointAgilityTest: 'Badminton 8 Point Agility Test',
    '100m': '100m',
    '200m': '200m',
    '400m': '400m',
    '800m': '800m',
    '1500m': '1500m',
    '3KRun': '3K Run',
    '5KRun': '5K Run',
    '10KRun': '10K Run',
    HalfMarathon: 'Half Marathon',
    Marathon: 'Marathon',
    FitnessBoxing: 'Fitness Boxing',
    HighIntensityIntervalTraining: 'High Intensity Interval Training',
    FatBurning: 'Fat Burning',
    LeftLegEyeClosedSingleLegStanceTest: 'Left Leg Eye Closed Single Leg Stance Test',
    RightLegEyeClosedSingleLegStanceTest: 'Right Leg Eye Closed Single Leg Stance Test',
    BatSwingTest: 'Bat Swing Test',
    PitchingAnalysis: 'Pitching Analysis',
    // 4MeterGait 5TimesStandAndSit BalanceTest
    '4MeterGait': '4 Meter Gait',
    '5TimesStandAndSit': '5 Times Stand And Sit',
    BalanceTest: 'Balance Test',
    Swing: 'Swing',
    GamePitchByPitch: 'Game Pitch By Pitch',
    BatterStats: 'Batter Stats',
    PitcherStats: 'Pitcher Stats',
    CatchingAnalysis: 'Catching Analysis',
    HittingAnalysis: 'Hitting Analysis',
    CoordinationTest: 'Coordination Test',
    // 虹映-徒手訓練
    Lunge: 'Lunge',
    JumpingJacks: 'Jumping Jacks',
    PlankHold: 'Plank Hold',
    StandCrunch: 'Stand Crunch',
    SplitSquat: 'Split Squat',
    ReverseCrunch: 'Reverse Crunch',
    PlankLegLift: 'Plank Leg Lift',
    BirdDog: 'Bird Dog',
    KneeinCrunch: 'Knee-in Crunch',
    CrossKick: 'Cross Kick',
    CookBridge: 'Cook Bridge',
    KneeupLunge: 'Knee-up Lunge',
    SingleLegDeadlift: 'Single Leg Deadlift',
    AlternateToeTouch: 'Alternate Toe Touch',
    PikePlank: 'Pike Plank',
    SidePlankLegLift: 'Side Plank Leg Lift',
    Burpee: 'Burpee',
    Squat: 'Squat',
    // 等速肌力檢測, 等速肌力訓練
    UpperLimbPush_Pull: 'Upper Limb Push-Pull',
    UpperLimbFrontPush_BackPull: 'Upper Limb Front Push-Back Pull',
    UpperLimbAdduction_Abduction: 'Upper Limb Adduction-Abduction',
    LowerLimbAdduction_Abduction: 'Lower Limb Adduction-Abduction',
    LowerLimbUpwardPush_DownwardGlide: 'Lower Limb Upward Push-Downward Glide',
    LowerLimbFrontKick_BackHook: 'Lower Limb Front Kick-Back Hook',
    CoreRightRotation_LeftRotation: 'Core Right Rotation-Left Rotation',
    CoreAbdominalMuscles_BackMuscles: 'Core Abdominal Muscles-Back Muscles',
    // 律動運動
    VerticalHarmonicVibration: 'Vertical Harmonic Vibration',
    PeriodicalAcceleration: 'Periodical Acceleration',
    HandHarmonicVibration: 'Hand Harmonic Vibration',
    // 
    ElectricBicycle: 'Electric Bicycle',
    // 虹映-徒手訓練
    ShoulderCircles: 'Shoulder Circles',
    SeatedKneeRaise: 'Seated Knee Raise',
    SeatedLegRaise: 'Seated Leg Raise',
    SeatedHold: 'Seated Hold',
    KneeRaiseClap: 'Knee Raise Clap',
    BicepCurl: 'Bicep Curl',
    TricepsExtension: 'Triceps Extension',
    ShoulderPress: 'Shoulder Press',
    WeightedSquat: 'Weighted Squat',
    ThoracicStretch: 'Thoracic Stretch',
    OverHeadPulldown: 'Over Head Pulldown',
    SeatedSquat: 'Seated Squat',
    SideLunge: 'Side Lunge',
    KneeLiftTwist: 'Knee Lift Twist',
    ShuttlecockKick: 'Shuttlecock Kick',
    VShapedSquat: 'V-shaped squat',
    // 卡洛動-划船
    SUP: '立槳',
    Canoeing: '輕艇',
    Rowing: '西式划船'
  },
  accessToken: {
    permissionGet: 'Get - Get Uploaded Data',
    permissionPost: 'Post - Upload Data',
    permissionDelete: 'Delete - Delete Data',
    fieldName: 'Name',
    fieldActive: 'Active Date',
    fieldExpired: 'Expire Date',
    fieldScope: 'Scope',
    description: 'Description',
    created: 'has created',
    saveWarning: 'Please copy and save it now, or you will not see it again',
    getToken: 'Get Token'
  },
  accountManagement: {
    add: 'Add',
    org: 'Organization',
    sidebarBasic: 'Basic Info',
    sidebarPassword: 'Change Password',
    sidebarToken: 'Access Token',
    sidebarAccount: 'Account Management',
    sidebarOrganization: 'Organization Management',
    sidebarConsumer: 'Consumer Review',
    sidebarDistribute: 'Distribute Model',
    DistributeAppManagement: 'Distribute Model Management',
    OrgOwner: 'Organization Owner',
    OrgState: 'Organization State',
    description: 'Description',
    name: 'Name',
    FirstContact: 'Name',
    memberName: 'User Name',
    ContactName: 'Contact Name',
    ContactTel: 'Phone',
    email: 'Email',
    loginAccount: 'Account',
    login: 'Account',
    account: 'Account',
    PlaceName: 'Place Name',
    DataPlaceName: 'Place of Data',
    org_city: 'city of place',
    organization: 'Organization',
    Authorities: 'Authorities',
    viewOrganization: 'view organization',
    Id: 'Id',
    orgId: 'Tax ID number',
    OrgIdorNumber: 'Tax ID Number',
    orgCity: 'City',
    phone: 'Phone',
    department: 'Department',
    title: 'Title',
    role: 'Role',
    state: 'State',
    active: 'Active',
    inactive: 'Inactive',
    pass: 'Active',
    reject: 'Reject',
    edit: 'Edit',
    password: 'password',
    resetPassword: 'Reset Password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    accountInfo: 'Account Info',
    changePassword: 'Change Password',
    AccountRule: 'Account should be 2-60 characters long and "._-" can be accepted at the middle of string',
    PasswordRule: 'Password should be 8-20 characters long with at least 1 uppercase, 1 lowercase and 1 number',
    validOrgOwnerName: 'Please Input Organization Owner Name',
    validOrgName: 'Please Input Organization Name',
    validOrgID: 'Please Input Tax Id Number',
    validLogin: 'Please Input Login account',
    validPwd: 'Please Input Password',
    validemail: 'Please Input email',
    isDeleteOrg: 'Are you sure you want to delete this organization？',
    orgMemberlist: 'Member List',
    orgMemberCount: 'Member',
    LoginCheck: 'Check',
    isDeleteOrgDESC: 'This action will delete all member in the same time.',
    isDeleteMember: 'Are you sure you want to delete this account？',
    accIsOwnerDESC: 'This account belong to an Organization Owner. Please transfer owner to other member on Organization Management page before delete',
    toOrg: 'go to transfer',
    member: {
      add: 'Add Member',
      edit: 'Edit Member'
    },
    organ: {
      name: 'Organization',
      add: 'Add Organization',
      addOwner: 'Add Owner',
      editOwner: 'Edit Owner',
      edit: 'Edit Organization'
    },
    consumer: {
      ConsumerReviewDialogTitle: 'Consumer Review'
    },
    // consumer
    isDuplicate: 'Duplicate',
    notDuplicate: 'Good to go',
    reviewReject: 'Reject',
    reviewPending: 'Pending',
    version: 'Version',
    agreeVersion: 'Version',
    dateApply: 'Apply Date',
    dateReview: 'Review Date',
    LastUpdateDate: 'Updated Date',
    Auditing: 'Auditing',
    Reason: 'Reason',
    review_status: 'Review',
    suggestion: 'Suggestion',
    // device
    inputReasonForReject: 'Please describe the reason for reject.',
    editDeviceForm: 'Device Management',
    reviewed: 'Reviewed',
    reviewing: 'Reviewing',
    reviewRecord: 'Reviewed log',
    reviewDescription: 'Description',
    disabledDate: 'Updated Date',
    all: 'All'
  },
  Register: {
    Provider: 'Provider',
    Consumer: 'Consumer',
    DataProvider: 'Data Provider',
    DataConsumer: 'Data Consumer',
    PlatformUser: 'Platform User',
    ContactUs: 'Contact Us',
    PersonalInfoAgree: 'Personal Information Agreement',
    TremsOfUse: 'Terms Of Use',
    FetchType: 'Fetch',
    FetchAPi: 'API',
    FetchService: 'Website service',
    Suggestion: 'Suggestion',
    NeedHelp: 'Need help? Please',
    ApplyFormTitle: 'Apply Form',
    placeholderOrgName: 'Please Input Data Ownership Organization',
    placeholderOrgId: 'Please Input Tax ID Number',
    placeholderEmail: 'Please Input Email',
    placeholderphone: 'Please Input Phone',
    placeholderName: 'Please Input Name',
    placeholderSuggestion: 'Please Input Suggestion',
    placeholderPlace: 'Your requirements in detail with the public welfare purposes',
    placeholderDescripUsage: 'Please provide a detailed description of the data requirements and potential public benefit uses.',
    placeholderPlaceOrgCity: 'Please Input the place of Data',
    placeholderContactName: 'Primary Contact Person',
    placeholderContactNo: 'Primary Contact Phone',
    placeholderBelong: 'Please fill in the name of your authority.',
    pleaseCheckAgree: 'please Check the Terms Of Service',
    pleaseReadAndCheck: 'Please review the "Terms of Service" and check to confirm your agreement.',
    RegisterNotify1: 'Upon approval of the data review, you will receive your login credentials within 7 working days.',
    RegisterNotify2: 'If you do not receive any notification, please contact us.',
    RegisterConsumerNotify1: 'Thank you for applying to become',
    RegisterConsumerNotify2: 'We will notify you of the opening date as soon as possible.',
    RegisterConsumerNotify3: 'If you have any questions during the waiting period, please feel free to',
    ApplySuccess: 'Application successful',
    IReadAndAgree: 'I have read and agree that the Platform may collect, process, and use my personal data to the extent necessary for the purposes listed by the Platform.',
    JoinUs: 'Join Sports Data Altruism',
    WithUs: 'Let us Stimulate the Development of the Sports Technology Industry and Promote Sports Good for All!',
    DonateData: 'I Want to Donate Data',
    UseData: 'I Want to Use Data',
    NoAccount: `Don't have account?`
  },
  Organization: {
    Organization_Employer: 'Organization Owner',
    Data_Consumer: 'Consumer',
    Member: 'Member',
    SubAdmin: 'SubAdministrator',
    Administrator: 'Administrator',
    consumer: 'Consumer', // for css
    provider: 'Provider' // for css
  },
  activities: {
    organization_name: 'Organization Name', // organization_name
    user_name: 'User',
    action_type: 'Action',
    details: 'Details',
    act_at: 'Action time',
    DELETE_DATAS: 'Delete data',
    UPLOAD_DATAS_BY_FILE: 'Upload data by file',
    UPLOAD_DATAS_BY_API: 'Upload data by API',
    UPLOAD_DATAS_BY_DISTRIBUTION: 'Upload data by distribution',
    CREATE_ORGANIZATION: 'Create organization',
    UPDATE_ORGANIZATION: 'Update organization',
    DELETE_ORGANIZATION: 'Delete organization',
    DOWNLOAD_SDV_DATA: 'Download data',
    CREATE_MEMBER: 'Create account',
    DELETE_MEMBER: 'Delete account',
    UPDATE_MEMBER: 'Update account',
    MEMBER_LOGIN: 'Login',
    
    created_at: 'Created',
    ProcessingTimes: 'Processing Times',
    process_times: 'Process Times',
    log: 'log',
    proces_user: 'User'
    
  },
  Spec: {
    selectDataType: 'Select Data Type',
    item: 'Item',
    columName: 'Colum Name',
    unit: 'Unit',
    required: 'Required',
    nonRequired: 'Optional',
    reasonalbe_data: 'Value Range',
    Example: 'Example',
    desciption: 'Description',
    noData: 'This data type has no subtype',
    all: 'Y(Must Have One)',
    true: 'Y',
    false: 'N'
  },
  Device: {
    DeviceList: 'Device List',
    SyncMembers: 'Sync User',
    online: 'Online',
    offline: 'Offline',
    error: 'Error',
    integration: 'Integration'
  },
  DeviceListField: {
    Status: 'Status',
    OrgName: 'Name',
    LoadingStatus: 'Uploading Status',
    UpdatedTime: 'Last Updated'
  },
  Form: {
    placeholderSelect: 'please Select',
    placeholderInput: 'please Input',
    placeholderKeyword: 'Search Data Type'
  },
  errorMessage: {
    // Organization
    1003: 'Organization not found.',
    // Member
    2001: 'Member {user_id} not found.',
    2002: 'Member may only contain a-z, A-Z, 0-9, dot, dash, underline, and the heading and trailing character should be alphanumeric and should be 2 to 60 characters long.',
    2003: 'Password is not legal. You can only use a-z, A-Z, 0-9, !@#$%^&*()_+|{}[]``~-=\'";:/?.>,<, and should contain at least an upper case alphabet, a lower case alphabet, and a digit, and is 8 to 20 characters long.',
    2004: 'Wrong password or username.',
    2005: 'The Account is already used.',
    2009: 'Member is in a project, cannot change his role.',
    2010: 'Organization owner can not be removed.',
    2011: 'Please change password first.',
    2100: 'This account ( {member_account} ) login failure time surpass 5 times in 15 minute',
    2101: 'This IP( {ip} ) has been blocked.',
    2012: 'Account or email does not match.',
    // Access Token
    3005: 'You need higher permission to change to this role.',
    3101: 'Access_token {access_token} not found.',
    3102: 'Access_token has expired.',
    3103: 'According to your access token, you do not have permission for this operation.',
    3201: 'Expired access token encountered',
    3202: 'Invalid access token encountered',
    // Excel Upload
    4001: 'File should contains the following columns',
    4002: 'File contains undefined columns',
    4003: 'File should not contains the following columns',
    4004: 'Column "start_date" format should be 2022-01-01 00:00:00 and type should be string.',
    4005: 'Upload File type should be CSV or Excel.',
    4006: 'File contains empty rows.',
    4007: 'Select File type should be CSV, Excel or ODS.',
    4101: 'DB has no data of this datatype: {data_type} - {type} - {subtype} - {version}.',
    4102: 'Failed to generate synthetic data.',
    4201: 'Delete data should not surpass 3000',
    // Common Upload Error
    5001: 'Version: {version} is disabled or not exist.',
    5002: 'DataType: {data_type}- {type}- {subtype} is not exist or undefined',
    5100: 'Upload data exceeds limit (Must lower than 7000).',
    // Device
    1004: 'Key not found.',
    7021: 'The device permission is denied, please contact admin.',
    // Unkown Error
    9001: 'Unexpected error, please contact your administrator.',
    9002: 'An invalid code path happens.',
    9999: 'An unknown internal error has occurred, please contact your administrator.'
  },
  DataExplore: {
    sdv_count: 'Number of Records',
    download_times: 'Downloads',
    dataEmpty: 'No data is available in this dataset.',
    downloadDialogTitle: 'Download processed data API Documentation',
    interface: 'Interface',
    selectedDatatype: 'Selected',
    apiKey: 'API Key'
  },
  Privacy: {
    Title_Subject: 'Privacy Policy',
    Desc_Subject: 'This Privacy Policy has been formulated on the "Sports Data Altruism Service Platform" (hereinafter referred to as "the Platform") operated by the Administration for Digital Industries, Ministry of Digital Affairs (MODA) to ensure the privacy rights of users when browsing the Platform. To help you understand the information on how the Platform protects the personal data you provide, as well as how it collects, uses, and safeguards various types of information you provide, we kindly suggest you read the following details to ensure a secure and pleasant experience when using the services of the Platform. Furthermore, the personal data collected by the Platform shall be handled in accordance with the specifications set forth in the "Personal Data Protection Act," unless otherwise provided by other laws and specifications.',
    Title_Section1: 'I. Scope of Application',
    Desc_Section1: 'This Privacy Policy applies to the collection, use, and protection of personal data related to your activities on the Platform. However, it does not apply to government agency websites or payment websites linked to the Platform. Each website linked through the Platform has its own privacy policy, and the Platform does not bear any joint responsibility. When you access these websites, the protection of personal data is subject to the privacy policy of each respective website.',
    Title_Section2: 'II. Collection, Processing, and Use of Personal Data on the Platform',
    Desc1_Section2: 'Purpose of collection: The Platform collects your personal data for the purposes of user identity authentication and management, in accordance with the specific purpose and the classification of personal data of the Personal Data Protection Act, including but not limited to "135 Information (Communication) Services," "136 Information (Communication) and database Management," "137 Information and Communication Security and Management," "157 Investigation, Statistics and Research Analysis," etc.',
    Desc2_Section2: 'Category of the personal data collected: This includes personally identifiable data such as name, email address, telephone or mobile number, etc.',
    Desc3_Section2: 'Period, region, and subjects of personal data used:',
    Desc3_1_Section2: '(1) Duration: The duration required to fulfill the specific purposes, the years for retention as stipulated by relevant laws and specifications or contractual agreements, and the necessary retention period for the performance of official duties or business operations by the Platform.',
    Desc3_2_Section2: '(2) Region: In the administrative territory of the Republic of China.',
    Desc3_3_Section2: '(3) Subjects: The operating and management agency of the Platform, as well as necessary third-party entities involved in the operation.',
    Desc3_4_Section2: '(4) Browsing and downloading files on the Platform do not involve the collection of any personally identifiable data.',
    Desc3_5_Section2: `(5) The Platform's system may retain records of your browsing or search activities, such as usage time, browser information, and click data. These records are used for internal analysis of website traffic and online behavior by the Platform's management unit, with the aim of improving the overall service quality of the Platform. There will not be any analysis conducted on any individual users.`,
    Title_Section3: 'III. Sharing Personal Data with Third Parties',
    Desc1_Section3: 'The Platform will never provide, exchange, rent, or sell any of your personal data to other organizations, individuals, or private companies. The use of your personal data is strictly limited to the specific purposes for which it was collected. However, the information may be used outside the scope when one of the following conditions occurs:',
    Desc1_1_Section3: '(1) where it is authorized with your consent;',
    Desc1_2_Section3: '(2) where it is expressly required by law;',
    Desc1_3_Section3: '(3) where it is necessary for ensuring national security or furthering public interest;',
    Desc1_4_Section3: '(4) where it is to prevent harm on your life, body, freedom, or property;',
    Desc1_5_Section3: '(5) where it is necessary for statistics gathering or academic research by a government agency or an academic institution for public interests; provided that such data, as provided by the data provider or as disclosed by the data collector, may not lead to the identification of a specific data subject;',
    Desc1_6_Section3: `(6) where your actions on the Platform violate the Platform's terms of service or may harm or obstruct the Platform's rights and interests, or cause damage to any person, and it is necessary for the Platform to analyze and disclose your personal data for identification, contact, or legal action.`,
    Desc1_7_Section3: `(7) where it is for the data subject's rights and interests.`,
    Desc2_Section3: 'When the Platform entrusts vendors to assist in the collection, processing, or use of your personal data, the Platform will fulfill its responsibility of supervision and management over the outsourced vendors or individuals.',
    Title_Section4: 'IV. Rights and Ways of Exercising Rights by Data Subjects',
    Desc1_Section4: 'After registering as a user on the Platform, you can log in to the user area at any time using your account and password to review, make copies, remark, correct, delete, and request the cessation of collection, processing, or use of the personal data you provided. Please note that once you choose to delete your registered user account, you will no longer be able to log in to the user services on the Platform with that account. You may request to exercise rights of other personal data by contacting the Platform through phone call or online application.',
    Desc2_Section4: `The Platform may retain the right of the data subjects’ request for the deletion or cessation of collection, processing, or use of personal data in the following circumstances:`,
    Desc2_1_Section4: '(1) When it is necessary for government agencies to perform their official or business duties;',
    Desc2_2_Section4: '(2) When there are legitimate reasons that prevent the deletion or cessation of collection, processing, or use.',
    Title_Section5: 'V. Use of Cookies',
    Desc1_Section5: `Cookies are small pieces of information written by information systems to users' hard drives through browsers, serving the purpose of managing needs and providing optimal personalized services. The Platform will write and read cookies in your browser. You can refer to the Platform's instructions on how to configure your browser to accept or reject cookies. Choosing to reject cookies will not affect your general website browsing experience but may cause certain functions of the website not to work properly.`,
    Desc2_Section5: `The aforementioned cookies only identify individual connections and record the web pages browsed by the browser within the Platform. This information is used for statistics on website traffic and browsing patterns analysis, serving as a reference for improving the Platform's services, and it does not identify specific data subjects. If necessary for statistics gathering or academic research by a government agency or an academic institution for the public interest, the data will also be processed in a way that may not lead to the identification of specific data subjects.`,
    Title_Section6: 'VI. Protection Measures to Ensure Personal Data Security',
    Desc_Section6: 'Please keep your personal data properly and do not provide it to any third parties to protect your rights and interests. If you are using a shared computer or a public computer, be sure to log out of your account and close the Platform after use.',
    Title_Section7: 'VII. Revision of Privacy Policy',
    Desc_Section7: 'If there are any revisions to this privacy policy, they will be announced on the Platform. Please check out our Platform for the revision announcements.',
    Title_Section8: 'VIII. Privacy Policy Inquiries (Contact Information)',
    Desc_Section8: 'If you have any questions regarding this policy, please feel free to'
  },
  TermsOfService: {
    Title_Subject: `Sports Data Altruism Service Platform Terms of Service`,
    Desc_Subject: `Welcome to the "Sports Data Altruism Service Platform" (hereinafter referred to as "the Platform")! The Platform is built, operated, and managed by the Institute for Information Industry (hereinafter referred to as "managing entity") entrusted by the Administration for Digital Industries, Ministry of Digital Affairs (MODA) to promote data altruism for the public in Taiwan.`,
    Title_Section1: `I. Sports Data Altruism Service Platform Privacy Policy`,
    Desc_before_link_Section1: `The Platform values the privacy rights of every user. By using the services of the Platform, you understand and agree that your user registration and other specific data will be collected, processed, and used under the protection and specification of the`,
    Desc_after_link_Section1: `Please read the Terms of Service and Privacy Policy carefully before using the Platform's services.`,
    Title_Section2: `II. Consent to the Terms and Changes in the Terms`,
    Desc1_Section2: `The Platform provides various services based on the Terms of Service. When you complete the registration or start using the Platform's services, it means that you have read, understood, and agreed to accept all the contents of the Terms of Service, including the existing and future services and contents derived from the Platform.`,
    Desc2_Section2: `If you cannot comply with the contents of the Terms or do not agree with all or part of the contents of the Terms, or if the Terms are not applicable to your country or region, you should immediately cease using the Platform's services.`,
    Desc3_Section2: `The Platform reserves the right to revise or change the contents of the Terms of Service at any time and will announce such revisions or changes on the Platform. Please make sure to follow the revisions or changes on the Platform at any time. If you continue to use the Platform's services after any revisions or changes, you will be deemed to have read, understood, and agreed to accept such revisions or changes.`,
    Title_Section3: `III. Definitions of Terms`,
    Desc1_Section3: `Service: Refers to various support services provided by the Platform's website and applications.`,
    Desc2_Section3: `Platform User: Refers to users who browse or use the Platform's services.`,
    Desc3_Section3: `Data Provider: Refers to those who connect the Platform and upload sports data.`,
    Desc4_Section3: `Data User: Refers to those who download the sports data, collect, process, and utilize them based on the principles of data altruism and for promoting social development and the related use of development of the sports technology industry.`,
    Title_Section4: `IV.Application Connecting the Platform for Platform User`,
    Desc_Section4: `If you wish to apply as a Data Provider or Data User on the Platform and for connection testing, official launch, modification, or termination of connecting, you should follow the following specifications:`,
    Desc1_Section4: `You should participate in the connection test in the testing environment and may proceed with modifying or terminating of connection upon receiving the official notice regarding the connection.`,
    Desc2_Section4: `Those who have terminated the connection may apply for resuming the connection according to the previous provision.`,
    Desc3_Section4: `Applicants should cooperate with the managing entity for testing and proceed with connection in accordance with the relevant specifications and formats specified by the Platform.`,
    Title_Section5: `V. Matters to Be Complied with by Platform User`,
    Desc1_Section5: `After becoming a data provider or data user, you should comply with the following matters:`,
    Desc1_1_Section5: `(1) If you are a data provider, you should obtain the consent of the data subject when collecting personal data and keep a physical or electronic copy of the consent form of personal data.`,
    Desc1_2_Section5: `(2) If you are a data user, you may only use the data within the scope and purpose of the approved the connecting and must not re-identify the data in any way.`,
    Desc1_3_Section5: `(3) Both data providers and data users should retain records of data transmission for at least six months. The records should at least include the data field names, transmission time, transmission recipients, and the success or failure of the data transmission. The managing entity may conduct audits as necessary.`,
    Desc2_Section5: `Platform users who connect the Platform and utilize data are responsible for ensuring the quality and accuracy of their developed products or services. In case of any legal violations resulting in harm to data subjects, the users will be held accountable for related legal liabilities.`,
    Desc3_Section5: `You understand and agree that all your actions on the Platform must comply with these Terms of Service and relevant laws and specifications. You are solely responsible for all risks and legal liabilities. If you are a user from a country or region other than the Republic of China, you agree to comply with the relevant specifications of your own country or region.`,
    Desc4_Section5: `In the event of data leakage, theft, alteration, loss, or suspected infringement of others' privacy rights or other rights, both data providers and data users must immediately stop connecting and report it to the managing entity.`,
    Desc5_Section5: `The managing entity may request rectification within a specified period for violations of other provisions of these Terms of Service. If the violations are not rectified within the given schedule or if the violations are significant, the managing entity may suspend the connecting services.`,
    Title_Section6: `VI.Service Changes and Notifications`,
    Desc1_Section6: `The Platform strives to provide users with the latest compiled information. However, the form and content of the services provided on the Platform may be added, modified, or terminated at any time without prior notice to users, based on operation development and user demands.`,
    Desc2_Section6: `You agree that the Platform reserves the right to modify, temporarily or permanently terminate the provision of service of the Platform (or any part thereof) at any time. When required by law or other relevant specifications, the Platform may notify you through the Platform's service webpage, email, or other reasonable means. However, if you violate these Terms of Service by unauthorized access to data on the Platform or by providing incorrect or outdated information during registration, you will not receive the aforementioned notifications.`,
    Title_Section7: `VII.Disclaimer`,
    Desc1_Section7: `The Platform may experience interruptions or malfunctions, which may cause inconvenience or errors when you use the Platform. You should take appropriate protective measures when using the Platform. The Platform is not liable for any damages caused by your use (or inability to use) of the Platform's services.`,
    Desc2_Section7: `The various functions, information, and content provided by the Platform are provided as it is at the time of their availability. The original providing agencies or entities are responsible for the completeness, reliability, security, and accuracy of such functions, information, and content. The Platform makes no warranty of any kind, expressed or implied for them.`,
    Desc3_Section7: `When you use the Platform, the data records recorded by the Platform's system during the usage process shall prevail. In the event of any disputes or litigation, the data recorded by the Platform's system shall be the determining standard. If you can provide other data and prove its authenticity, it is not subject to this limitation.`,
    Title_Section8: `VIII. Consultation Service (Contact Information)`,
    Desc1_Section8: `If you have any questions regarding these Terms of Service, please feel free to contact us.`,
    Desc2_Section8: `Email: service@data-sports.tw`,
    Title_Section9: `IX.Others`,
    Desc_Section9: `Due to considerations of laws, technology, market developments, or government policies, the managing entity may modify the service conditions, temporarily suspend, or terminate the services of the Platform.`
  },
  InfoaAree: {
    Title_Subject: `Notification and Letter of Consent for Collection, Processing and Use of Personal Data`,
    Desc_Subject: `The Institute for Information Industry has been entrusted to implement the "Promotion Project of Sports Data Altruism Service Platform" by the Administration for Digital Industries, Ministry of Digital Affairs (MODA), and operate the Sports Data Altruism Service Platform (referred to as "the Platform"). To comply with relevant laws and specifications for personal data protection and the Platform's privacy policy, before collecting your personal data, we would like to inform you of the following matters in accordance with the law. Please read them carefully.`,
    Title_Section1: `I. Purpose and Category of Personal Data to be Collected`,
    Desc1_Section1: `For the implementation of the project, the Platform requires the following personal data: company name, email address, contact person's name, phone number, or mobile number.`,
    Desc2_Section1: `※You may choose, at any time in the future, not to receive any promotional information from the Platform by clicking the link provided in the promotional message.`,
    Title_Section2: `II. Time Period, Area, Target and Ways of Personal Data`,
    Desc_Section2: `Unless the purposes of use relating to international business or activities, your personal data will be used solely by the Platform in a reasonable way in the territory of the Republic of China  to the extent necessary to implement the purposes of collection until the purposes of collection prescribed above is fulfilled.`,
    Title_Section3: `III. Your Rights with regard to Personal Data Provided`,
    Desc_Section3: `You may exercise the following rights by the rules set forth by the aforementioned operations and activities or by submitting an application via email to the Platform's service mailbox (service@data-sports.tw):`,
    Desc1_Section3: `Any inquiry and request for a review of the personal data;`,
    Desc2_Section3: `Any request to make duplications of the personal data;`,
    Desc3_Section3: `Any request to supplement or correct the personal data;`,
    Desc4_Section3: `Any request to discontinue collection, processing or use of personal data; and`,
    Desc5_Section3: `Any request to delete the personal data.`,
    Title_Section4: `IV. The Influence on Your Rights and Interests while You Choose Not to Provide Your Personal Data`,
    Desc_Section4: `If you provide incorrect personal data or choose not to provide personal data to the Platform, the Platform may not be able to provide you with services relating to the purposes prescribed above.`,
    Title_Section5: `V. You understand that this document complies with the R.O.C. Personal Data Protection Act and related specifications, and you agree that the Platform keeps this document for further checking.`,
    Desc_Section5: `The Consent to the Provision of Personal Data`,
    Desc1_Section5: `I have read and understood the above notification.`,
    Desc2_Section5: `I agreed that the Platform may collect, process, and use my personal data for the purposes of collection prescribed above.`
  },
  Banner: {
    Accumulated_Data: `Accumulated Data Available for Use`,
    Accumulated_Data_Content: `“Data Altruism” comes from the Data Governance Act, DGA, as discussed and resolved by the European Union in the recent years. Through people’s trust in the government, the data is contributed to benefit public altruistic research and to assist with the empowerment of Taiwan’s sports technology industry, so that industrial innovation will be prompted by social innovation.`,
    Sport_Data: `Sport Data`,
    Physical_Fitness_Data: `Physical Fitness Data`,
    Physiology_Data: `Physiology Data`
  },
  About: {
    Description: '依據運動×科技SRB會議結論，跨部會整合規劃「運動科技應用與產業發展計畫」及四大戰略，期藉由各部會協力合作，帶動整體運動科技產業發展及促進全民運動健康。計畫戰略期望透過數位部發展共通的運動資料蒐集和跨部會合作治理的新基礎建設，並串連衛福部、教育部、國科會、經濟部等部會上下游場域資源，故發展「運動數據公益平台」，實踐數據公益推動。',
    Function_title: '平台功能介紹',
    Function_description: '定義運動數據欄位、以及提供國內場域串接，進行運動數據公益的利他行動。',
    Usage_title: '平台用途介紹',
    Usage_description: '透過本平台促進數據公益，例如：透過數據提升運動安全性(高齡、身障者、高危險群)、建立平權友善的運動環境、或協助政策面推動全民健康與運動推廣發展策略…等。',
    User_title: '使用者對象',
    User_description: '提供數據的平台生態系數據公益轉型業者(即平台相關運動資料提供之數據公益合作機構、單位或企業)及使用數據的第三方加值創新業者。',
    Data_title: '提供什麼數據',
    Data_description: '合法蒐集、處理後無關個資，可供利用之運動、體適能、生理量測等類型資料。'
  },
  Introduce: {
    Data_Specification: `Introduction to Data Specification`,
    Data_Specification_Content: `We have collaborated with sports data experts in the definition of sports data standards.  By sharing data under unified data Specification, it would allow different data sources to have the opportunity to concatenate with one another.  So far, we have defined a set of data specifications that would accept at least 65 types of different data categories, including baseball, badminton, running, hiking, workout, physical fitness, and physiological data.  Please click on the link to enter our Specification page for better understanding of the platform’s data specification and sharing procedure.`
  },
  Dataset: {
    Download: 'Download dataset information',
    DownloadFile: 'Download',
    DownloadCount: 'Download count',
    ViewCount: 'View count',
    Column: 'Column',
    DatasetTitle: 'Altruism Dataset',
    Dataset: 'Dataset',
    DatasetInfo: 'Information',
    LoginWithConsumer: 'Please log in first with the identity of consumer.',
    Running_Heart_Rate: `Running Heart Rate Dataset`,
    Running_Heart_Rate_Description: `Collecting running data that is approved by acknowledged electronic industry employees and that is de-identified and is free of personal data.`,
    Running_Heart_Rate_Measure: `Heart Rate, Speed, Gender, Age`,
    Swimming_Rate: `Swimming 25/50 Meters Dataset`,
    Swimming_Rate_Description: `Collecting standard swimming pool exercise data that is approved by acknowledged swimming enthusiasts and that is de-identified.`,
    Swimming_Rate_Measure: `Paddling Count, Paddling Distance, Paddling Frequency, Pacing, Swimming Efficiency, Lap Count`,
    Outdoor_Biking_Rate: `Outdoor Biking Dataset`,
    Outdoor_Biking_Rate_Description: `Collecting various kinds of outdoor biking data that are acknowledged by users and approved for altruism use.`,
    Outdoor_Biking_Rate_Measure: `Longitude and Latitude, Speed, Distance, Total Sea Level Risen, Total Sea Level Descended, Highest Speed, Revolution Per Minute`,
    Indoor_Exercise_Rate: `Indoor Exercise Dataset`,
    Indoor_Exercise_Rate_Description: `Collecting at-home exercise data that is acknowledged by users and approved for altruism use.`,
    Indoor_Exercise_Rate_Measure: `Time Slot, Exercise Time, Class Name, Gender, Age`
  },
  Platform: {
    Platform_Policy: `Platform Policy Background`,
    Policy_Basis: `Policy Basis`,
    Policy_Basis_Section1: `Based on the conclusion of Sports x Technology Strategy Review Board Conference, cross-department integration is in place for the planning of 5-year (1+4) “Sports Technology Application and Industry Development Plan” and four major strategies.  Through cross-department coordination and collaboration, it is hoped that the overall sports technology industry development would be prompted and that the public’s exercises and health would be advanced.`,
    Policy_Basis_Section2: `Plans and strategies are expected to gather common exercise data from Ministry of Digital Affairs and new infrastructure of cross-department collaboration governance to bridge together resources from upstream and downstream areas across the Ministry of Health and Welfare, Ministry of Education, National Science and Technology Council, and Ministry of Economic Affairs.`,
    Vision_Platform: `Vision of Sports Data Altruism Service Platform`,
    Vision_Platform_Section1: `Creating distributed sports data compliance platform – easy-to-use, easy-to-retrieve, easy-to-reload altruism sports service environment`,
    Vision_Platform_Section2: `Through the government’s data governance mechanism, autonomy of personal data is protected, and concerns over data security and privacy are resolved.  By reinforcing citizens’ trust in government’s data, they would then be willing to share their data accumulated at sports competitions, Civil Sports Centers, all levels of sports venues and facilities, and community life sports venues; so that an openly sharing healthy ecosystem of sports data altruism is established.`,
    Sports_Everywhere: `Link: Sports and technology action plan`
  },
  DataUsage: {
    Data_Usage: `Data Usage Detail`,
    Foreword: `Foreword`,
    Foreword_Description: `Through the Sports Data Altruism Service Platform (legitimate, compliant), data computing models beneficial for relevant researches are produced.  It would promote Taiwan’s venues, facilities, and sports technology companies to collaborate in connecting and contributing data that would produce new altruism applications.`,
    How_and_Who: `How to Collect, and Who is the User`,
    How_and_Who_Section1: `Overall, there are three roles in total: data contributor, data collector, and data user.  The contributor can be an individual or a business.  The collector encompasses public and private sports venues and facilities, sports events organizers, sports app companies, sports equipment companies, etc.  The user constitutes government offices, private institutions, and the academia research field.`,
    How_and_Who_Section2: `The data procedure begins from the collector, who proposes the “Personal Data Collection Notice and Consent Form” to contributor (individual / business) to obtain authorization of personal data.  After collector obtains authorized data, he/she then begins data identification and concatenation by him/herself, allowing the data obtained by these collectors to concatenate with the Sports Data Altruism Service Platform.  The platform can display data, letting user to examine the data on the platform.  The user can obtain data use authorization from the companies and proposes application of data altruism; the result will give back and enhance public interests or produce services that benefit people’s exercises.`,
    Data_Usage_Include: `Data altruism applications can include but are not limited to the following:`,
    Exercise_Advocacy: `Studies and Analyses of Exercise Advocacy Items`,
    Studies_and_Analyses: {
      Road_Section: `Road section analysis for exercising group`,
      Time_Slot: `Time slot analysis for exercising group`,
      Urban_rural: `Urban-rural digitalization disparity`,
      Exercise_Energy: `Study and analysis of Taiwan’s exercise energy`,
      Tourism_Festival: `Study and planning for sports tourism festival`,
      Fatigue_Recuperation: `Post-race sports fatigue recuperation analysis`,
      Sports_Science: `Sports science analysis and training`
    },
    Integrating_Exterior_Data: `Integrating exterior open data`,
    Integrating_Exterior: {
      Routes: `Integrating exercise-friendly routes`,
      Shops: `Integrating exercise-friendly shops`
    }
  },
  Navigator: {
    Site_Navigator: 'Site Navigator',
    Site_Navigator_Include: 'The website has been set up based on the Accessible Web Development Guidelines (AWDG), which encompass the following:',
    Site_Navigator_Include_Section: {
      Section1: `All pages are able to be operated using a keyboard and are not limited to using a mouse.`,
      Section2: `The website is set up with an anchor access key ( ::: ), so that the user can rapidly reach to various main blocks.`,
      Section3: `Website images are all labeled with appropriate details of text alternatives.`,
      Section4: `Adding hint texts to the links at times of interface changes, website changes, or new windows pop-ups, so the users do not need to enter each link page individually to know the purpose of each link.`
    },
    Site_Navigator_Description: 'The website has been set up based on the Accessible Web Development Guidelines (AWDG), which encompass the following:',
    Site_Navigator_Block: {
      Upper: `Upper function block`,
      Center: `Center content block`,
      Lower: `Lower function block`,
      Left: `Left function block`
    },
    Site_Navigator_Shortcut: {
      AltU: `Upper function block, including “Back to Homepage”, “Site Navigator”, and “Development Guideline.`,
      AltC: `Center content block is this website’s main content block.`,
      AltL: `Left function block is this website’s interior page (secondary menu); this area only appears within certain interior pages.`,
      AltZ: `Lower function block.`
    },
    Browser: `If your web browser is Firefox, the method to use the shortcut key is Shift+Alt+(shortcut key alphabet), e.g. Shift+Alt+C will jump to the center content block of the website, and so on and so forth.`
  },
  FAQ: {
    Q9: '運動數據公益平台法遵相關資訊可在哪裡檢視呢?',
    Q9_Answer: `請參考運動數據公益平台個資評估法遵使用手冊。`
  },
  FAQ_Provider: {
    Eligibility: 'About Eligibility',
    About_Eligibility: {
      Q1: `Who can contribute data?`,
      Q1_Answer: `The Platform welcomes all companies and groups who are willing to partner with the Platform to provide data.`,
      Q2: `What is the fee mechanism of the Sports Data Altruism Service Platform?`,
      Q2_Answer: `The Platform currently allows data provider to use the Platform for free.`
    },
    Compliance: 'About Compliance',
    About_Compliance: {
      Q1: `Being a data provider, does the data I have provided need to be first made known to and approved by the individual in advance?`,
      Q1_Answer: `The data provided by data provider has to first obtain individual’s Personal Data Consent Form, which will be kept for future reference.`,
      Q2: `What content does a Personal Data Consent Form need to cover?`,
      Q2_Answer1: `Please refer to the Personal Data Consent Form sample.`,
      Q2_Answer2: `Click here to download.`,
      Q3: `How to obtain and keep Personal Data Consent Forms?`,
      Q3_Answer: `In order to accurately ensure that approval has been granted by the data subject, it would usually be done in practice via the method of signing a Personal Data Consent Form, and paper / electronic files will also be kept as proofs.`,
      Q4: `I am the company of the Platform’s partnering venue, does the Personal Data Consent Form require an actual personal signature? Or can it be signed using a personal device?`,
      Q4_Answer: `Personal Data Consent Form actually does not have a set format, but a proof needs to be kept. It is also valid if there are records saved in related apps; actual signature is not necessarily required.`
    },
    Field_Specification_and_Data_Quality: 'Field Specification and Data Quality',
    About_Field_Specification_and_Data_Quality: {
      Q1: `What is data field specification? Why does data field specification need to be prepared for upload?`,
      Q1_Answer: `The establishment of data field specification is for the data that everyone contributes to be able to have a unified format, so that it will benefit the capability for all parties to subsequently concatenate and use the data. As a result, all contributed data has to follow the data field specification set forth by the Platform.`,
      Q2: `Where can one look up the latest version of data specification?`,
      Q2_Answer1: `View the`,
      Q2_Answer2: `field specification`,
      Q3: `If my measuring items are not within the specification, how should I propose a new uploading specification?`,
      Q3_Answer1: `First, please make the proposal via the Platform`,
      Q3_Answer2: `the platform administrator will arrange meetings with experts to discuss whether to make adjustments per suggestion; we also welcome other experts from your recommendations to join the discussion. If there are any updates, they will be released in the next version of sports data field specification.`,
      Q3_File_name: `Data Spec Change Request`,
      Q3_mailto: `mailbox`,
      Q4: `Does the format of the measuring items need to be transformed into the form as defined by data specification?`,
      Q4_Answer: `Please have the uploaded data primarily follow the format specification as provided by the Platform, because the Platform performs two-stage compliance checks. Even though the successful upload could take place in the first stage, the wrong format would still be checked and dismissed during the second stage. So it is recommended that the uploaded data should completely comply with the specification in the beginning.`,
      Q5: `Is the birth year of the user in A.D. or in ROC era?`,
      Q5_Answer1: `The date format defined by the Platform is in A.D.`,
      Q5_Answer2: `To all providers, please first transform the years from ROC era to A.D. before uploading them to sports altruism platform.`,
      Q6: `How should the event name be written?`,
      Q6_Answer: `Event name is not a required field to be filled, so it can be filled freely according to venue planning.`,
      Q7: `What is the format of recorded time?`,
      Q7_Answer1: `Recorded time is primarily the time at recording of the respective data. If it is a time slot, then the starting time will do.`,
      Q7_Answer2: `The date format is 2022-01-01 00:00:00.`,
      Q8: `What is the definition of a set of data?`,
      Q8_Answer: `The Platform defines a set of data as a column that includes type of the data, basic information of the data, and information of the measuring item.`,
      Q9: `If the field does not have any data, should the respective form to be left as blank, given a null value, or not filled at all?`,
      Q9_Answer: `As long as it is a field that is not required to be filled, it would be fine to either give a null value or no information at all to the value of the field. However, it is encouraged to enhance the thoroughness of the data filled.`
    },
    API_Concatenation: 'API Concatenation',
    About_API_Concatenation: {
      Q1: `How does one use API to concatenate data?`,
      Q1_Answer1: `The Platform provides each user a set of exclusive API token. Users can coordinate data field specification with Open API to perform data concatenation in accordance with exclusive API token.`,
      Q1_Answer2: `If API concatenation technique service is needed, please contact the Platform.`,
      Q2: `What are the advantages of API concatenation?`,
      Q2_Answer: `Through API concatenation, you could save your time, and you can also integrate user data more efficiently, so that you could drastically lower the time cost that needs to be devoted to before data sharing.`
    },
    Data_Security: 'About Data Security',
    About_Data_Security: {
      Q1: `Does the Platform collect users’ personal data?`,
      Q1_Answer1: `For future data statistical analyses, it is encouraged to provide data in completion; basic data only requires the age (birthdate in year, month, day), and no other personal data is collected.`
    },
    Management: 'About Management',
    About_Management: {
      Q1: `Being the data provider, what are my limits of authority in terms of management?`,
      Q1_Answer1: `Data provider currently can upload, delete, and download all data belonging to his or her own account.`
    }
  },
  JoinUs: {
    Welcome: `join now`,
    Slogan1: `Exploring the Possibility of Public Welfare Through Contributing Your Data. Together We Create More Value`,
    Slogan2: `If you want to know more about us`,
    Slogan3: `Transforming Data for Public Good to build a better future for all`,
    Slogan4: `Join us, Let Sports Data Do the Best for the Public Welfare!`
  },
  City: {
    基隆市: `Keelung`,
    新北市: `New Taipei`,
    臺北市: `Taipei`,
    台北市: `Taipei`,
    桃園市: `Taoyuan`,
    新竹縣: `Hsinchu County`,
    新竹市: `Hsinchu City`,
    苗栗縣: `Miaoli`,
    臺中市: `Taichung`,
    台中市: `Taichung`,
    彰化縣: `Changhua`,
    南投縣: `Nantou`,
    雲林縣: `Yunlin`,
    嘉義縣: `Chiayi County`,
    嘉義市: `Chiayi City`,
    臺南市: `Tainan`,
    台南市: `Tainan`,
    高雄市: `Kaohsiung`,
    屏東縣: `Pingtung`,
    宜蘭縣: `Yilan`,
    花蓮縣: `Hualien`,
    臺東縣: `Taitung`,
    台東縣: `Taitung`,
    澎湖縣: `Penghu`,
    金門縣: `Kinmen`,
    連江縣: `Lienchiang`,
    綠島: `Green Island`,
    蘭嶼: `Orchid Island`,
    馬祖: `Matsu`,
    其他: `Others`,
    未填: `None`
  },
  ContactUs: {
    Tel: 'Tel',
    Email: 'Email'
  }
}
