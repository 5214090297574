import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import i18n from '@/lang'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/SportData',
      name: 'SportData',
      redirect: { name: 'Landing' },
      component: () => import('../views/Home/index.vue'),
      meta: {
        title: 'SportsDataCharityService'
      },
      children: [
        {
          path: 'Register',
          name: 'Register',
          component: () => import('../views/Register/index.vue'),
          meta: {
            title: 'JoinPlatform'
          }
        },
        {
          path: 'SiteMap',
          name: 'SiteMap',
          component: () => import('../views/SiteMap/index.vue'),
          meta: {
            title: 'SiteMap'
          }
        },
        {
          path: 'Landing',
          name: 'Landing',
          component: () => import('../views/Landing/index.vue'),
          meta: {
            title: 'SportsDataCharityService'
          }
        },
        {
          path: 'Service',
          name: 'Service',
          component: () => import('../views/Service/index.vue'),
          meta: {
            title: 'Specification'
          }
        },
        {
          path: 'Sdcamp',
          name: 'Sdcamp',
          component: () => import('../views/Sdcamp/index.vue'),
          meta: {
            title: 'Sdcamp'
          }
        },
        {
          path: 'FAQ',
          name: 'FAQ',
          component: () => import('../views/FAQ/index.vue'),
          meta: {
            title: 'FAQ'
          }
        },
        {
          path: 'Privacy',
          name: 'Privacy',
          component: () => import('../views/Privacy/index.vue'),
          meta: {
            title: 'Privacy'
          }
        },
        {
          path: 'TermsOfService',
          name: 'TermsOfService',
          component: () => import('../views/TermsOfService/index.vue'),
          meta: {
            title: 'Terms'
          }
        },
        {
          path: 'ProjectInfo',
          name: 'ProjectInfo',
          component: () => import('../views/ProjectInfo/index.vue'),
          meta: {
            title: 'AboutProject'
          }
        },
        {
          path: 'ContactUs',
          name: 'ContactUs',
          component: () => import('../views/ContactUs/index.vue'),
          meta: {
            title: 'ContactUs'
          }
        },
        {
          path: 'DataUse',
          name: 'DataUse',
          component: () => import('../views/DataUse/index.vue'),
          meta: {
            title: 'DataUsage'
          }
        },
        {
          path: 'Achievements',
          name: 'Achievements',
          component: () => import('../views/Achievements/index.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'Updates-2024001-BasicInfo',
          name: 'PlatformUpdates',
          component: () => import('../views/PlatformUpdates/index.vue'),
          meta: {
            title: 'PlatformUpdates'
          }
        },
        {
          path: 'Updates-2024002-FitnessData',
          name: 'Updates02FitnessData',
          component: () => import('../views/PlatformUpdates/Updates02FitnessData.vue'),
          meta: {
            title: 'PlatformUpdates'
          }
        },
        {
          path: 'SampleCase',
          name: 'SampleCase',
          redirect: { name: 'UseCase_112_02' },
          component: () => import('../views/SampleCase/index.vue'),
          meta: {
            title: 'UseCase'
          },
          children: [
            {
              path: 'UseCase_112_01',
              name: 'UseCase_112_01',
              component: () => import('../views/SampleCase/components/UseCase_112_01.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_02',
              name: 'UseCase_112_02',
              component: () => import('../views/SampleCase/components/UseCase_112_02.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_03',
              name: 'UseCase_112_03',
              component: () => import('../views/SampleCase/components/UseCase_112_03.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_04',
              name: 'UseCase_112_04',
              component: () => import('../views/SampleCase/components/UseCase_112_04.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_05',
              name: 'UseCase_112_05',
              component: () => import('../views/SampleCase/components/UseCase_112_05.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_06',
              name: 'UseCase_112_06',
              component: () => import('../views/SampleCase/components/UseCase_112_06.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_07',
              name: 'UseCase_112_07',
              component: () => import('../views/SampleCase/components/UseCase_112_07.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_08',
              name: 'UseCase_112_08',
              component: () => import('../views/SampleCase/components/UseCase_112_08.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_09',
              name: 'UseCase_112_09',
              component: () => import('../views/SampleCase/components/UseCase_112_09.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_10',
              name: 'UseCase_112_10',
              component: () => import('../views/SampleCase/components/UseCase_112_10.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_11',
              name: 'UseCase_112_11',
              component: () => import('../views/SampleCase/components/UseCase_112_11.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_112_12',
              name: 'UseCase_112_12',
              component: () => import('../views/SampleCase/components/UseCase_112_12.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_09',
              name: 'UseCase_111_09',
              component: () => import('../views/SampleCase/components/UseCase_111_09.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_08',
              name: 'UseCase_111_08',
              component: () => import('../views/SampleCase/components/UseCase_111_08.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_07',
              name: 'UseCase_111_07',
              component: () => import('../views/SampleCase/components/UseCase_111_07.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_01',
              name: 'UseCase_111_01',
              component: () => import('../views/SampleCase/components/UseCase_111_01.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_02',
              name: 'UseCase_111_02',
              component: () => import('../views/SampleCase/components/UseCase_111_02.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_03',
              name: 'UseCase_111_03',
              component: () => import('../views/SampleCase/components/UseCase_111_03.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_04',
              name: 'UseCase_111_04',
              component: () => import('../views/SampleCase/components/UseCase_111_04.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_05',
              name: 'UseCase_111_05',
              component: () => import('../views/SampleCase/components/UseCase_111_05.vue'),
              meta: {
                title: 'Achievements'
              }
            },
            {
              path: 'UseCase_111_06',
              name: 'UseCase_111_06',
              component: () => import('../views/SampleCase/components/UseCase_111_06.vue'),
              meta: {
                title: 'Achievements'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/',
      name: 'Home',
      component: Layout,
      redirect: 'Dashboard',
      children: [
        {
          path: '/Dashboard',
          name: 'Dashboard',
          component: () => import('../views/Dashboard/index.vue'),
          meta: {
            title: 'Dashboard'
          }
        },
        {
          path: '/Data',
          name: 'Data',
          component: () => import('../views/Data/index.vue'),
          meta: {
            title: 'SearchData'
          }
        },
        {
          path: '/DataExplore',
          name: 'DataExplore',
          component: () => import('../views/DataExplore/index.vue'),
          meta: {
            title: 'SearchData'
          }
        },
        {
          path: '/Dataset',
          name: 'Dataset',
          component: () => import('../views/Dataset/index.vue'),
          meta: {
            title: 'Dataset'
          }
        },
        {
          path: '/FAQ',
          name: 'FAQProvider',
          component: () => import('../views/FAQProvider/index.vue'),
          meta: {
            title: 'QA'
          }
        },
        {
          path: '/ProcessedData',
          name: 'ProcessedData',
          component: () => import('../views/ProcessedData/index.vue'),
          meta: {
            title: 'SyntheticData'
          }
        },
        {
          path: '/Data/ExcelUpload',
          name: 'ExcelUpload',
          component: () => import('../views/Data/ExcelUpload.vue'),
          meta: {
            title: 'UploadFile'
          }
        },
        {
          path: '/Data/CheckExcelUpload',
          name: 'CheckExcelUpload',
          component: () => import('../views/Data/CheckExcelUpload.vue'),
          meta: {
            title: 'CheckUpload'
          }
        },
        {
          path: '/Account',
          name: 'Account',
          component: () => import('../views/Account/index.vue'),
          children: [
            {
              path: 'BasicInfo',
              name: 'BasicInfo',
              component: () => import('../views/Account/components/BasicInfo.vue'),
              meta: {
                title: 'Basic'
              }
            },
            {
              path: 'ChangePwd',
              name: 'ChangePwd',
              component: () => import('../views/Account/components/ChangePwd.vue'),
              meta: {
                title: 'Password'
              }
            },
            {
              path: 'GetToken',
              name: 'GetToken',
              component: () => import('../views/Account/components/GetToken.vue'),
              meta: {
                title: 'Token'
              }
            },
            {
              path: 'AccountManagement',
              name: 'AccountManagement',
              component: () => import('../views/Account/components/AccountManagement.vue'),
              meta: {
                title: 'Account'
              }
            },
            {
              path: 'OrgManagement',
              name: 'OrgManagement',
              component: () => import('../views/Account/components/OrgManagement.vue'),
              meta: {
                title: 'Organization'
              }
            },
            {
              path: 'ConsumerReview',
              name: 'ConsumerReview',
              component: () => import('../views/Account/components/ConsumerReview.vue'),
              meta: {
                title: 'Consumer'
              }
            },            
            {
              path: 'DeviceManagement',
              name: 'DeviceManagement',
              component: () => import('../views/Account/components/DeviceMenagement.vue'),
              meta: {
                title: 'Device'
              }
            }
          ]
        },
        {
          path: '/Activities',
          name: 'Activities',
          component: () => import('../views/Activities/index.vue'),
          meta: {
            title: 'Activities'
          }
        },
        {
          path: '/SysActivities',
          name: 'SysActivities',
          component: () => import('../views/SysActivities/index.vue'),
          meta: {
            title: 'UserRecord'
          }
        },
        {
          path: '/DataProcessinglog',
          name: 'DataProcessinglog',
          component: () => import('../views/DataProcessinglog/index.vue'),
          meta: {
            title: 'DataLog'
          }
        },
        {
          path: '/Device',
          name: 'Device',
          component: () => import('../views/Device/index.vue'),
          meta: {
            title: 'DeviceList'
          }
        },
        {
          path: '/DataSpec',
          name: 'DataSpec',
          component: () => import('../views/DataSpec/index.vue'),
          meta: {
            title: 'DataSpec'
          }
        },
        {
          path: 'Landing',
          name: 'LandingLogin',
          component: () => import('../views/Landing/index.vue'),
          meta: {
            title: 'SportsDataCharityService'
          }
        },
        {
          path: 'Service',
          name: 'ServiceLogin',
          component: () => import('../views/Service/index.vue'),
          meta: {
            title: 'Specification'
          }
        },
        {
          path: 'Sdcamp',
          name: 'SdcampLogin',
          component: () => import('../views/Sdcamp/index.vue'),
          meta: {
            title: 'Sdcamp'
          }
        },
        {
          path: '/FAQ',
          name: 'FAQLogin',
          component: () => import('../views/FAQ/index.vue'),
          meta: {
            title: 'FAQ'
          }
        },
        {
          path: 'Privacy',
          name: 'PrivacyLogin',
          component: () => import('../views/Privacy/index.vue'),
          meta: {
            title: 'Privacy'
          }
        },
        {
          path: 'TermsOfService',
          name: 'TermsOfServiceLogin',
          component: () => import('../views/TermsOfService/index.vue'),
          meta: {
            title: 'Terms'
          }
        },
        {
          path: 'ProjectInfo',
          name: 'ProjectInfoLogin',
          component: () => import('../views/ProjectInfo/index.vue'),
          meta: {
            title: 'AboutProject'
          }
        },
        {
          path: 'ContactUs',
          name: 'ContactUsLogin',
          component: () => import('../views/ContactUs/index.vue'),
          meta: {
            title: 'ContactUs'
          }
        },
        {
          path: 'DataUse',
          name: 'DataUseLogin',
          component: () => import('../views/DataUse/index.vue'),
          meta: {
            title: 'DataUsage'
          }
        },
        {
          path: 'Achievements',
          name: 'AchievementsLogin',
          component: () => import('../views/Achievements/index.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_01',
          name: 'LoginUseCase_112_01',
          component: () => import('../views/SampleCase/components/UseCase_112_01.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_02',
          name: 'LoginUseCase_112_02',
          component: () => import('../views/SampleCase/components/UseCase_112_02.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_03',
          name: 'LoginUseCase_112_03',
          component: () => import('../views/SampleCase/components/UseCase_112_03.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_04',
          name: 'LoginUseCase_112_04',
          component: () => import('../views/SampleCase/components/UseCase_112_04.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_05',
          name: 'LoginUseCase_112_05',
          component: () => import('../views/SampleCase/components/UseCase_112_05.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_06',
          name: 'LoginUseCase_112_06',
          component: () => import('../views/SampleCase/components/UseCase_112_06.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_07',
          name: 'LoginUseCase_112_07',
          component: () => import('../views/SampleCase/components/UseCase_112_07.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_08',
          name: 'LoginUseCase_112_08',
          component: () => import('../views/SampleCase/components/UseCase_112_08.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_09',
          name: 'LoginUseCase_112_09',
          component: () => import('../views/SampleCase/components/UseCase_112_09.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_10',
          name: 'LoginUseCase_112_10',
          component: () => import('../views/SampleCase/components/UseCase_112_10.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_11',
          name: 'LoginUseCase_112_11',
          component: () => import('../views/SampleCase/components/UseCase_112_11.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_112_12',
          name: 'LoginUseCase_112_12',
          component: () => import('../views/SampleCase/components/UseCase_112_12.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_09',
          name: 'LoginUseCase_111_09',
          component: () => import('../views/SampleCase/components/UseCase_111_09.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_08',
          name: 'LoginUseCase_111_08',
          component: () => import('../views/SampleCase/components/UseCase_111_08.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_07',
          name: 'LoginUseCase_111_07',
          component: () => import('../views/SampleCase/components/UseCase_111_07.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_01',
          name: 'LoginUseCase_111_01',
          component: () => import('../views/SampleCase/components/UseCase_111_01.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_02',
          name: 'LoginUseCase_111_02',
          component: () => import('../views/SampleCase/components/UseCase_111_02.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_03',
          name: 'LoginUseCase_111_03',
          component: () => import('../views/SampleCase/components/UseCase_111_03.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_04',
          name: 'LoginUseCase_111_04',
          component: () => import('../views/SampleCase/components/UseCase_111_04.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_05',
          name: 'LoginUseCase_111_05',
          component: () => import('../views/SampleCase/components/UseCase_111_05.vue'),
          meta: {
            title: 'Achievements'
          }
        },
        {
          path: 'UseCase_111_06',
          name: 'LoginUseCase_111_06',
          component: () => import('../views/SampleCase/components/UseCase_111_06.vue'),
          meta: {
            title: 'Achievements'
          }
        }
        // {
        //   path: '*',
        //   name: 'notFound',
        //   component: () => import('../views/NotFound/index.vue')
        // }
      ]
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('../views/Home/Login.vue'),
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/ConsumerForm',
      name: 'ConsumerForm',
      component: () => import('../views/Register/ConsumerForm.vue'),
      meta: {
        title: 'JoinPlatform'
      }
    },
    {
      path: '/ProviderForm',
      name: 'ProviderForm',
      component: () => import('../views/Register/ProviderForm.vue'),
      meta: {
        title: 'JoinPlatform'
      }
    }
  ],
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.afterEach((to, from) => {
  document.title = i18n.t('route.' + to.meta.title)
})

export default router
