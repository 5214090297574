<template>
  <div class="login-header">
    <el-row>
      <div class="nav-desk">
        <el-col :span="6" class="navbar-title">
          <LogoOnly />
        </el-col>
        <el-col :span="18" class="navbar-menu">
          <el-menu
            mode="horizontal"
            :background-color="backgroundColor"
            :text-color="textColor"
            active-text-color="#ffffff"
            @select="handleSelect"
          >
            <template
              v-for="(item, index) in providerMenu"
            >
              <el-menu-item
                v-if="item.type === 'router'"
                :key="index"
                :index="item.id"
                :style="item.style"
              >
                <router-link
                  :to="{ name: item.routerName }"
                >
                  {{ $t(`navbar.${item.displayName}`) }}
                </router-link>
              </el-menu-item>
              <el-submenu
                v-else-if="item.type === 'subMenu'"
                :key="index"
                :index="item.id"
              >
                <template slot="title">
                  {{ $t(`navbar.${item.displayName}`) }}
                </template>
                <el-menu-item 
                  v-for="(childItem, childIndex) in item.children"
                  :key="childIndex"
                  :index="childItem.id"
                  :style="childItem.style"
                >
                  <router-link :to="{ name: childItem.routerName }">
                    {{ $t(`navbar.${childItem.displayName}`) }}
                  </router-link>
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                v-else-if="item.type === 'component'"
                :key="index"
                :index="item.id"
                :style="item.style"
              >
                <component :is="item.component" />
              </el-menu-item>
            </template>
          </el-menu>
        </el-col>
      </div>
      
      <!-- hamburg -->
      <el-row class="nav-pad">
        <el-col class="navbar-title">
          <LogoOnly />
        </el-col>
        <el-col class="navbar-menu">
          <el-row type="flex" justify="end">
            <el-col :span="4">
              <el-button 
                class="btn-hamburger"
                type="text"
                icon="el-icon-s-fold"
                circle
                plain
                size="medium"
                style="color: #606266;" 
                @click="drawer = true"
              />
              <el-drawer
                :visible.sync="drawer"
                :direction="direction"
                :with-header="false"
                custom-class="rwd-menu"
                :modal="false"
              >
                <el-menu 
                  class="el-menu-vertical-demo"
                  text-color="#606266"
                  active-text-color="#0c356d"
                  mode="vertical"
                  :collapse="true"
                  @select="handleSelect"
                >
                  <template
                    v-for="(item, index) in providerMenu"
                  >
                    <el-menu-item
                      v-if="item.type === 'router'"
                      :key="index"
                      :index="item.id"
                      :style="item.style"
                    >
                      <router-link
                        :to="{ name: item.routerName }"
                      >
                        {{ $t(`navbar.${item.displayName}`) }}
                      </router-link>
                    </el-menu-item>
                    <el-submenu
                      v-else-if="item.type === 'subMenu'"
                      :key="index"
                      :index="item.id"
                    >
                      <template slot="title">
                        {{ $t(`navbar.${item.displayName}`) }}
                      </template>
                      <el-menu-item 
                        v-for="(childItem, childIndex) in item.children"
                        :key="childIndex"
                        :index="childItem.id"
                        :style="childItem.style"
                      >
                        <router-link :to="{ name: childItem.routerName }">
                          {{ $t(`navbar.${childItem.displayName}`) }}
                        </router-link>
                      </el-menu-item>
                    </el-submenu>
                  </template>
                </el-menu>
              </el-drawer>
            </el-col>
            <el-col :span="2">
              <LangSelect />
            </el-col>
            <el-col :span="8">
              <UserAccount />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MemberRole, MenuItem } from '@/Mixins'
import LangSelect from '@/components/LangSelect'
import UserAccount from '@/components/UserAccount'
import LogoOnly from './LogoOnly.vue'

export default {
  name: 'ProviderNavbar',
  components: {
    LangSelect, 
    UserAccount,
    LogoOnly
  },
  mixins: [MemberRole, MenuItem],
  data () {
    return {
      backgroundColor: '#47494d',
      textColor: '#ffffff',
      drawer: false,
      direction: 'rtl'
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  created() {
    this.setActiveMenu(this.$store.getters.activeMenu)
  },
  methods: {
    setActiveMenu(route) {
      this.$store.dispatch('app/setActiveMenu', route)
    },
    handleSelect(key, keyPath) {
      this.setActiveMenu(key)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/index.scss";

.login-header {
  height: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  .nav-pad{
    display: none;
  }

  .nav-desk{
    background-color: $bg_header_provider; // $bg_header_provider $bg_header_device
    position: fixed;
    width: 100%;
    .navbar-title{
      height: 60px;
      z-index: 1;
    }
  }
  .el-menu {
    float: right;
    .el-menu-item.is-active {   
      i {
        color: $text_light;
      }
    }

    .el-menu-item:hover {
      font-weight: bold;
      border-bottom: 2px solid $theme !important;
      background-color: $bg_header_provider !important;
    }

    .el-submenu__title:hover {
      background-color: $bg_header_provider !important;
    }

    .el-dropdown{
      font-size: 16px;
      color: $text_light;
    }
  }

  .el-menu--horizontal>.el-submenu:hover .el-submenu__title {
    font-weight: bold;
  } 

  /* tablets */
  @media (max-width: 1080px) { // normal 768
    .nav-desk{
      display: none;
      justify-content: space-between;
    }
    .nav-pad{
      display: flex;
      .rwd-menu{
        .el-menu .el-menu-item{
          padding: 0;
        }
        .el-menu-item:hover {
          background-color: #eaeefb !important;
        }
        .el-submenu__title:hover {
          background-color: #eaeefb !important;
        }
        .el-submenu{
          .el-menu-item{
            padding-left: 30px !important;
          }
        }
        position: absolute;
        top:60px;
        ul{
          width: 100%;
          text-align: left;
        }
      }
      .navbar-menu{
        height: 60px;
        line-height: 60px;
        .btn-hamburger{
          border: none;
          background-color: transparent;
        }
      }
    }
  }      
}
@media (max-width: 830px){
  .login-header{
    position: initial;
    z-index: initial;
  }
}
</style>
