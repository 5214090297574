import router from './router/index'
import store from './store'
import { Message, MessageBox } from 'element-ui'
import { getToken, getTokenExpiration } from '@/utils/auth'
import i18n from '@/lang'

router.beforeEach(async (to, from, next) => {
  const hasToken = getToken()
  if (hasToken) {
    onHasToken(to, next)
  } else {
    onNoToken(to, next)
  }
})

const showLogoutNotifyDialog = (to, next) => {
  MessageBox.confirm(i18n.t('Notify.logoutNotifications'), i18n.t('general.Notify'), {
    confirmButtonText: i18n.t('general.ReLogin'),
    cancelButtonText: i18n.t('general.Continue'),
    type: 'warning',
    showClose: false,
    showCancelButton: false,
    closeOnClickModal: false,
    closeOnPressEscape: false
  }).then(() => {
    console.log('permission beforeremoveToken')
    store.dispatch('user/logout')
    next(`/SportData?redirect=${to.path}`)
  })
}

const onHasToken = async (to, next) => {
  const isTokenExist = getTokenExpiration()
  const hasMember = store.getters.memberId
  if (!isTokenExist) {
    showLogoutNotifyDialog(to, next)
  } else if (to.path === '/SportData') {
    next({ name: 'Home' })
  } else {
    if (hasMember) {
      next()
    } else {
      try {
        await store.dispatch('user/getInfo')
        next({ ...to, replace: true })
      } catch (error) {
        await store.dispatch('user/resetToken')
        Message.error(error || 'Has Error')
        next(`/SportData?redirect=${to.path}`)
      }
    }
  }
}

const whiteList = [
  '/Login',
  '/ConsumerForm',
  '/ProviderForm',
  '/SportData',
  '/SportData/Achievements',
  '/SportData/Landing',
  '/SportData/ContactUs',
  '/SportData/Service',
  '/SportData/Register',
  '/SportData/Provider',
  '/SportData/SiteMap',
  '/SportData/Sdcamp',
  '/SportData/FAQ',
  '/SportData/Privacy',
  '/SportData/TermsOfService',
  '/SportData/ProjectInfo',
  '/SportData/DataUse',
  '/SportData/Updates-2024001-BasicInfo',
  '/SportData/Updates-2024002-FitnessData',
  '/SportData/SampleCase/UseCase_111_01',
  '/SportData/SampleCase/UseCase_111_02',
  '/SportData/SampleCase/UseCase_111_03',
  '/SportData/SampleCase/UseCase_111_04',
  '/SportData/SampleCase/UseCase_111_05',
  '/SportData/SampleCase/UseCase_111_06',
  '/SportData/SampleCase/UseCase_111_07',
  '/SportData/SampleCase/UseCase_111_08',
  '/SportData/SampleCase/UseCase_111_09',
  '/SportData/SampleCase/UseCase_112_01',
  '/SportData/SampleCase/UseCase_112_02',
  '/SportData/SampleCase/UseCase_112_03',
  '/SportData/SampleCase/UseCase_112_04',
  '/SportData/SampleCase/UseCase_112_05',
  '/SportData/SampleCase/UseCase_112_06',
  '/SportData/SampleCase/UseCase_112_07',
  '/SportData/SampleCase/UseCase_112_08',
  '/SportData/SampleCase/UseCase_112_09',
  '/SportData/SampleCase/UseCase_112_10',
  '/SportData/SampleCase/UseCase_112_11',
  '/SportData/SampleCase/UseCase_112_12'
]
const onNoToken = async (to, next) => {
  if (whiteList.indexOf(to.path) !== -1) { 
    next()
  } else { 
    next(`/SportData?redirect=${to.path}`)
  }
}

router.afterEach(() => {
})
