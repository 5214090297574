import { downloadFileFromBinary } from '@/utils/downloadFile'
import { getDatasetFiles } from '@/api/data'
import { getNewDatasetFiles } from '@/api/dataset'
import { getDocument } from '@/api/document'

export default {
  methods: {
    fetchGetDocumentAPI(doc_type, title, dataset_id) {
      this.fileLoading = true
      const requestObject = { doc_type: doc_type }
      if (dataset_id !== null) {
        requestObject.dataset_id = dataset_id
      }
      getDocument(requestObject)
        .then((res) => {
          downloadFileFromBinary(res, title)
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.fileLoading = false
        })
    },
    fetchGetDatasetFileAPI(id, para, title) { // 第一年的首頁 dataset 下載

      this.$set(this.fileLoadings, id, true)
      
      getDatasetFiles({ template_type: para })
        .then((res) => {
          downloadFileFromBinary(res, `${title}.csv`)
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.$set(this.fileLoadings, id, false)
        })
    },
    fetchGetNewDatasetFileAPI(id, name, format, title, callback) {
      this.$set(this.datasetFileLoadings, id, true)
      
      getNewDatasetFiles({ 
        id, name, format
      })
        .then((res) => {
          downloadFileFromBinary(res, `${title}.${format}`)
          callback()
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.$set(this.datasetFileLoadings, id, false)
        })
    }
  }
}
