import { mapGetters } from 'vuex'
import useCase from '@/assets/useCase/useCase.json'

export default {
  data() {
    return {
      landingUseCase: [],
      achievement: []
    }
  },
  computed: {
    ...mapGetters(['token']),
    isLogin () {
      return !!this.token
    }
  },
  mounted() {
    this.getLandingUseCase()
    this.getUseCase()
  },
  methods: {
    getLandingUseCase() {
      const data = useCase
      this.landingUseCase = data
        .filter(item => item.landing)
        .map(item => {
          const newObj = {
            ...item,
            src: require(`@/assets/images/sample/${item.landingPic}`)
          }
          if (this.isLogin) {
            newObj.routerName = item.routerNameLogin
          }
          return newObj
        })
    },
    getUseCase() {
      const data = useCase.map(item => {
        const newObj = {
          ...item,
          displayCover: require(`@/assets/images/sample/${item.displayCover}`)
        }
        if (this.isLogin) {
          newObj.routerName = item.routerNameLogin
        }
        return newObj
      })
      this.achievement = data
    }
  }
}
