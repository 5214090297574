import { getOrganization } from '@/api/organization'

export default {
  data() {
    return {
      OrgOptions: []
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      await getOrganization().then(res => {
        const data = res.data.organization_list
        this.OrgOptions = data.map((item) => {
          const newObj = {
            label: item.display,
            id: item.id,
            value: item.id
          }
          return newObj
        })

      })
    }
  }
}
