<template>
  <div class="adilogo-container">
    <img
      src="https://moda.gov.tw/copyright/ADI/rxvpnkqz.kd1.svg"
      class="image"
      alt="數位發展部數位產業署logo"
    >
  </div> 
</template>
<script>
export default {
  name: 'ADIlogo',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/index.scss";
.adilogo-container{
  .image {
    width: 100%;
    display: block;
  }
  @media (max-width: 768px){
    width: 50%;
  }
}
</style>
